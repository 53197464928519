import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { Button, FormFeedback, Form, Input, Label, Row, Col, Spinner, Card } from 'reactstrap';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import Select from 'react-select';
import { ArrowLeft, Info, PlusCircle, Trash2, X } from 'react-feather';
import { district, fieldOption, getFieldTypeOptions, pincode1, productApi, productCategory, state } from '../../../../ApiConfigs/ApiConfig';
import { multiImageUpload, uploadImage } from '../../upload/index';
import { useNavigate } from 'react-router-dom';
import './index.css';
import Navbar from '../../../navbar';
import { RiImageAddFill } from 'react-icons/ri';
import { TagsInput } from 'react-tag-input-component';


function AddProductIndex() {


    const nagivate = useNavigate()
    const [getData, setGetData] = useState([])
    const imageRef = useRef(null);
    const imageRef1 = useRef(null);
    const imageRefs = useRef([]);
    const [image, setImage] = useState(null);
    const [icon, setIcon] = useState(null);
    const [feildOption, setFieldOption] = useState([])
    const [getOptionsFieldType, setOptionsFieldType] = useState([])
    const [getState, setState] = useState([]);
    const [getDistrict, setDistrict] = useState([]);
    const [getPincode, setPincode] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedPincodes, setSelectedPincodes] = useState([]);
    const inputRef1 = useRef(null);
    const [showCustomUpload, setShowCustomUpload] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [selectedCustomImageIndex, setSelectedCustomImageIndex] = useState(null);
    const [profileImages, setProfileImages] = useState([]);
    const [customImages, setCustomImages] = useState([]);
    const [loader, setloader] = useState(false)
    const [loads, setloads] = useState(false)
    const [loaders, setloaders] = useState(false)
    const [loading, setLoading] = useState(false)
    const [load, setLoad] = useState(false)
    const [fieldTypeIds, setFieldTypeIds] = useState([]);
    const [typeIds, setTypeIds] = useState([])
    const navigate = useNavigate()
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');

    const formSchema = yup.object().shape({

        productCategory: yup.mixed().required('Please Enter Your productCategory '),
        name: yup.string().required('Please Enter Your productName').matches(/^[a-zA-Z0-9\s]+$/, 'Product Name should not contain special characters'),
        fullDescription: yup.string().required('Please Enter Your fullDescription'),
        metaTitle: yup.string().required('Please Enter Your Meta Title').matches(/^[a-zA-Z0-9\s]+$/, 'Meta Name should not contain special characters'),
        metaDescription: yup.string().required('Please Enter Your Meta Description'),
        // shortDescription: yup.string().required('Please Enter Your shortDescription'),
        amount: yup.mixed().required('Please Enter Your amount'),
        quantityType: yup.mixed().required('Please Enter Your quantityType'),
        minimunQuantity: yup.string().required('Please Enter Your minimunQuantity'),
        maximunQuantity: yup.string().required('Please Enter Your maximunQuantity'),


    });



    const {
        reset,
        control,
        watch,
        resetField,
        setValue,
        getValues,
        handleSubmit,
        formState: { errors, isDirty, isValid },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
    });


    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "fieldTypes",
    });

    const { fields: deliveryLocationFields, append: appendDeliveryLocation, remove: removeDeliveryLocation } = useFieldArray({
        control,
        name: "deliveryLocation",
    });

    const [isManualLocations, setIsManualLocations] = useState(false);

    // const { fields: shippingChargesFields, append: appendState, remove: removeState } = useFieldArray({
    //     control,
    //     name: 'shippingCharges', // Changed to more descriptive name
    // });

    const { fields: customizeImagesFields, append: appendCustomizeImages } = useFieldArray({
        control,
        name: "customizeImages",
    });

    const { fields: quantityBasedAmountFields, append: appendQuantityBasedAmount, remove: removeQuantityBasedAmount } = useFieldArray({
        control,
        name: "quantityBasedAmount",
    });

    const [isKg, setIsKg] = useState('kg')

    const submitForm = async (data) => {
        const productCategory = data?.productCategory?.value;
        delete data.img_url;
        delete data.image;
        const { deliveryLocation, fieldTypes, ...restData } = data;

        // Ensure deliveryLocation exists before mapping
        const formattedLocations = data.deliveryLocation?.map(location => ({
            state: location.state ? location.state.label : '', // Access label directly
            districts: Array.isArray(location.district) ? location.district.map(d => d.label) : [], // Ensure district is an array
            exceptPincodes: Array.isArray(location.pincode) ? location.pincode.map(p => p.label) : [] // Ensure pincode is an array
        })) || [];

        const quantityType = data?.quantityType?.value;
        const quantityBasedAmount = data.quantityBasedAmount.map(item => ({
            type: item?.type?.value,
            amount: parseFloat(item?.amount),
            from: parseFloat(item?.from),
            to: parseFloat(item?.to)
        }));

        const fieldd = {
            fieldType: data?.fieldTypes?.map((el) => ({
                name: el?.name?.value,
                options: el?.options?.map((item) => item?.value)
            }))
        };

        const customImage = {
            customizeImages: colorProducts.map(colorProduct => ({
                colourCode: colorProduct.colourCode,
                images: colorProduct.images.map(imageData => ({
                    name: imageData.name,
                    image: imageData.image
                }))
            }))
        };
        const payload = {
            ...data,
            amount: parseFloat(data.amount),
            maximunQuantity: parseFloat(data.maximunQuantity),
            minimunQuantity: parseFloat(data.minimunQuantity),
            locations: formattedLocations, // Add formattedLocations here
            fieldType: fieldd.fieldType,
            metaKeywords: metaKeywords,
            customizeImageSize: customizeImageSize,
            customizeImages: customImage.customizeImages,
            gstType: data?.gstType?.value,
            weightType: isKg,
        };
        delete payload.deliveryLocation;
        delete payload.fieldTypes;

        try {
            const response = await axios.post(productApi, {
                ...payload,
                productCategory,
                galleryImages: profileImages?.map((el) => el?.url),
                image: profileImageUrl,
                icon: profileIconUrl,
                quantityType,
                quantityBasedAmount
            });
            toast.success(response?.data?.msg);
            navigate('/admin/product');
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };
    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setloads(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloads(false)
            }
        }
    };

    const handleCustomImageUpload = async (e, colorIndex, imageIndex) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const localImageUrl = URL.createObjectURL(file); // Create a local URL for the image

            try {
                setloaders(true);
                const formData = new FormData();
                formData.append('file', file);

                // Perform the image upload to your server
                const uploadData = await uploadImage(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    const uploadedImageUrl = uploadData.result[0].location;

                    // Set both the local image URL and the uploaded image URL
                    const updatedColorProducts = [...colorProducts];
                    updatedColorProducts[colorIndex].images[imageIndex] = {
                        name: updatedColorProducts[colorIndex].images[imageIndex]?.name || '',
                        image: uploadedImageUrl,
                        localImageUrl, // Use localImageUrl to preview before upload completes
                    };

                    setColorProducts(updatedColorProducts);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setloaders(false);
            }
        }
    };


    const handleImageUpload = async (e) => {
        if (e.target.files) {
            try {
                setLoading(true)
                const formData = new FormData();
                const uploadedFiles = [];

                for (let i = 0; i < e.target.files.length; i++) {
                    formData.append('files', e.target.files[i]);
                }

                const uploadData = await multiImageUpload(formData);

                if (uploadData && uploadData.result && uploadData.result.length) {
                    uploadData.result.forEach((file) => {
                        uploadedFiles.push({
                            name: file.originalname,
                            url: file.location,
                        });
                    });

                    setProfileImages([...profileImages, ...uploadedFiles]);
                    // setIsImageUploaded(true);
                }
            } catch (error) {
                toast.error('Something went wrong...');
            } finally {
                setLoading(false)

            }
        }
    };


    const handleRemoveImage = (index) => {
        const updatedImages = [...profileImages];
        updatedImages.splice(index, 1);
        setProfileImages(updatedImages);
        setSelectedImageIndex(null);
    };

    const handleRemoveCustomImage = (index) => {
        const updatedCustomImages = [...customImages];
        updatedCustomImages.splice(index, 1);
        setCustomImages(updatedCustomImages);
        setSelectedCustomImageIndex(null);
    };


    const getProduct = async () => {
        try {

            const response = await axios.get(productCategory)
            setGetData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }

    const options1 = [
        {
            label: "Inclusive",
            value: "inclusive"
        },
        {
            label: "Exclusive",
            value: "exclusive"
        }
    ];

    const [states, setStates] = useState([]);  // Holds the states fetched from the API
    const [selectedStates, setSelectedStates] = useState([]);

    useEffect(() => {
        async function fetchStates() {
            try {
                const response = await axios.get(state);
                const stateOptions = response.data.result.map((state) => ({
                    label: state.state,
                    value: state.state,
                }));
                setStates(stateOptions);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        }
        fetchStates();
    }, []);

    // Handle the change in selected states
    const handleStateChange = (selected, index) => {
        let updatedSelections = [...selectedStates];
        updatedSelections[index] = selected ? selected.map(s => s.value) : [];
        setSelectedStates(updatedSelections);
    };

    // Filter out selected states for each dropdown
    const getAvailableStates = (index) => {
        const selectedFlat = selectedStates.flat();  // Flatten selected states from all fields
        return states.filter((state) => !selectedFlat.includes(state.value));  // Filter out already selected states
    };

    const options = getData?.map((item) =>
    (
        {
            label: item?.name,
            value: item?._id
        }))

    useEffect(() => {
        if (fieldTypeIds.length) {
            let value = fieldTypeIds.map(id => id.id);
            setTypeIds(value)
        }
    }, [fieldTypeIds])
    const getFieldType = async (id, ind) => {
        try {
            let payload = [];
            if (id) {
                typeIds.map((val) => {
                    if (val.toString() !== id.toString()) {
                        payload.push(val)
                    }
                })
            } else {
                payload = typeIds
            }
            const response = await axios.post(`${getFieldTypeOptions}`, { fieldType: payload })
            const optionsFieldType = response?.data?.result?.map((item) =>
            (
                {
                    label: item?.name,
                    value: item?._id
                }))
            setOptionsFieldType(optionsFieldType)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const getStateOption = async (searchValue = "", index) => {
        try {
            const response = await axios.get(`${state}?search=${searchValue}`);
            const optionsState = response?.data?.result?.map((item) => ({
                label: item?.state,
                value: item?._id,
            }));

            const selectedFlat = selectedState.flat(); // Flatten selected states from all fields
            const availableStates = optionsState.filter((state) => !selectedFlat.includes(state.label)); // Filter out already selected states

            setState(availableStates); // Set the filtered state options
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    // Handle state change to store selected states for each index
    const handleStateChange1 = (selectedOption = null, index) => {
        const updatedStates = [...selectedState]; // Ensure selectedState is always an array
        updatedStates[index] = selectedOption ? [selectedOption] : []; // Store the selected option as an array or empty array
        setSelectedState(updatedStates); // Update the selected states in state

        // Clear district and pincode selections when a new state is selected
        const updatedDistricts = [...selectedDistricts];
        const updatedPincodes = [...selectedPincodes];
        updatedDistricts[index] = []; // Clear districts for this index
        updatedPincodes[index] = []; // Clear pincodes for this index
        setSelectedDistricts(updatedDistricts);
        setSelectedPincodes(updatedPincodes);

        // Extract the value of the selected option
        const selectedStateValues = selectedOption ? [selectedOption.label] : []; // Ensure it's an array
        if (selectedStateValues.length > 0) {
            // Call the district fetch for the selected state's value
            getDistrictOption(selectedStateValues, index); // Load districts based on selected state's value
        }
    };

    // Fetch district options based on selected state
    const getDistrictOption = async (stateValues, searchValue = '') => {
        try {
            const response = await axios.get(`${district}?state=${stateValues.join(",")}`);
            const options = response?.data?.result?.rows?.map((item) => ({
                label: item?.district_name_english,
                value: item?._id,
            }));

            const selectedFlat = selectedDistricts.flat(); // Flatten selected districts from all fields
            const availableDistricts = options.filter((district) => !selectedFlat.includes(district.label)); // Filter out already selected districts

            setDistrict(availableDistricts); // Set the filtered district options
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    // Handle district change to store selected districts for each index
    const handleDistrictChange = (selectedOption, index) => {
        const updatedDistricts = [...selectedDistricts];
        updatedDistricts[index] = selectedOption ? selectedOption.map(s => s.label) : [];
        setSelectedDistricts(updatedDistricts);

        // Clear pincode selection when districts change
        const updatedPincodes = [...selectedPincodes];
        updatedPincodes[index] = []; // Clear pincodes for this index
        setSelectedPincodes(updatedPincodes);

        // Fetch pincodes for the newly selected districts
        getPincodeOption(updatedDistricts, index); // Update the selected districts in state
    };

    // Fetch pincode options based on selected districts
    const getPincodeOption = async (updatedDistricts, index) => {
        if (!updatedDistricts?.[index]) return; // Ensure there's a selected district
        try {
            const response = await axios.get(`${pincode1}?districts=${updatedDistricts[index].join(",")}`); // Adjust the API URL
            const options = response?.data?.result?.map((item) => ({
                label: item?.pincode,
                value: item?._id,
            }));

            const selectedFlat = selectedPincodes.flat();
            const availablePincodes = options.filter((pincode) => !selectedFlat.includes(pincode.label)); // Filter out already selected pincodes

            setPincode(availablePincodes); // Set the filtered pincode options
        } catch (error) {
            console.error('Error fetching pincodes:', error);
        }
    };

    // Handle pincode change to store selected pincodes for each index
    const handlePincodeChange = (selectedOption, index) => {
        const updatedPincodes = [...selectedPincodes];
        updatedPincodes[index] = selectedOption ? selectedOption.map(s => s.value) : [];
        setSelectedPincodes(updatedPincodes); // Update the selected pincodes in state
    };


    // const getPincodeOption = async (searchValue, ind) => {
    //     try {
    //         const response = await axios.get(`${district}?state=${selectedState}&search=${searchValue}`);
    //         const options = response?.data?.result?.map((item) => ({
    //             label: item?.district_name_english,
    //             value: item?._id,
    //         }));
    //         setDistrict(options); // Set the fetched options in state
    //     } catch (error) {

    //     }
    // };

    const getDatas = async (id, index) => {

        if (!fieldTypeIds.length) {
            setFieldTypeIds([{ index: index, id }]);
        } else if (fieldTypeIds[index]?.index === index) {
            setFieldTypeIds(prevState => {
                const updatedState = [...prevState];
                updatedState[index] = { index: index, id };
                return updatedState;
            });
        } else {
            fieldTypeIds.push({ index, id })
            // /(prevState => [...prevState, index, id]);
        }
    };

    useEffect(() => {
        getStateOption()
    }, [])

    useEffect(() => {
        getFieldType()
        getStateOption()
    }, [typeIds])


    const getFieldOption = async (id) => {
        try {
            const response = await axios.get(`${fieldOption}?fieldType=${id}`)
            setFieldOption(response?.data?.result)
        } catch (error) {

        }
    }
    const fieldoption = feildOption?.map((item) => (
        {
            label: item?.name,
            value: item?._id
        }
    ))



    const quantityBasedAmountType = [
        {
            name: "Recommended",
            value: "recemended"
        },
        {
            name: "General",
            value: "general"
        },
        {
            name: "BestPrice",
            value: "bestprice"
        },
        {
            name: "BulckPrice",
            value: "bulckprice"
        },

    ]

    const quantityBasedType = quantityBasedAmountType?.map((item) => (
        {
            label: item?.name,
            value: item?.value
        }
    ))

    const quantityTypeData = [
        {
            name: "dropdown",
            value: "dropdown"
        },
        {
            name: "manual",
            value: "manual"
        }
    ]

    const quantityTypeDrop = quantityTypeData?.map((item) => (
        {
            label: item?.name,
            value: item?.name
        }
    ))

    useEffect(() => {
        if (watch('customizeLocations') === 'false') {
            setSelectedState([]);
            setSelectedDistricts([]);
            setSelectedPincodes([]);
        }
    }, [watch('customizeLocations')]);

    useEffect(() => {
        getProduct()
        getFieldType()
    }, [])

    const handleTypeChange = (selectedOption, index) => {
        setValue(`fieldTypes[${index}].options`, []);
        getFieldOption(selectedOption?.value);
        getDatas(selectedOption?.value, index);
    };

    const handleRadioChange = (value) => {
        setSelectedValue(value);
    };

    const [metaKeywords, setMetaKeywords] = useState([]);
    const [customizeImageSize, setCustomizeImageSize] = useState([]);

    const handleMetaKeywordsChange = (keywords) => setMetaKeywords(keywords);
    const handleSizeChange = (ImageSize) => setCustomizeImageSize(ImageSize);

    const [colorProducts, setColorProducts] = useState([
        {
            colourCode: '',
            images: [{ name: '', image: '' }]
        }
    ]);

    const customStyles = (isMulti) => ({
        control: (provided, state) => ({
            ...provided,
            height: 'auto', // Set the height to auto
            minHeight: isMulti ? '38px' : '40px', // Set a minimum height
            padding: '5px', // Add some padding
            overflow: 'hidden', // Prevent overflow
        }),
        multiValue: (provided) => ({
            ...provided,
            maxWidth: '100%', // Allow multi values to take full width
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            overflow: 'hidden',
            whiteSpace: 'nowrap', // Prevent text wrapping
            textOverflow: 'ellipsis', // Add ellipsis for overflow text
        }),
    });

    return (
        <>
            <Navbar />

            <div className='pt-5 pb-5' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                <div className='d-flex fw-medium' style={{ color: '#E4510B' }} ><p style={{ cursor: 'pointer', color: '#333333' }} onClick={() => navigate('/admin/product')}>Product  {`>`}</p> Add Product</div>
                <h1 className='mb-5'>Add Product</h1>

                <Form onSubmit={handleSubmit(submitForm)}>
                    <Card className='p-4'>
                        <h1 className='fs-3 mb-4'>Product Details</h1>
                        <Row >
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Choose Category</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='productCategory'
                                        id='productCategory'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                type="select"
                                                {...field}
                                                options={options}
                                                invalid={errors.productCategory && true}
                                            >
                                            </Select>
                                        )}
                                    />
                                    {errors.productCategory && <FormFeedback>{errors.productCategory.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>New Product Name</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='name'
                                        id='name'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field}
                                            invalid={errors.name && true}
                                            placeholder="Enter new product name" />)}
                                        onInput={(e) => {
                                            const inputValue = e.target.value;
                                            if (inputValue.length === 1) {
                                                e.target.value = inputValue.replace(/[^A-Za-z]/g, '');
                                            } else {
                                                e.target.value = inputValue.replace(/[^A-Za-z0-9]/g, '');
                                            }
                                        }}
                                    />
                                    {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3 s'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Full Description</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='fullDescription'
                                        id='fullDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input type="textarea" rows={5} cols={60} {...field} invalid={errors.fullDescription && true} placeholder="Enter Full Description" />)}
                                    />
                                    {errors.fullDescription && <FormFeedback>{errors.fullDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='description'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Description {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`description[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.description && errors.description[index] && errors.description[index].description && true}
                                                                    placeholder={`Enter product description`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.description && errors.description[index] && errors.description[index].description && (
                                                                    <FormFeedback>{errors.description[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Description
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Overview</h1>
                        <Row >
                            <Col sm={12} md={12} lg={12}>
                                <div className='mb-3'>
                                    <Controller
                                        name='productOverview'
                                        control={control}
                                        defaultValue={[]}
                                        render={({ field }) => (
                                            <div>
                                                <Row>
                                                    {field.value.map((item, index) => (
                                                        <Col key={index} sm={12} md={6} lg={4}>
                                                            <div className='mb-3 d-flex flex-column'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                                                    <div className='d-flex justify-content-between align-items-center'>
                                                                        <div>Product Overview {index + 1}</div>
                                                                        <div className='justify-content-end ms-2 mt-1'
                                                                            color='danger'
                                                                            size='sm'
                                                                            style={{ cursor: 'pointer', color: '#AF1B1B' }}
                                                                            onClick={() => {
                                                                                const updatedValues = [...field.value];
                                                                                updatedValues.splice(index, 1);
                                                                                field.onChange(updatedValues);
                                                                            }}
                                                                        >
                                                                            <Trash2 />
                                                                        </div>
                                                                    </div>
                                                                </Label>
                                                                <Input
                                                                    type="textarea" rows={5} cols={60}
                                                                    name={`productOverview[${index}].description`}
                                                                    value={field.value[index]?.description || ''}
                                                                    invalid={errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && true}
                                                                    placeholder={`Enter product overview`}
                                                                    onChange={(e) => {
                                                                        const updatedValues = [...field.value];
                                                                        updatedValues[index] = { description: e.target.value };
                                                                        field.onChange(updatedValues);
                                                                    }}
                                                                />
                                                                {errors.productOverview && errors.productOverview[index] && errors.productOverview[index].description && (
                                                                    <FormFeedback>{errors.productOverview[index].description.message}</FormFeedback>
                                                                )}
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                                <Button
                                                    className='overview-btn'
                                                    size='sm'
                                                    onClick={() => {
                                                        field.onChange([...field.value, { description: '' }]);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Overview
                                                </Button>
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Upload Images</h1>
                        <Row>

                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Icon</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='icon'
                                        id='icon'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="icon"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.icon && true}
                                                    ref={imageRef1}
                                                    onChange={handleIconUpload}
                                                />
                                                {loads ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", justifyContent: "center", alignItems: "center", border: '1px dashed #E4510B' }} onClick={() => imageRef1?.current?.click()}>
                                                        {icon ? (
                                                            <>
                                                                <img
                                                                    alt="uploaded icon"

                                                                    src={icon}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setIcon(null)}                                                                    >
                                                                    <X />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your product icon</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Image</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='image'
                                        id='image'
                                        control={control}
                                        defaultValue=''
                                        render={({ field }) => (
                                            <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                <input
                                                    style={{ display: 'none' }}
                                                    id="image"
                                                    type="file"
                                                    {...field}
                                                    accept=".jpeg,.png,.jpg,.jfif"
                                                    invalid={errors.image && true}
                                                    ref={imageRef}
                                                    onChange={handleProfileUpload}
                                                />
                                                {loader ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <Card style={{ width: "100%", height: "160px", border: '1px dashed #E4510B', justifyContent: "center", alignItems: "center" }} onClick={() => imageRef?.current?.click()}>
                                                        {image ? (
                                                            <>
                                                                <img
                                                                    alt="uploaded image"

                                                                    src={image}
                                                                    style={{ width: '140px', objectFit: 'cover' }}
                                                                />
                                                                <div
                                                                    className='image-remove'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '1px',
                                                                        cursor: 'pointer',
                                                                        color: 'red',
                                                                    }}
                                                                    onClick={() => setImage(null)}                                                                    >
                                                                    <X />
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <div className='text-primary text-center'>
                                                                <h1 style={{ color: "primary" }}><RiImageAddFill /></h1>
                                                                <p>*Upload your Cover Images</p>
                                                            </div>
                                                        )}
                                                    </Card>

                                                )}
                                                {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4} >
                                <Label style={{ fontWeight: "500", color: '#262525' }}>
                                    <div className='d-flex'>
                                        <div>Product Gallery Image</div>
                                    </div>
                                </Label>
                                <Card className='p-3' style={{ display: 'flex', border: '1px dashed #E4510B', width: "100%", justifyContent: 'center', alignItems: 'center' }}>
                                    <div className="">
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {profileImages.map((image, index) => (
                                                <div key={index} className="m-2" style={{ position: 'relative' }}>
                                                    <img
                                                        alt={`preview image ${index + 1}`}
                                                        src={image.url}
                                                        style={{
                                                            width: '100px',
                                                            boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => setSelectedImageIndex(index)}
                                                    />
                                                    <div className="cursor-pointer image-close" style={{ position: 'absolute', top: '1px', right: '1px', cursor: 'pointer' }} onClick={() => handleRemoveImage(index)}>
                                                        <X style={{ width: '16px', height: '16px' }} />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                            <input style={{ display: 'none' }} type="file" ref={inputRef1} accept=".jpeg,.png,.jpg,.jfif" onChange={handleImageUpload} multiple />
                                            {loading ? (
                                                <Spinner />
                                            ) : (
                                                <div className='text-center text-primary' onClick={() => inputRef1?.current?.click()}>
                                                    {profileImages.length === 0 ? (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Upload your Gallery Images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <h1> <RiImageAddFill /></h1>
                                                            <p>*Add more images</p>
                                                            <p style={{ marginTop: '-15px' }}>(Preferably Vector Image)</p>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>GST Details</h1>
                        <Row >
                            <Col sm={12} md={6} lg={4}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Type</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstType'
                                        id='gstType'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                type="select"
                                                {...field}
                                                options={options1}
                                                invalid={errors.gstType && true}
                                            >
                                            </Select>
                                        )}
                                    />
                                    {errors.gstType && <FormFeedback>{errors.gstType.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={4}>
                                <div className="mb-3 ms-2">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>GSTIN Percentage</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='gstPercentage'
                                        id='gstPercentage'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.gstPercentage && true} placeholder="Enter Your GST Percentage" />
                                        )} />
                                    {errors.gstPercentage && (
                                        <FormFeedback>{errors.gstPercentage.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                            {/* <Col sm={12} md={6} lg={4}>
                                <div className="mb-3">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>HSN Code</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='hsnCode'
                                        id='hsnCode'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.hsnCode && true} placeholder="Enter Your HSN code" />
                                        )} />
                                    {errors.hsnCode && (
                                        <FormFeedback>{errors.hsnCode.message}</FormFeedback>
                                    )}
                                </div>
                            </Col> */}
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Product Fields</h1>

                        {fieldTypesFields.map((field, index) => (
                            <div key={field.id}>
                                <Row>
                                    <Col sm={12} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                            <Controller
                                                name={`fieldTypes[${index}].name`}
                                                id='options'
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        {...field}
                                                        isSearchable={false}
                                                        id='fieldTypes'
                                                        options={getOptionsFieldType}
                                                        value={field.value}
                                                        onChange={(selectedOption) => {
                                                            getFieldOption(selectedOption.value);
                                                            field.onChange(selectedOption);
                                                            getDatas(selectedOption.value, index)
                                                            handleTypeChange(selectedOption, index);

                                                        }}
                                                    />
                                                )} />

                                        </div>
                                    </Col>
                                    <Col sm={5} md={5}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Options</Label>
                                            <Controller
                                                name={`fieldTypes[${index}].options`}
                                                id='options'
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <Select
                                                        styles={{ width: '100%', height: '100%' }}
                                                        {...field}
                                                        options={fieldoption}
                                                        isMulti={true}
                                                        closeMenuOnSelect={false}
                                                        isSearchable={false}
                                                        invalid={errors.fieldType && true}
                                                    />
                                                )}
                                            />
                                            {errors.fieldType && errors.fieldType[index]?.options && (
                                                <FormFeedback>{errors.fieldType[index]?.options.message}</FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={2} md={2}>
                                        <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={(e) => {
                                            getFieldType(typeIds[index], index);
                                            removeFieldType(index)
                                        }}>
                                            <Trash2 />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        ))}
                        <div className='d-flex'>
                            <Button type="button" className='overview-btn' onClick={() => appendFieldType({ name: '', options: [] })}>
                                <PlusCircle /> Add Field
                            </Button>
                        </div>
                        <div className='mt-2 mb-2 mt-4' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                        <h1 className='fs-3 mb-4 mt-4'>Product Specifications</h1>
                        <Row>
                            <Col sm={12} md={6} lg={3}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Product Basic Amount</Label>
                                    <Controller
                                        name='amount'
                                        id='amount'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.amount && true} placeholder="Enter your Product Basic Amount" />)} />
                                    {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3}>
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity Type</Label>
                                    <Controller
                                        name='quantityType'
                                        id='quantityType'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Select
                                                isSearchable={false}
                                                type="select"
                                                {...field}
                                                options={quantityTypeDrop}
                                                invalid={errors.quantityType && true} >
                                            </Select>
                                        )} />
                                    {errors.quantityType && <FormFeedback>{errors.quantityType.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Minimum Quantity</Label>
                                    <Controller
                                        name='minimunQuantity'
                                        id='minimunQuantity'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.minimunQuantity && true} placeholder="Enter Minimun Quantity" />)} />
                                    {errors.minimunQuantity && <FormFeedback>{errors.minimunQuantity.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={3} >
                                <div className='mb-3'>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Maximum Quantity</Label>
                                    <Controller
                                        name='maximunQuantity'
                                        id='maximumQuantity'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.maximunQuantity && true} placeholder="Enter Maximun Quantity" />)} />
                                    {errors.maximunQuantity && <FormFeedback>{errors.maximunQuantity.message}</FormFeedback>}
                                </div>
                            </Col>

                            <Col sm={12} md={12} lg={6}>
                                <div className="col-span-12 md:col-span-12 lg:col-span-6 intro-y">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Size</Label>
                                    <div className="mt-1">
                                        <TagsInput
                                            placeHolder="Enter Product size"
                                            onChange={handleSizeChange}
                                            value={customizeImageSize}
                                        />
                                        <span>Press enter the size of the product</span>
                                        {errors.customizeImageSize && (
                                            <div className="mt-2 text-danger">
                                                {errors.customizeImageSize.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-1">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>Product Weight</div>
                                        </div>
                                    </Label>
                                    <div className='d-flex'>
                                        <div>
                                            <Controller
                                                name='productWeight'
                                                id='productWeight'
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => (
                                                    <Input style={{ height: '44px', width: '300px' }} type="number"  {...field} invalid={errors.productWeight && true} placeholder="Enter Your Product Weight" />
                                                )} />
                                            {errors.productWeight && (
                                                <FormFeedback>{errors.productWeight.message}</FormFeedback>
                                            )}

                                        </div>
                                        <div className='d-flex kg-gm-con'>
                                            <div onClick={() => setIsKg('kg')} className={`${isKg === 'kg' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>kg</h1>
                                            </div>
                                            <div onClick={() => setIsKg('gm')} className={`${isKg === 'gm' ? 'active-con' : ''} con-width`}>
                                                <h1 className='fs-6'>gm</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={4} md={4} lg={6}>
                                <div className="mb-3 mt-4">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>
                                        <div className='d-flex'>
                                            <div>No Shipping Charge Above</div>
                                        </div>
                                    </Label>
                                    <Controller
                                        name='noShipChargeAbove'
                                        id='noShipChargeAbove'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <Input style={{ height: '44px' }} type="number"  {...field} invalid={errors.noShipChargeAbove && true} placeholder="Enter Your amount" />
                                        )} />
                                    {errors.noShipChargeAbove && (
                                        <FormFeedback>{errors.noShipChargeAbove.message}</FormFeedback>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Quantity Based Amount</h1>

                        <div className='mt-3'>
                            {quantityBasedAmountFields.map((field, index) => (
                                <div key={field.id}>
                                    <Row>
                                        <Col sm={12} md={12} lg={5}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity From</Label>
                                                <Controller

                                                    name={`quantityBasedAmount[${index}].from`}
                                                    id='from'
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input type="number" style={{ height: '44px' }}
                                                            {...field}
                                                            invalid={errors.from && true}
                                                            placeholder="Enter Quantity From" />)} />
                                                {errors.from && <FormFeedback>{errors.from.message}</FormFeedback>}
                                            </div>
                                        </Col>

                                        <Col sm={12} md={6} lg={5}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Quantity To</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].to`}
                                                    id='to'
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (<Input style={{ height: '44px' }} type="number"
                                                        {...field} invalid={errors.to && true} placeholder="Enter Quantity To" />)} />
                                                {errors.to && <FormFeedback>{errors.to.message}</FormFeedback>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={5}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Amount</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].amount`}
                                                    id='amount'
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (<Input style={{ height: '44px' }} type="number" {...field} invalid={errors.amount && true} placeholder="Enter Your Amount" />)} />
                                                {errors.amount && <FormFeedback>{errors.amount.message}</FormFeedback>}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={12} lg={5}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Type</Label>
                                                <Controller
                                                    name={`quantityBasedAmount[${index}].type`}
                                                    id='type'
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            isSearchable={false}
                                                            options={quantityBasedType}
                                                            invalid={errors.form && true}

                                                        />
                                                    )}
                                                />
                                                {errors.type && errors.type[index]?.name && (
                                                    <FormFeedback>{errors.type[index]?.name.message}</FormFeedback>
                                                )}
                                            </div>
                                        </Col>
                                        <Col sm={12} md={6} lg={2}>
                                            <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => { removeQuantityBasedAmount(index); }}>
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))}
                            <div className='d-flex py-3'>
                                <Button type="button" className='overview-btn' onClick={() => appendQuantityBasedAmount({})}>
                                    <PlusCircle /> Add Field
                                </Button>
                            </div>
                        </div>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Customization Details</h1>
                        <Row>
                            <Col sm={12}>
                                <div className='mb-1 d-flex flex-column'>
                                    <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                        Upload Users Design
                                    </Label>
                                    <Controller
                                        name='upload'
                                        id='upload'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <div className="d-flex">
                                                <div >
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === 'true' ? '#007bff' : 'transparent',
                                                            color: field.value === 'true' ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value="true"
                                                        />
                                                        <span className='ml-1'> True</span>
                                                    </label>
                                                </div>
                                                <div className='ms-3'>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === 'false' ? '#007bff' : 'transparent',
                                                            color: field.value === 'false' ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value="false"
                                                        />
                                                        <span className='ml-1'> False</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errors.upload && <FormFeedback>{errors.upload.message}</FormFeedback>}
                                </div>
                            </Col>
                            <div className='mt-2 mb-2' style={{ width: '100%', height: '1px', backgroundColor: '#DFDFDF' }}></div>
                            <Col sm={12}>
                                <div className='mb-1 d-flex flex-column'>
                                    <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                        Customization
                                    </Label>
                                    <Controller
                                        name='customizeProduct'
                                        id='customizeProduct'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (
                                            <div className="d-flex">
                                                <div >
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === 'true' ? '#007bff' : 'transparent',
                                                            color: field.value === 'true' ? '#fff' : '#000',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value="true"
                                                            // checked={field.value === 'true'}
                                                            onClick={() => { setShowCustomUpload(true); handleRadioChange('true') }}
                                                        />
                                                        <span className='ml-1'> True</span>
                                                    </label>
                                                </div>
                                                <div className='ms-3'>
                                                    <label
                                                        style={{
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            border: '1px solid #ccc',
                                                            borderRadius: '3px',
                                                            backgroundColor: field.value === 'false' ? '#007bff' : 'transparent',
                                                            color: field.value === 'false' ? '#fff' : 'black',
                                                            width: "100px",
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <input
                                                            type="radio"
                                                            {...field}
                                                            value="false"
                                                            // checked={field.value === 'false'}
                                                            onClick={() => { setShowCustomUpload(false); handleRadioChange('false') }}
                                                        />
                                                        <span className='ml-1'> False</span>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    />
                                    {errors.upload && <FormFeedback>{errors.upload.message}</FormFeedback>}
                                </div>
                            </Col>
                            {showCustomUpload && (
                                <Col sm={12} md={6} lg={12}>
                                    <h1 className='fs-3 mb-4 mt-4'>Upload Customization Image</h1>

                                    {colorProducts.map((colorProduct, colorIndex) => (
                                        <div key={colorIndex} className='mt-3'>
                                            <Row className='mb-3'>
                                                <Col sm={12} md={6} lg={6}>
                                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Color Code</Label>
                                                    <div className='d-flex'>
                                                        <Input
                                                            type="text"
                                                            style={{ height: '44px' }}
                                                            value={colorProduct.colourCode}
                                                            onChange={(e) => {
                                                                const updatedColorProducts = [...colorProducts];
                                                                updatedColorProducts[colorIndex].colourCode = e.target.value;
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                            placeholder='Enter Color Code'
                                                        />
                                                        <div
                                                            className='ms-2'
                                                            style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                            onClick={() => {
                                                                const updatedColorProducts = colorProducts.filter((_, i) => i !== colorIndex);
                                                                setColorProducts(updatedColorProducts);
                                                            }}
                                                        >
                                                            <Trash2 />
                                                        </div>
                                                    </div>
                                                </Col>

                                            </Row>
                                            {colorProduct.images.map((imageData, imageIndex) => (
                                                <div key={imageIndex} className='mt-3'>
                                                    <Row className='d-flex justify-content-center'>
                                                        <Col sm={12} md={5} lg={5}>
                                                            <div className='mb-3'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Name</Label>
                                                                <Input
                                                                    type='text'
                                                                    style={{ height: '44px' }}
                                                                    value={imageData.name}
                                                                    placeholder='Enter Custom Image Name'
                                                                    onChange={(e) => {
                                                                        const updatedColorProducts = [...colorProducts];
                                                                        updatedColorProducts[colorIndex].images[imageIndex].name = e.target.value;
                                                                        setColorProducts(updatedColorProducts);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={3} md={3} lg={3}>
                                                            <div className='mb-3'>
                                                                <Label style={{ fontWeight: "500", color: '#262525' }}>Image</Label>
                                                                <div className="d-flex" style={{ cursor: 'pointer' }}>
                                                                    <input
                                                                        style={{ display: 'none' }}
                                                                        type="file"
                                                                        accept=".jpeg,.png,.jpg,.jfif"
                                                                        ref={el => (imageRefs.current[colorIndex * 100 + imageIndex] = el)}
                                                                        onChange={(e) => handleCustomImageUpload(e, colorIndex, imageIndex)}
                                                                    />
                                                                    <Card
                                                                        style={{ width: "100px", height: "100px", justifyContent: "center", alignItems: "center" }}
                                                                        onClick={() => imageRefs.current[colorIndex * 100 + imageIndex]?.click()}
                                                                    >
                                                                        {imageData.image ? (
                                                                            <>
                                                                                <img
                                                                                    alt="uploaded image"
                                                                                    src={imageData.image}
                                                                                    style={{ width: '60px', objectFit: 'cover' }}
                                                                                />
                                                                                <div
                                                                                    className='image-remove'
                                                                                    style={{
                                                                                        position: 'absolute',
                                                                                        top: '5px',
                                                                                        right: '1px',
                                                                                        cursor: 'pointer',
                                                                                        color: 'red',
                                                                                    }}
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation(); // Prevent triggering the file input click
                                                                                        const updatedColorProducts = [...colorProducts];
                                                                                        updatedColorProducts[colorIndex].images[imageIndex].image = '';
                                                                                        setColorProducts(updatedColorProducts);
                                                                                    }}
                                                                                >
                                                                                    <X />
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div className='text-primary d-flex align-items-center w-75 h-75'>
                                                                                <div className='text-center'>
                                                                                    <h1 style={{ color: "primary" }}><RiImageAddFill size={20} /></h1>
                                                                                    <p style={{ fontSize: '12px' }}>*Upload your Custom Image</p>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col sm={2} md={2} lg={2}>
                                                            <div
                                                                className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                                                style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                                                onClick={() => {
                                                                    const updatedColorProducts = [...colorProducts];
                                                                    updatedColorProducts[colorIndex].images = updatedColorProducts[colorIndex].images.filter((_, i) => i !== imageIndex);
                                                                    setColorProducts(updatedColorProducts);
                                                                }}
                                                            >
                                                                <Trash2 />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr />
                                                </div>
                                            ))}

                                            <div className='d-flex'>
                                                <Button
                                                    type="button"
                                                    className='overview-btn'
                                                    onClick={() => {
                                                        const updatedColorProducts = [...colorProducts];
                                                        updatedColorProducts[colorIndex].images.push({ name: '', image: '' });
                                                        setColorProducts(updatedColorProducts);
                                                    }}
                                                >
                                                    <PlusCircle /> Add Custom Image
                                                </Button>
                                            </div>
                                            <hr />
                                        </div>
                                    ))}

                                    <div className='d-flex mt-3'>
                                        <Button
                                            type="button"
                                            className='overview-btn'
                                            onClick={() => setColorProducts([...colorProducts, { colourCode: '', images: [{ name: '', image: '' }] }])}
                                        >
                                            <PlusCircle /> Add Color Product
                                        </Button>
                                    </div>
                                </Col>

                            )}
                        </Row>
                    </Card>
                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Delivery Locations</h1>

                        <Col sm={12}>
                            <div className='mb-1 d-flex flex-column'>
                                <Label style={{ marginBottom: '5px', paddingRight: "10px", fontWeight: "500", color: '#262525' }}>
                                    Choose Delivery Method
                                </Label>
                                <Controller
                                    name='customizeLocations'
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                        <div className="d-flex">
                                            <label
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '3px',
                                                    backgroundColor: field.value === 'true' ? '#007bff' : 'transparent',
                                                    color: field.value === 'true' ? '#fff' : '#000',
                                                    width: "180px",
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    {...field}
                                                    value="true"
                                                    onChange={(e) => {
                                                        setIsManualLocations(true); // Show manual locations form
                                                        field.onChange(e);
                                                    }}
                                                />
                                                <span className='ms-1'>Manual Locations</span>
                                            </label>
                                            <label
                                                className='ms-3'
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '3px',
                                                    backgroundColor: field.value === 'false' ? '#007bff' : 'transparent',
                                                    color: field.value === 'false' ? '#fff' : '#000',
                                                    width: "150px",
                                                    textAlign: 'center'
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    {...field}
                                                    value="false"
                                                    onChange={(e) => {
                                                        // Reset all selected states, districts, and pincodes
                                                        setSelectedState([]);
                                                        setSelectedDistricts([]);
                                                        setSelectedPincodes([]);
                                                        setIsManualLocations(false);
                                                        resetField('deliveryLocation');// Hide manual locations form
                                                        field.onChange(e); // Ensure field value updates
                                                    }}
                                                />
                                                <span className='ms-1'>All Locations</span>
                                            </label>
                                        </div>
                                    )}
                                />
                                {errors.customizeLocations && <FormFeedback>{errors.customizeLocations.message}</FormFeedback>}
                            </div>
                        </Col>

                        {/* Render state and district fields conditionally */}
                        {isManualLocations && (
                            <>
                                {deliveryLocationFields.map((field, index) => (
                                    <div className='mt-2' key={field.id}>
                                        <Row>
                                            <Col sm={12} md={3}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Delivery Available State</Label>
                                                    <Controller
                                                        name={`deliveryLocation[${index}].state`}
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array of objects (label, value)
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getState}  // Ensure options have the correct format (label, value)
                                                                isMulti={false}
                                                                placeholder="Select State"
                                                                closeMenuOnSelect={false}
                                                                isSearchable={true}
                                                                onInputChange={(inputValue) => {
                                                                    getStateOption(inputValue, index); // Fetch districts based on input and current index
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handleStateChange1(selectedOption, index);
                                                                    field.onChange(selectedOption); // Pass the entire object
                                                                }}
                                                                value={field.value}  // Set value directly from field.value (must match option format)
                                                                invalid={errors?.deliveryLocation?.[index]?.state && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.state && (
                                                        <FormFeedback>{errors.deliveryLocation[index].state.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Delivery Available Districts</Label>
                                                    <Controller
                                                        name={`deliveryLocation[${index}].district`}  // Ensure unique names for each field
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getDistrict}  // Filtered district options
                                                                isMulti={true}
                                                                closeMenuOnSelect={false}
                                                                placeholder='Select Districts'
                                                                isSearchable={true}
                                                                menuPortalTarget={document.body} // To render menu in the body
                                                                styles={customStyles(true)}
                                                                onInputChange={(inputValue) => {
                                                                    getDistrictOption(inputValue, index); // Fetch districts based on input and current index
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handleDistrictChange(selectedOption, index); // Update selected districts
                                                                    field.onChange(selectedOption); // Always set value as array
                                                                }}
                                                                value={field.value}  // Ensure field.value is an array before using includes
                                                                invalid={errors?.deliveryLocation?.[index]?.district && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.district && (
                                                        <FormFeedback>{errors.deliveryLocation[index].district.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col sm={12} md={4}>
                                                <div className='mb-3'>
                                                    <Label style={{ fontWeight: "bolder" }}>Select Unavailable Pincode</Label>
                                                    <Controller
                                                        name={`deliveryLocation[${index}].pincode`}  // Ensure unique names for each state field
                                                        control={control}
                                                        defaultValue={[]}  // Ensure defaultValue is an array
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={getPincode}  // Use the updated state containing pincodes
                                                                isMulti={true}
                                                                placeholder='Select Unavailable Pincodes'
                                                                closeMenuOnSelect={false}
                                                                isSearchable={true}
                                                                menuPortalTarget={document.body} // To render menu in the body
                                                                styles={customStyles(true)}
                                                                onInputChange={(inputValue) => {
                                                                    getPincodeOption(selectedDistricts[index], inputValue, index); // Fetch pincodes based on selected districts
                                                                }}
                                                                onChange={(selectedOption) => {
                                                                    handlePincodeChange(selectedOption, index); // Update selected pincodes
                                                                    field.onChange(selectedOption); // Always set value as array
                                                                }}
                                                                value={field.value}  // Ensure field.value is an array before using includes
                                                                invalid={errors?.deliveryLocation?.[index]?.pincode && true}
                                                            />
                                                        )}
                                                    />
                                                    {errors?.deliveryLocation?.[index]?.pincode && (
                                                        <FormFeedback>{errors.deliveryLocation[index].pincode.message}</FormFeedback>
                                                    )}
                                                </div>
                                            </Col>

                                            <Col sm={2} md={1}>
                                                <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => {
                                                    removeDeliveryLocation(index);
                                                }}>
                                                    <Trash2 />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <div>
                                    <hr />
                                </div>
                                <div className='d-flex'>
                                    <Button type="button" className='overview-btn' onClick={() => appendDeliveryLocation({ state: [], district: [], pincode: [] })}>
                                        <PlusCircle /> Add Field
                                    </Button>
                                </div>
                            </>
                        )}
                    </Card>


                    {/* {deliveryLocation.map((field, index) => (
                        <div key={field.id}>
                            <Row>
                                <Col sm={12} md={3}>
                                    <div className='mb-3'>
                                        <Label style={{ fontWeight: "500", color: '#262525' }}>Select Delivery Available State</Label>
                                        <Controller
                                            name={`deliveryLocation[${index}].state`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isSearchable
                                                    placeholder='Select State'
                                                    options={getState} // Dynamically updated options
                                                    value={field.value}
                                                    onInputChange={(inputValue) => {
                                                        getStateOption(inputValue);
                                                    }}
                                                    onChange={(selectedOption) => {
                                                        setSelectedState(selectedOption);
                                                        getDistrictOption(); // Load districts when state changes
                                                        field.onChange(selectedOption);
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </Col>

                                <Col sm={12} md={4}>
                                    <div className='mb-3'>
                                        <Label style={{ fontWeight: "500", color: '#262525' }}>Select Delivery Available Districts</Label>
                                        <Controller
                                            name={`deliveryLocation[${index}].district`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isMulti
                                                    placeholder='Select Districts'
                                                    options={getDistrict} // Resolved district options
                                                    closeMenuOnSelect={false}
                                                    isSearchable
                                                    value={selectedDistricts}
                                                    onChange={(selectedOptions) => {
                                                        setSelectedDistricts(selectedOptions);
                                                        field.onChange(selectedOptions);
                                                        getPincodeOption(); // Load pincodes when districts change
                                                    }}
                                                    onInputChange={(inputValue) => {
                                                        getDistrictOption(inputValue);
                                                    }}
                                                />
                                            )}
                                        />
                                        {errors.fieldType && errors.fieldType[index]?.options && (
                                            <FormFeedback>{errors.fieldType[index]?.options.message}</FormFeedback>
                                        )}
                                    </div>
                                </Col>

                                <Col sm={12} md={4}>
                                    <div className='mb-3'>
                                        <Label style={{ fontWeight: "500", color: '#262525' }}>Select Unavailable Pincode</Label>
                                        <Controller
                                            name={`deliveryLocation[${index}].pincode`}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    isMulti
                                                    placeholder='Select Unavailable Pincodes'
                                                    closeMenuOnSelect={false}
                                                    options={getPincode}
                                                    isSearchable
                                                />
                                            )}
                                        />
                                        {errors.fieldType && errors.fieldType[index]?.options && (
                                            <FormFeedback>{errors.fieldType[index]?.options.message}</FormFeedback>
                                        )}
                                    </div>
                                </Col>

                                <Col sm={2} md={1}>
                                    <div
                                        className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3'
                                        style={{ cursor: 'pointer', color: "#AF1B1B" }}
                                        onClick={() => removeDeliveryLocation(index)}
                                    >
                                        <Trash2 />
                                    </div>
                                </Col>
                            </Row>

                            <div className='d-flex'>
                                <Button
                                    type="button"
                                    className='overview-btn mt-2'
                                    onClick={() => appendDeliveryLocation({ state: '', district: '', pincode: '' })}
                                >
                                    <PlusCircle /> Add Field
                                </Button>
                            </div>
                        </div>
                    ))
                    } */}

                    <Card className='p-4 mt-3'>
                        <h1 className='fs-3 mb-4'>Meta Details</h1>
                        <Row>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Title</Label>
                                    <Controller
                                        name='metaTitle'
                                        id='metaTitle'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="text" {...field} invalid={errors.metaTitle && true} placeholder="Enter your Meta Title" />)} />
                                    {errors.metaTitle && <FormFeedback>{errors.metaTitle.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={6}>
                                <div className='mb-3 '>
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Description</Label>
                                    <Controller
                                        name='metaDescription'
                                        id='metaDescription'
                                        control={control}
                                        defaultValue=""
                                        render={({ field }) => (<Input style={{ height: '44px' }} type="textarea" {...field} invalid={errors.metaDescription && true} placeholder="Enter your Meta Descritpion" />)} />
                                    {errors.metaDescription && <FormFeedback>{errors.metaDescription.message}</FormFeedback>}
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={12}>
                                <div className="">
                                    <Label style={{ fontWeight: "500", color: '#262525' }}>Meta Keywords</Label>
                                    <div className=" w-100">
                                        <TagsInput
                                            classNames='w-100'
                                            placeHolder="Enter Company Meta Keywords"
                                            onChange={handleMetaKeywordsChange}
                                            value={metaKeywords}
                                        />
                                        <span className='text-secondary ' style={{ fontSize: '13px' }}>Press enter to add Meta Keywords</span>
                                        {errors.metaKeywords && (
                                            <div className="mt-2 text-danger">
                                                {errors.metaKeywords.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>




                    {/* 
                    <Col sm={12} md={6} lg={12}>
                        <div className='d-flex mt-3'>
                            <div> <b>Shipping Charges :</b></div>
                        </div>

                        {shippingChargesFields.map((field, index) => (
                            <>
                                <div className='mt-2' key={field.id}>
                                    <Row>
                                        <Col sm={5} md={6}>
                                            <div className='mb-3'>
                                                <Label style={{ fontWeight: "bolder" }}>State</Label>
                                                <Controller
                                                    name={`shippingCharges[${index}].state`}  // Ensure unique names for each state field
                                                    control={control}
                                                    defaultValue={[]}  // Ensure defaultValue is an array
                                                    render={({ field }) => (
                                                        <Select
                                                            {...field}
                                                            options={getAvailableStates(index)}  // Filtered state options
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            isSearchable={true}
                                                            onChange={(selected) => {
                                                                field.onChange(selected ? selected.map(s => s.value) : []);  // Always set value as array
                                                                handleStateChange(selected, index);  // Update selected states
                                                            }}
                                                            value={states.filter(s => field.value && Array.isArray(field.value) ? field.value.includes(s.value) : false)}  // Ensure field.value is an array before using includes
                                                            invalid={errors?.shippingCharges?.[index]?.state && true}
                                                        />
                                                    )}
                                                />
                                                {errors?.shippingCharges?.[index]?.state && (
                                                    <FormFeedback>{errors.shippingCharges[index].state.message}</FormFeedback>
                                                )}
                                            </div>
                                        </Col>

                                        <Col sm={12} md={6}>
                                            <div className="mb-1">
                                                <Label style={{ fontWeight: "bolder" }}>Base Charge</Label>
                                                <Controller
                                                    name={`shippingCharges[${index}].baseCharge`}  // Unique name for each shipping charge
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            type="number"
                                                            {...field}
                                                            invalid={errors?.shippingCharges?.[index]?.baseCharge && true}
                                                            placeholder="Enter shipping base charge"
                                                        />
                                                    )}
                                                />
                                                {errors?.shippingCharges?.[index]?.baseCharge && (
                                                    <FormFeedback>{errors.shippingCharges[index].baseCharge.message}</FormFeedback>
                                                )}
                                            </div>
                                        </Col>

                                        <Col sm={12} md={8}>
                                            <div className="mb-1">
                                                <Label style={{ fontWeight: "bolder" }}>Charge per Quantity</Label>
                                                <Controller
                                                    name={`shippingCharges[${index}].perQuantity`}  // Unique name for each shipping charge
                                                    control={control}
                                                    defaultValue=""
                                                    render={({ field }) => (
                                                        <Input
                                                            type="number"
                                                            {...field}
                                                            invalid={errors?.shippingCharges?.[index]?.perQuantity && true}
                                                            placeholder="Enter shipping charge for per Quantity"
                                                        />
                                                    )}
                                                />
                                                {errors?.shippingCharges?.[index]?.perQuantity && (
                                                    <FormFeedback>{errors.shippingCharges[index].perQuantity.message}</FormFeedback>
                                                )}
                                            </div>
                                        </Col>

                                        <Col sm={2} md={2}>
                                            <div className='mt-0 mb-5 pt-0 mt-lg-4 mb-lg-3 pt-lg-3' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => {
                                                removeState(index)
                                            }}>
                                                <Trash2 />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <hr />
                                </div>
                            </>
                        ))}

                        <div className='d-flex mt-2'>
                            <Button type="button" className='overview-btn' onClick={() => appendState({ state: [], charge: '' })}>
                                <PlusCircle /> Add
                            </Button>
                        </div>
                    </Col> */}


                    <div className='d-flex justify-content-end mt-2'>
                        <Button
                            disabled={!isDirty || !isValid}
                            className='overview-btn' type='submit'>Create Product</Button>
                    </div>
                </Form >
            </div >
        </>
    )
}

export default AddProductIndex