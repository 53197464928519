import React, { useEffect, useRef, useState } from 'react'
import { Upload, X } from 'react-feather'
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Form, FormFeedback, Input, Label, Modal, ModalBody, Spinner } from 'reactstrap'
import { toast } from 'react-hot-toast';
import "../addModal/index.css";

import { productCategoryApi } from '../../../../ApiConfigs/ApiConfig';
import { uploadImage } from '../../upload';



function ProductCategoryEdit({ open, handleEdit, editData, additionalData }) {

    const inputRef = useRef(null);
    const inputRef1 = useRef(null);
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [profileIconUrl, setProfileIconUrl] = useState('');
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false)
    const [image, setImage] = useState(null)
    const [icon, setIcon] = useState(null)
    const [isNameNumber, setIsNameNumber] = useState(false)

    const formSchema = yup.object().shape({
        name: yup.string().required('Please Enter Categoty Name'),
        description: yup.string().required('Please Enter Your description'),
        hsnCode: yup.string().required('Please Enter Your HSN Code'),  // Correct casing
        minimumPrice: yup.string().required('Please Enter Your Starting Price'),
        category_url: yup.string().required('Please Enter Your Category URL'),
    });
    const {
        reset,
        control,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            name: editData?.name || "",
            description: editData?.description || "",
            hsnCode: editData?.hsnCode || "",
            minimumPrice: editData?.minimumPrice || "",
            category_url: editData?.category_url || "",
        }
    });

    const submitForm = async (data) => {
        const image = profileImageUrl;
        const icon = profileIconUrl;
        const hsnCode = data?.hsnCode;

        try {
            const response = await axios.put(`${productCategoryApi}/${editData._id}`, {
                ...data,
                image,
                icon,
                hsnCode, // Ensure the correct `hsnCode` is sent 
                customizedPrinting: isNameNumber
            });
            handleEdit();
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        reset({
            name: editData?.name,
            description: editData?.description,
            hsnCode: editData?.hsnCode,
            minimumPrice: editData?.minimumPrice,
            category_url: editData?.category_url,
        });
        setProfileImageUrl(editData?.image);
        setProfileIconUrl(editData?.icon);
        setImage(editData?.image);
        setIcon(editData?.icon);
        setIsNameNumber(editData?.customizedPrinting)
    }, [editData, reset, setValue]);

    const handleProfileUpload = async (e) => {
        if (e.target.files && e.target.files[0]) {
            setImage(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoader(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileImageUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoader(false)
            }
        }
    };

    const handleIconUpload = async (e) => {

        if (e.target.files && e.target.files[0]) {
            setIcon(URL.createObjectURL(e.target.files[0]));
        }
        if (e.target.files) {
            try {
                setLoading(true)
                const formData = new FormData();
                formData.append('file', e.target.files[0]);

                const uploadData = await uploadImage(formData);
                if (uploadData && uploadData.result && uploadData.result.length) {
                    setProfileIconUrl(uploadData.result[0].location);

                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoading(false)
            }
        }
    };


    return (
        <div className='modal-page'>
            <Modal
                isOpen={open}
                toggle={handleEdit}
                className='sidebar-lg'
                modalClassName='modal-slide-in sidebar-todo-modal'
                contentClassName='p-0'>
                <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                    <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'black' }}>
                        Edit Product Category
                    </h4>
                    <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={handleEdit} />
                </div>
                <ModalBody className='flex-grow-1'>
                    <Form onSubmit={handleSubmit(submitForm)}>

                        <div className='mb-1 mt-1'>
                            <Label className='modal-label'>Product Category Name <span className='text-danger'>*</span></Label>
                            <Controller
                                name='name'
                                id='name'
                                control={control}
                                defaultValue=""
                                render={({ field }) => (<Input type="text" {...field} invalid={errors.name && true} placeholder="Enter Categoty Name"
                                />)} />
                            {errors.name && <FormFeedback>{errors.name.message}</FormFeedback>}
                        </div>

                        <div className='mb-1'>
                            <Label className='modal-label'>Product Category Description <span className='text-danger'>*</span></Label>
                            <Controller
                                name='description'
                                id='description'
                                control={control}
                                defaultValue=""
                                render={({ field }) => (<Input type="text" {...field} invalid={errors.description && true} placeholder="Enter Your Description" />)} />
                            {errors.description && <FormFeedback>{errors.description.message}</FormFeedback>}
                        </div>
                        <div className='mb-1'>
                            <Label className='modal-label'>Product Category Basic Price <span className='text-danger'>*</span></Label>
                            <Controller
                                name='minimumPrice'
                                id='minimumPrice'
                                control={control}
                                defaultValue=""
                                render={({ field }) => (<Input type="number" {...field} invalid={errors.description && true} placeholder="Enter Your Starting Price"
                                    onInput={(e) => {
                                        let inputValue = e.target.value;
                                        inputValue = inputValue.replace(/^0+/, '');
                                        e.target.value = inputValue.replace(/[^0-9]/g, '');
                                    }}
                                />)} />
                            {errors.minimumPrice && <FormFeedback>{errors.minimumPrice.message}</FormFeedback>}
                        </div>
                        <div className='mb-1'>
                            <Label className='modal-label'>
                                HSN Code <span style={{ color: 'red' }}>*</span>
                            </Label>
                            <Controller
                                name='hsnCode'
                                id='hsnCode'
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: 'HSN code is required',
                                    pattern: {
                                        value: /^[0-9]{6}$/, // Ensures exactly 6 digits
                                        message: 'HSN code must be exactly 6 digits',
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        style={{ height: '44px' }}
                                        type="text" // Use text to control input length easily
                                        maxLength={6} // Restrict the user to typing 6 digits
                                        onInput={(e) => {
                                            e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Allow only numeric input
                                        }}
                                        invalid={!!errors.hsnCode}
                                        placeholder="Enter Your HSN code"
                                    />
                                )}
                            />
                            {errors.hsnCode && (
                                <FormFeedback>{errors.hsnCode.message}</FormFeedback>
                            )}
                        </div>
                        <div className='mt-3'>
                            <Label className='modal-label me-2'>
                                Need Customization Name/Number?{'  '}
                            </Label>
                            <Input
                                type="switch"
                                checked={isNameNumber}
                                onClick={() => {
                                    setIsNameNumber(!isNameNumber);
                                }}
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='modal-label'>Product Category URL <span className='text-danger'>*</span></Label>
                            <Controller
                                name='category_url'
                                id='category_url'
                                control={control}
                                defaultValue=""
                                render={({ field }) => (<Input type="text" {...field} invalid={errors.category_url && true} placeholder="Enter Your Category URL" />)} />
                            {errors.category_url && <FormFeedback>{errors.category_url.message}</FormFeedback>}
                        </div>
                        <div className="mb-5 mt-3">
                            <Label className='modal-label'>Product Category Image <span style={{ color: 'red' }}>*</span></Label>
                            <Controller
                                name='image'
                                id='image'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                            <input style={{ display: 'none' }} id="image" type="file" {...field} invalid={errors.image && true} ref={inputRef} onChange={handleProfileUpload} />
                                            {loader ?
                                                <Spinner />
                                                :
                                                <Button
                                                    style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                    color="primary"
                                                    onClick={() => {
                                                        inputRef?.current?.click();
                                                    }}
                                                >
                                                    <Upload size={15} />
                                                    <span className='ms-1'>Upload Image</span>
                                                </Button>

                                            }

                                            {errors.image && <FormFeedback>{errors.image.message}</FormFeedback>}
                                        </div>
                                        {profileImageUrl && (
                                            <div className="mt-2 d-flex">
                                                {image && (
                                                    <>
                                                        <img
                                                            alt="preview image"
                                                            src={profileImageUrl}
                                                            style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                        />
                                                        <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setImage(null)}>
                                                            <X />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="mb-5 mt-3">
                            <Label className='modal-label'>Product Category Icon <span style={{ color: 'red' }}>*</span></Label>
                            <Controller
                                name='icon'
                                id='icon'
                                control={control}
                                defaultValue=''
                                render={({ field }) => (
                                    <div>
                                        <div className="d-flex" style={{ cursor: 'pointer' }}>
                                            <input style={{ display: 'none' }} id="icon" type="file" {...field} invalid={errors.icon && true} ref={inputRef1} onChange={handleIconUpload} />
                                            {loading ?
                                                <Spinner />
                                                :
                                                <Button
                                                    style={{ backgroundColor: '#E4510B', border: 'none' }}
                                                    color="primary"
                                                    onClick={() => {
                                                        inputRef1?.current?.click();
                                                    }}
                                                >
                                                    <Upload size={15} />
                                                    <span className='ms-1'>Upload icon</span>
                                                </Button>

                                            }

                                            {errors.icon && <FormFeedback>{errors.icon.message}</FormFeedback>}
                                        </div>
                                        {profileIconUrl && (
                                            <div className="mt-2 d-flex">
                                                {icon && (
                                                    <>
                                                        <img
                                                            alt="preview icon"
                                                            src={profileIconUrl}
                                                            style={{ width: '200px', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}
                                                        />
                                                        <div className="image-close ms-1" style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={() => setIcon(null)}>
                                                            <X />
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <hr></hr>
                        <div className='d-flex'>
                            <button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} className='mt-1 w-50' type='button' onClick={handleEdit}>Cancel</button>
                            <button className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Update Category</button>
                        </div>
                        {/* <button className='mt-1 add-modal-btn' type='submit'>Submit</button> */}
                    </Form >
                </ModalBody>

            </Modal>
        </div >
    )
}

export default ProductCategoryEdit
