import React, { useState } from 'react'
import FooterSection from '../../../footer'
import Navbar from '../../../navbar'
import { useNavigate } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import './index.css'
import ClipArtTab from './ClipArtTab'
import LibraryTab from './LibraryTab'

function DynamicStrikers() {
    const [isActive, setIsActive] = useState('clip-art')
    const navigate = useNavigate()

    return (
        <div>
            <Navbar />
            <div className='pt-5' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '15%', backgroundColor: '#F8F8F8' }}>
                <div className='pt-1'>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ cursor: 'pointer', opacity: '0.6' }} onClick={() => navigate('/admin/productCategory')}>Product Category</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Clip Art & Library</span></p>
                    </div>
                    {/* <div style={{ width: '100%' }} className='d-flex align-items-center mt-4'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Clip Art & Library</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div> */}
                </div>
                <div className='mt-4'>
                    <div className='d-flex align-items-center gap-3'>
                        <div onClick={() => setIsActive('clip-art')} className='cursor-pointer'>
                            <h1 className={` ${isActive === 'clip-art' ? '' : 'text-secondary'}`} >Clip Art</h1>
                            <div className={`tab-title ${isActive === 'clip-art' ? 'tab-title-active' : ''}`}></div>
                        </div>
                        <div onClick={() => setIsActive('library')} className='cursor-pointer'>
                            <h1 className={` ${isActive === 'library' ? '' : 'text-secondary'}`}>Library</h1>
                            <div className={`tab-title ${isActive === 'library' ? 'tab-title-active' : ''}`}></div>
                        </div>
                    </div>
                </div>
                <div className='mt-3'>
                    {isActive === 'clip-art' ? (
                        <ClipArtTab />
                    ) : (
                        <LibraryTab />
                    )}
                </div>
            </div>
            <FooterSection />
        </div >
    )
}

export default DynamicStrikers
