import React, { useState, useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import './index.css';

const ImageSlider = ({ images }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (images && images.length > 0) {
            setSelectedImage(images[0]);
            setCurrentIndex(0);
        }
    }, [images]);

    useEffect(() => {
        if (images && images.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [images]);

    useEffect(() => {
        if (images && images.length > 0) {
            setSelectedImage(images[currentIndex]);
        }
    }, [currentIndex, images]);

    if (!images || images.length === 0) {
        return null;
    }

    const handleImageClick = (image, index) => {
        setSelectedImage(image);
        setCurrentIndex(index);
    };

    return (
        <>
            <div className='d-none d-lg-block'>
                <div className='position-relative'>
                    <div className="main-image-container position-relative">
                        {selectedImage && (
                            <ReactImageMagnify
                                {...{
                                    smallImage: {
                                        alt: 'main',
                                        isFluidWidth: true,
                                        src: selectedImage
                                    },
                                    largeImage: {
                                        src: selectedImage,
                                        width: 1200,  // Full-size image width
                                        height: 1800  // Full-size image height
                                    },
                                    enlargedImagePosition: "over" // Places magnified image over the small image
                                }}
                                className="main-image"
                            />
                        )}
                    </div>
                    <div className="image-slider position-absolute top-0 start-0">
                        <div className="thumbnail-container">
                            {images.map((image, index) => (
                                <img
                                    key={index}
                                    src={image}
                                    alt={`thumbnail-${index}`}
                                    className={selectedImage === image ? 'selected-thumbnail' : ''}
                                    onClick={() => handleImageClick(image, index)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-lg-none'>
                <div className="main-image-container">
                    {selectedImage && (
                        <div className={`main-image ${window.innerWidth > 768 ? 'floating-magnify' : ''}`}>
                            {window.innerWidth > 768 ? (
                                <ReactImageMagnify
                                    {...{
                                        smallImage: {
                                            alt: 'main',
                                            isFluidWidth: true,
                                            src: selectedImage
                                        },
                                        largeImage: {
                                            src: selectedImage,
                                            width: 1200,
                                            height: 1800
                                        }
                                    }}
                                    className="main-image"
                                />
                            ) : (
                                <img src={selectedImage} alt="main" className="main-image" />
                            )}
                        </div>
                    )}
                </div>
                <div className="thumbnail-container">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`thumbnail-${index}`}
                            className={selectedImage === image ? 'selected-thumbnail' : ''}
                            onClick={() => handleImageClick(image, index)}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};

export default ImageSlider;
