import React, { useState } from 'react'
import './index.css'
import { Col } from 'reactstrap'
import TestimonialIndex from '../testimonial'
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import about1 from "../../../assets/images/banner/about1.png";
import about2 from "../../../assets/images/banner/about2.png";
import about3 from "../../../assets/images/banner/about3.png";
import { AccordionSummary } from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { FaPlus, FaMinus } from 'react-icons/fa';
import Accordion from '@mui/material/Accordion';

function InteractiveHome({ isLoggedIn }) {

    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <>

            <TestimonialIndex />
            <div className='faqs'>

                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='mt-3 mt-lg-5 d-flex'>
                            <div>
                                <img className='img1' src={categoryLeft} alt="" />
                            </div>
                            <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                <h3 className='head'>Frequently Asked Questions</h3>
                            </div>
                            <div>
                                <img className='img1' src={categoryRight} alt="" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col>
                    <div style={{ paddingRight: '8%', paddingLeft: '8%' }}>
                        <div style={{ marginTop: '0px', marginLeft: '-20px' }} className='position-absolute style1'>
                            <img style={{}} src={about1} alt="" />
                        </div>
                        <div style={{ marginTop: '270px', marginLeft: '600px' }} className='position-absolute style1'>
                            <img style={{}} src={about2} alt="" />
                        </div>
                        <div style={{ marginTop: '-40px', marginLeft: '900px' }} className='position-absolute d-md-none style1'>
                            <img style={{}} src={about3} alt="" />
                        </div>
                        {[ // Array of accordion items
                            {
                                id: 'panel1',
                                question: "Can I make changes to my order after it's been placed?",
                                answer: 'For the best print quality, use high-resolution images and ensure your design fits within the designated print area. Avoid using copyrighted materials without permission.',
                            },
                            {
                                id: 'panel2',
                                question: 'What payment methods do you accept?',
                                answer: 'We accept all major credit cards, PayPal, and bank transfers.',
                            },
                            {
                                id: 'panel3',
                                question: 'Are there any design guidelines I should follow?',
                                answer: 'For the best print quality, use high-resolution images and ensure your design fits within the designated print area. Avoid using copyrighted materials without permission.',
                            },
                            {
                                id: 'panel4',
                                question: 'How can I contact customer support?',
                                answer: 'You can contact us through our support email or by calling our customer support line.',
                            },
                        ].map((item) => (
                            <Accordion
                                className='mt-4'
                                expanded={expanded === item.id}
                                onChange={handleChange(item.id)}
                                key={item.id}
                            >
                                <AccordionSummary
                                    style={{ height: '80px', borderBottom: '1px solid #ddd' }}
                                    expandIcon={expanded === item.id ? <FaMinus /> : <FaPlus />}
                                    aria-controls={`${item.id}-content`}
                                    id={`${item.id}-header`}
                                >
                                    <Typography>{item.question}</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: '0 10px', borderTop: '1px solid #ddd' }}>
                                    <div style={{ padding: '10px 0' }}>
                                        <Typography style={{ color: '#666666' }}>{item.answer}</Typography>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </div>
                </Col>
            </div >

        </>
    )
}

export default InteractiveHome
