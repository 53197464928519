import React, { useEffect, useState } from 'react';
import logo from "../../../assets/images/logo/printon logo-01.png";
import { Link, NavLink, useNavigate } from 'react-router-dom';
import visa from '../../../assets/images/logo/Visa-Logo.png';
import master from '../../../assets/images/logo/MasterCard_Logo.svg.png';
import paypl from '../../../assets/images/logo/068BjcjwBw0snwHIq0KNo5m-15.webp';
import america from '../../../assets/images/logo/American_Express-4876c7d0db694256968485f3085dfa78.png';
import whatsapp from "../../../assets/images/logo/whatsapp.png";
import { Button, FormFeedback, Input, InputGroup, InputGroupText, Label } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import '../../myProfile/index.css';
import { resetPassword } from '../../../ApiConfigs/ApiConfig';
import axios from 'axios';

const Reset = () => {

    // Validation schema using Yup
    const ResetSchema = yup.object().shape({
        password: yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(/[a-z]/, 'Must contain at least one lowercase letter')
            .matches(/[A-Z]/, 'Must contain at least one uppercase letter')
            .matches(/\d/, 'Must contain at least one number')
            .required('Enter Your Password'),
        confirmPassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Your Password'),
    });

    const [socialMedias, setSocialMedias] = useState({})
    const navigate = useNavigate();
    const location = useLocation(); // This will give you access to the current URL
    const [token, setToken] = useState('');
    const { handleSubmit, watch, control, formState: { errors }, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(ResetSchema),
        defaultValues: {}
    });

    const password = watch('password');
    const confirmPassword = watch('confirmPassword');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);
    const isPasswordValid = password && confirmPassword && password === confirmPassword;

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenFromUrl = queryParams.get('token'); // Get the 'token' parameter
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        }
    }, [location.search]);

    const submitForm = async (data) => {
        const payload = {
            password: data.password,
            confirmPassword: data.confirmPassword,
        };

        try {
            const response = await axios.post(`${resetPassword}?token=${token}`, payload);
            toast.success(response?.data?.msg);
            reset();
            navigate('/');
        } catch (error) {
            toast.error("Failed to reset password");
        }
    };

    return (
        <>
            <div style={{ paddingBottom: '10%', height: '100vh' }}>
                {/* Header */}
                <div className='d-flex align-items-center' style={{ background: '#FFEFE6 ', paddingLeft: '5%', paddingRight: '5%', height: '10vh' }}>
                    <Link to='/'>
                        <img style={{ width: '200px', cursor: 'pointer' }} src={logo} alt="Logo" />
                    </Link>
                </div>

                {/* Main Section */}
                <div className='d-flex flex-column justify-content-center align-items-center' style={{ height: '81vh' }}>
                    <div className='d-flex flex-column justify-content-center align-items-center mt-3'>
                        <h2>Reset account password</h2>
                        {/* <p className='mt-3' style={{ fontSize: '18px' }}>
                            Enter a new password for <span style={{ color: '#E4510B', fontSize: '18px' }}>{resetEmail}</span>
                        </p> */}

                        {/* Form */}
                        <form onSubmit={handleSubmit(submitForm)} className="d-block mt-3">
                            {/* Password Field */}
                            <div className='mb-2'>
                                <Label className='signup'>Password</Label>
                                <div className={`input-tick ${password && confirmPassword && password === confirmPassword ? 'valid' : ''}`}>
                                    <InputGroup>
                                        <Controller
                                            name='password'
                                            id='password'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type={showPassword ? "text" : "password"}
                                                    {...field}
                                                    invalid={errors.password && true}
                                                    placeholder="Enter Your Password"
                                                    className='m-0 p-0 ps-2'
                                                />
                                            )}
                                        />
                                        <InputGroupText onClick={togglePasswordVisibility} style={{ cursor: 'pointer', height: '40px' }}>
                                            {showPassword ? <FaEyeSlash style={{ color: '#E45D25' }} /> : <FaEye style={{ color: '#E45D25' }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </div>
                                <p style={{ fontSize: '11px', marginTop: '5px' }}>
                                    *Password must be at least 8 characters, one lowercase letter, one uppercase letter, and at least one number.
                                </p>
                                {errors.password && <FormFeedback>{errors.password.message}</FormFeedback>}
                            </div>

                            {/* Confirm Password Field */}
                            <div className='mb-2'>
                                <Label className='signup'>Confirm Password</Label>
                                <div className={`input-tick ${password && confirmPassword && password === confirmPassword ? 'valid' : ''}`}>
                                    <InputGroup>
                                        <Controller
                                            name='confirmPassword'
                                            id='confirmPassword'
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <Input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    {...field}
                                                    invalid={errors.confirmPassword && true}
                                                    placeholder="Enter Your Confirm Password"
                                                />
                                            )}
                                        />
                                        <InputGroupText onClick={toggleConfirmPasswordVisibility} style={{ cursor: 'pointer', height: '40px' }}>
                                            {showConfirmPassword ? <FaEyeSlash style={{ color: '#E45D25' }} /> : <FaEye style={{ color: '#E45D25' }} />}
                                        </InputGroupText>
                                    </InputGroup>
                                </div>
                                {errors.confirmPassword && <FormFeedback>{errors.confirmPassword.message}</FormFeedback>}
                            </div>

                            {/* Reset Password Button and Login Link */}
                            <div style={{ justifyContent: 'center' }} className="modal-footer d-flex flex-row align-items-center mt-4">
                                <Button
                                    className='me-2'
                                    type='submit'
                                    style={{
                                        backgroundColor: isPasswordValid ? "#E4510B" : "#d3d3d3", // Change color when invalid
                                        border: 'none',
                                        flex: 1,
                                        cursor: isPasswordValid ? 'pointer' : 'not-allowed' // Disable pointer when invalid
                                    }}
                                    disabled={!isPasswordValid} // Disable button when passwords do not match
                                >
                                    Reset password
                                </Button>
                                <div className='mt-3' style={{ flex: 1, textAlign: 'right' }}>
                                    <p className='log'>
                                        know your password?{' '}
                                        <a
                                            className='ms-2'
                                            style={{ color: '#E4510B', textDecoration: 'underline' }} // Removed underline
                                            href="/"
                                        >
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {/* Footer */}
                <div style={{ background: '#E4510B', paddingLeft: '5%', paddingRight: '5%', height: '9vh' }}>
                    <div className='footer-bottom d-lg-flex pt-2 pb-2'>
                        <div className='d-lg-flex w-50 justify-content-between'>
                            <p className='footer-bottom-head m-0 mb-2'>© 2024 printon.co.in</p>
                            <p className='footer-bottom-para me-3'><NavLink to="#">Site Map</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink to="/privacyPolicy">Privacy Policy</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink to="#">Cookie Policy</NavLink></p>
                            <p className='footer-bottom-para me-3'><NavLink to='/Terms'>Terms</NavLink></p>
                        </div>
                        <div className='footer-bottom-img flex-wrap mt-2 mt-md-0'>
                            <img src={visa} height={40} className='me-3' alt="Visa" />
                            <img src={master} height={40} className='me-3' alt="MasterCard" />
                            <img src={paypl} height={40} className='me-3' alt="PayPal" />
                            <img src={america} height={40} alt="American Express" />
                        </div>
                    </div>

                    {/* WhatsApp Floating Button */}
                    <div className='fixed-bottom footer-whatsapp right-50' style={{ zIndex: "6", left: "initial", marginLeft: "5px" }}>
                        <a className='whatsappLink' href={`https://wa.me/${socialMedias?.whatsApp?.whatsAppNumber}?text=${socialMedias?.whatsApp?.text}`} target='_blank' rel="noreferrer">
                            <img src={whatsapp} width="100" alt='WhatsApp' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reset;
