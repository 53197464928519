import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Form, FormFeedback, Input, Label, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { addDistrict1, district } from '../../../../../ApiConfigs/ApiConfig';
import Select from 'react-select';

function EditLocation({ modal, editData, editId, toggle, additionalData, id }) {

    const [getState, setState] = useState([]);
    const [getDistrict, setDistrict] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [totalPages, setTotalPages] = useState(1);

    const formSchema = yup.object().shape({
        state: yup.object().nullable().required('Please select a state'),
        districts: yup.array().min(1, 'Please select at least one district').required(),
    });

    const { reset, control, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            state: null,
            districts: [],
        }
    });

    useEffect(() => {
        // Fetch districts whenever the modal opens and if editData.state is present
        if (modal && editData?.state) {
            getDistrictOption(editData.state, searchValue);
        }
    }, [modal, editData?.state, searchValue]); // Add searchValue and currentPage as dependencies

    const handleReset = () => {
        reset({
            state: null,
            districts: [],
        });
    };

    const close = () => {
        toggle();
        handleReset();
    };

    const submitForm = async (data) => {
        const payload = {
            district: selectedDistricts.map(district => district.label),
        };

        try {
            const response = await axios.post(`${addDistrict1}/${editData?._id}`, payload);
            close();
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    const getDistrictOption = async (stateId, searchValue = '') => {
        if (!stateId) return; // Ensure stateId is provided
        try {
            const response = await axios.get(`${district}?state=${stateId}&search=${searchValue}`); // Include pagination parameters
            const options = response?.data?.result?.rows?.map((item) => ({
                label: item?.district_name_english,
                value: item?._id,
            }));
            setDistrict(options);
            setTotalPages(response.data.result.totalPages); // Assuming your API returns total pages
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistricts(selectedOption || []); // Update state with selected districts
        setValue('districts', selectedOption); // Update form state
    };

    const handleSearchChange = (inputValue) => {
        setSearchValue(inputValue);
        getDistrictOption(editData?.state, inputValue, 1); // Fetch districts
    };

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: '#2B2B2D' }}>
                    Add Delivery District Location
                </h4>
                <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={close} />
            </div>
            <ModalBody>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <div className='mb-1 mt-3'>
                        <Label>Delivery Available State</Label>
                        <Input
                            value={editData?.state}
                            readOnly
                        />
                        {errors.state && <FormFeedback>{errors.state.message}</FormFeedback>}
                    </div>
                    <div className='mb-1 mt-3'>
                        <Label>Select Delivery Available Districts</Label>
                        <Controller
                            name="districts"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={getDistrict}
                                    isMulti
                                    placeholder="Select Districts"
                                    onChange={handleDistrictChange}
                                    onInputChange={handleSearchChange} // Update search input handling
                                    value={field.value} // Set the value to selected districts
                                />
                            )}
                        />
                        {errors.districts && <FormFeedback>{errors.districts.message}</FormFeedback>}
                    </div>
                    <hr />
                    <div className='d-flex'>
                        <Button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} onClick={close} className='mt-1 w-50'>Cancel</Button>
                        <Button onClick={submitForm} className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default EditLocation;
