import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Form, FormFeedback, Input, Label, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { addPincode, district, pincode1 } from '../../../../../ApiConfigs/ApiConfig';
import Select from 'react-select';

function AddPincode({ modal, editData, editId, toggle, additionalData }) {
    const [getPincode, setPincode] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedPincodes, setSelectedPincodes] = useState([]);

    const formSchema = yup.object().shape({
        state: yup.object().nullable().required('Please select a state'),
        districts: yup.array().min(1, 'Please select at least one district').required(),
    });

    const { reset, control, setValue, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            state: null,
            districts: [],
        }
    });

    useEffect(() => {
        // Fetch pincodes whenever the modal opens and if editData.districts is present
        if (modal && editData?.districts) {
            setSelectedDistricts(editData.districts.map(district => ({ label: district, value: district })));
            getPincodeOption(editData.districts, searchValue); // Fetch pincodes for the given districts
        }
    }, [modal, editData?.districts, searchValue]); // No need for searchValue here

    const handleReset = () => {
        reset({
            state: null,
            districts: [],
        });
        setSelectedDistricts([]);
        setSelectedPincodes([]); // Reset pincodes selection
        setPincode([]);
    };

    const close = () => {
        toggle();
        handleReset();
    };

    const submitForm = async (data) => {
        const payload = {
            pincode: selectedPincodes.map(pincode => pincode.label),
        };

        try {
            const response = await axios.post(`${addPincode}/${editData?._id}`, payload);
            close();
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    const getPincodeOption = async (districtValues = [], searchValue = '') => {
        if (!districtValues.length) return; // Ensure there are selected districts
        try {
            const response = await axios.get(`${pincode1}?districts=${districtValues.join(",")}&search=${searchValue}`);
            const options = response?.data?.result?.map((item) => ({
                label: item?.pincode,
                value: item?._id,
            }));
            setPincode(options); // Set the pincodes based on the selected districts
        } catch (error) {
            console.error('Error fetching pincodes:', error);
        }
    };

    const handleDistrictChange = (selectedOption) => {
        setSelectedDistricts(selectedOption || []); // Update state with selected districts
        setValue('districts', selectedOption); // Update form state
        getPincodeOption(selectedOption.map(district => district.value)); // Fetch pincodes for selected districts
    };

    const handlePincodeChange = (selectedOption) => {
        setSelectedPincodes(selectedOption || []); // Update state with selected pincodes
    };

    const handleSearchChange = (inputValue) => {
        setSearchValue(inputValue);
        getPincodeOption(editData?.districts, inputValue, 1);
        // Optionally, you could fetch pincodes here based on the search value
    };

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: '#2B2B2D' }}>
                    Unavailable Pincode Location
                </h4>
                <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={close} />
            </div>
            <ModalBody>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <div className='mb-1 mt-3'>
                        <Label>Delivery Available Districts</Label>
                        <Input
                            value={editData?.districts?.join(', ') || ''} // Join the districts with a comma and a space
                            readOnly
                        />
                        {errors.state && <FormFeedback>{errors.state.message}</FormFeedback>}
                    </div>
                    <div className='mb-1 mt-3'>
                        <Label>Select Unavailable Pincodes</Label>
                        <Controller
                            name="pincodes"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={getPincode} // Use pincodes fetched based on districts
                                    isMulti
                                    placeholder="Select Pincodes"
                                    onChange={handlePincodeChange}
                                    onInputChange={handleSearchChange}
                                    value={selectedPincodes} // Set the value to selected districts
                                />
                            )}
                        />
                        {errors.districts && <FormFeedback>{errors.districts.message}</FormFeedback>}
                    </div>
                    <hr />
                    <div className='d-flex'>
                        <Button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} onClick={close} className='mt-1 w-50'>Cancel</Button>
                        <Button onClick={submitForm} className='mt-1 w-50 ms-2 add-modal-btn' type='submit'>Submit</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default AddPincode;
