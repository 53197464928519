import React from 'react'
import Navbar from '../../navbar'
import FooterSection from '..'
import './index.css';
import { useNavigate } from 'react-router-dom'
import { Col, Row } from 'reactstrap';
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import categoryLeft1 from "../../../assets/images/banner/category-left1.png";
import categoryRight1 from "../../../assets/images/banner/category-right1.png";
import about from "../../../assets/images/banner/about.png";
import aboutimg1 from "../../../assets/images/banner/aboutimg1.png";
import about1 from "../../../assets/images/banner/about1.png";
import about2 from "../../../assets/images/banner/about2.png";
import about3 from "../../../assets/images/banner/about3.png";
import about5 from "../../../assets/images/banner/about5.png";
import choose1 from "../../../assets/images/banner/choose1.png";
import choose2 from "../../../assets/images/banner/choose2.png";
import choose3 from "../../../assets/images/banner/choose3.png";
import choose4 from "../../../assets/images/banner/choose4.png";
import choosebg from "../../../assets/images/banner/choose-person-bg.png";
import chooseperson from "../../../assets/images/banner/choose-person.png";

function AboutUs() {
    const navigate = useNavigate();
    const navToHome = () => {
        navigate('/')
    }
    const navToAboutUs = () => {
        navigate('/aboutUs')
    }
    const steps = [
        {
            title: "Step 1",
            description: "If you're not completely satisfied with your order, please tell us. Please use live chat for a fast response."
        },
        {
            title: "Step 2",
            description: "In the event we cannot resolve it immediately, a member of our customer service team will come back to you to obtain details of the problem. In the first instance, we will need photos of the defective job to help us find the right solution."
        },
        {
            title: "Step 3",
            description: "We'll come back with a solution. We're very confident we can help. The most important thing for us is that you're happy."
        },
        {
            title: "Step 4",
            description: "In the unlikely event we can't fix our printing error, we'll give you a full refund."
        }
    ];

    return (
        <div>
            <Navbar />
            <div>
                <div className='about1'>
                    <Col lg={12} md={12} sm={12}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex categ11'>
                                <div>
                                    <img className='img1' src={categoryLeft} alt="" />
                                </div>
                                <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                    <h3 style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>Welcome to Printon</h3>
                                </div>
                                <div>
                                    <img className='img1' src={categoryRight} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='aout1-pa mt-2'>
                            <p>Read more about us, Our mission, and many other you might love</p>
                        </div>
                    </Col>
                </div>
                <div className='pb-5 pt-3' style={{ paddingLeft: '5%', paddingRight: '5%', background: '#FAFAFA' }}>
                    <p className='pt-3' style={{ cursor: 'pointer' }}><span style={{ color: '#333333' }} onClick={navToHome}>Home</span> / <span style={{ color: '#E4510B' }} onClick={navToAboutUs}>About Us</span></p>
                    <Col lg={12} sm={12} md={12}>
                        <div className='d-lg-flex'>
                            <Col sm={12} md={12} lg={8}>
                                <div>
                                    <div className='mt-5 d-block'>
                                        <div className=' d-flex align-items-center'>
                                            <h4 className='about-h'>About Us</h4>
                                            {/* <img src={aboutimg} alt="" /> */}
                                            {/* <hr className='ms-4 d-flex align-items-center' style={{ color: '#D4D4D4', width: '75% ', height: '5px' }} /> */}
                                            <hr className='ms-3' style={{ height: '2px', width: '80%', background: 'black' }} />
                                            {/* <div className='line'>
                                                <img className='ms-3 d-flex align-items-center' style={{ height: '2px', width: '80%' }} src={aboutimg} alt="" />
                                            </div> */}
                                        </div>
                                        <p style={{ color: '#2B2B2B', fontSize: '16px', lineHeight: '40px', fontWeight: '400' }} className='mt-5 me-4'> We specialize in high-quality custom products, from apparel and accessories to home decor and office supplies. Our mission is to bring your unique visions to life using cutting-edge printing technology and eco-friendly materials. We prioritize quality, sustainability, and customer satisfaction, ensuring vibrant and durable products delivered quickly and affordably. With a user-friendly platform and dedicated customer support, Printon makes customization easy and enjoyable. Join our community and discover the endless possibilities of personalized products with Printon. Thank you for choosing us to bring your ideas to life!</p>
                                    </div>
                                </div>

                            </Col>
                            <Col lg={4} md={12} sm={12}>
                                <div className='ms-lg-5 mt-5'>
                                    <img className='about1-img' src={about} alt="" />
                                </div>
                            </Col>
                        </div>
                    </Col>
                    <Col lg={12} sm={12} md={12}>
                        <div className='d-lg-flex mt-4'>
                            <Col lg={4} md={12} sm={12}>
                                <div className=' mt-5'>
                                    <img className='about1-img' src={aboutimg1} alt="" />
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={8}>
                                <div>
                                    <div className='mt-5 ms-lg-5 d-block'>
                                        <div className=' d-flex align-items-center'>
                                            <h4 className='about-h'>Our Mission</h4>
                                            {/* <img src={aboutimg} alt="" /> */}
                                            {/* <hr className='ms-4 d-flex align-items-center' style={{ color: '#D4D4D4', width: '75% ', height: '5px' }} /> */}
                                            <hr className='ms-3' style={{ height: '2px', width: '70%', background: 'black' }} />
                                            {/* <div className='line'>
                                                <img className='ms-3 line d-flex align-items-center' style={{ height: '2px', width: '70%' }} src={aboutimg} alt="" />
                                            </div> */}
                                        </div>
                                        <p style={{ color: '#2B2B2B', fontSize: '16px', lineHeight: '40px', fontWeight: '400' }} className='mt-3 me-4'> At Printon, our mission is to empower creativity and self-expression through high-quality custom products. We believe that every idea, no matter how big or small, deserves to be brought to life. By providing a diverse range of customizable items, from apparel and accessories to home decor and gifts, we offer endless possibilities for personalization.</p>
                                        <p style={{ color: '#2B2B2B', fontSize: '16px', lineHeight: '40px', fontWeight: '400' }} className=' me-4'>Customer satisfaction is our top priority. We strive to provide a seamless and enjoyable experience from design to delivery. Our user-friendly platform, competitive pricing, and dedicated support team ensure that creating custom products is easy and accessible for everyone.</p>
                                    </div>
                                </div>

                            </Col>
                        </div>
                    </Col>

                </div>

                <div className='pb-5 pt-1 choose' style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <Col lg={12} md={12} sm={12}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex categ11'>
                                <div>
                                    <img className='imgsc1' src={categoryRight1} alt="" />
                                </div>
                                <div className=' ms-4 me-4 mt-3 d-flex justify-content-center align-items-center'>
                                    <h3 style={{ fontWeight: '400', fontSize: '32px', font: 'Manrope' }}>Why Choose Us </h3>
                                </div>
                                <div>
                                    <img className='imgsc2' src={categoryLeft1} alt="" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className=' d-lg-flex mt-5'>
                        <Col className='d-flex' lg={5} ms={12} sm={12}>
                            <div className='chooseppp'>
                                <div>
                                    <img style={{ marginTop: '150px', marginLeft: '265px' }} className='about11 position-absolute' src={about1} alt="" />
                                </div>
                                <div className='position-relative'>
                                    <img className='choosebg' src={choosebg} alt="" />
                                </div>
                                <div>
                                    <img style={{ marginTop: '-100px', marginLeft: '45px' }} className='about11 position-absolute' src={about2} alt="" />
                                </div>
                                <div className='position-absolute ms-2'>
                                    <img className='chooseperson' src={chooseperson} alt="" />
                                </div>
                                <div>
                                    <img style={{ marginTop: '-320px', marginLeft: '250px' }} className='about11 position-absolute' src={about2} alt="" />
                                </div>
                                <div>
                                    <img style={{ marginTop: '-270px', marginLeft: '0px' }} className='about11 position-absolute' src={about5} alt="" />
                                </div>
                                <div>
                                    <img style={{ marginTop: '-380px', marginLeft: '320px' }} className='about11 position-absolute' src={about3} alt="" />
                                </div>
                            </div>
                        </Col>
                        <Col lg={7} md={12} sm={12}>
                            <div className='d-flex flex-column justify-content-end w-100 mt-5'>
                                <Col lg={12} sm={12} md={12}>
                                    <div className='d-lg-flex'>
                                        <Col lg={5} sm={12} md={12}>
                                            <div className='d-block choose-box'>
                                                <div className=' ms-4 me-4'>
                                                    <div>
                                                        <img className='choose-img' src={choose1} alt="" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <h6 className='choose-head'>Premium Quality</h6>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <p className='choose-para'>
                                                            We ensure top-notch quality for all our customized products. Our advanced production techniques guarantee vibrant prints and durable materials that will last.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className='mtsm-3' lg={6} sm={12} md={12}>
                                            <div className='d-block choose-box ms-lg-4'>
                                                <div className=' ms-4 me-4'>
                                                    <div>
                                                        <img className='choose-img' src={choose2} alt="" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <h6 className='choose-head'>Quick and Reliable Service</h6>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <p className='choose-para'>Time is of the essence, and we prioritize quick turnaround times without compromising quality. Enjoy fast production and shipping so you can get your customized products promptly.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col lg={12} sm={12} md={12}>
                                    <div className='d-lg-flex mt-4'>
                                        <Col lg={6} sm={12} md={12}>
                                            <div className='d-block choose-box'>
                                                <div className=' ms-4 me-4'>
                                                    <div>
                                                        <img className='choose-img' src={choose3} alt="" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <h6 className='choose-head'>Outstanding Customer Support</h6>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <p className='choose-para'>Our customer service team is dedicated to providing you with a seamless experience. From order placement to after-sales support, we’re here to help.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={5} sm={12} md={12}>
                                            <div className='d-block choose-box ms-lg-4'>
                                                <div className=' ms-4 me-4'>
                                                    <div>
                                                        <img className='choose-img' src={choose4} alt="" />
                                                    </div>
                                                    <div className='mt-2'>
                                                        <h6 className='choose-head'>Affordable Prices</h6>
                                                    </div>
                                                    <div className='mt-2'>
                                                        <p className='choose-para'>Get the best value for your money with our competitive pricing. We offer affordable rates without sacrificing the quality of our products.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                            </div>
                        </Col>
                    </div>
                </div>
                <div className='gurante'>
                    <Col lg={12} md={12} sm={12}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='d-flex categ11'>
                                <div>
                                    <img className='imgsc1' src={categoryRight1} alt="" />
                                </div>
                                <div className=' ms-4 me-4 mt-3 d-flex justify-content-center align-items-center'>
                                    <h3 style={{ fontWeight: '400', fontSize: '32px', font: 'Manrope' }}>Printon Guarantee </h3>
                                </div>
                                <div>
                                    <img className='imgsc2' src={categoryLeft1} alt="" />
                                </div>
                            </div>
                        </div>
                    </Col>
                    <div className=' pt-1' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%' }}>
                        <Col lg={12} sm={12} md={12}>
                            <div className='mt-3'>
                                <p className='d-flex text-center gran-para'>We're only truly happy when you are. We want you to be satisfied, of course, but we'd prefer it if you were absolutely thrilled with us. <br />
                                    We take great pride in the work we do. So if you ever receive a job from us and we have made a mistake in printing or finishing your job please get in touch as soon as possible and we'll do everything we can to fix it and reprint it for you.</p>
                            </div>
                        </Col>
                        <div className='mt-5 how'>
                            <Col lg={12} md={12} sm={12}>
                                <div className='d-block'>
                                    <div >
                                        <h5>How it works: </h5>
                                    </div>
                                    <div style={{ width: '100%', margin: '0 auto' }}>
                                        <Row>

                                            {steps.map((step, index) => (
                                                <Col key={index} lg={3} md={6} sm={12}>
                                                    <div style={{ border: '1px solid #EEEEEE', borderRadius: '20px', background: 'white', height: '300px', width: '100%', boxSizing: 'border-box' }} className='d-block mt-5'>
                                                        <div className='d-flex align-items-center mt-3 ' style={{ width: '100%' }}>
                                                            <h5 className='how-h5'>{step.title}</h5>
                                                            <hr className='' style={{ height: '2px', width: '65%', background: 'black' }} />
                                                            {/* <div className='line' style={{ maxWidth: '100%' }}>
                                                                    <img className='' style={{ height: '2px', width: '' }} src={aboutimg} alt="" />
                                                                </div> */}
                                                        </div>
                                                        <p className='how-para'>{step.description}</p>
                                                    </div>
                                                </Col>
                                            ))}

                                        </Row>
                                    </div>
                                </div>
                                <div className='d-block mt-5 note'>
                                    <div >
                                        <h5 style={{ color: '#DA3333', fontSize: '24px', fontWeight: '700', lineHeight: '35px' }}>Note: </h5>
                                    </div>
                                    <div>
                                        <p style={{ color: '#2B2B2B', fontSize: '16px', fontWeight: '400', lineHeight: '26px' }}> <span style={{ color: '#DA3333' }} >*</span> Please note we cannot be held responsible in any way for any errors, omissions or problems with your artwork as we are an 'upload to print' service and print the artwork you supply. We do not check each supplied piece of artwork for errors. Goods must be returned in full for a refund.</p>
                                    </div>
                                </div>
                            </Col>
                        </div>
                    </div>

                </div>
            </div >

            <FooterSection />
        </div >
    )
}

export default AboutUs