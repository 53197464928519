import React, { useCallback, useEffect, useState } from 'react'
import * as yup from "yup";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Label, Button, Row, Col, Modal, ModalHeader, ModalBody, Collapse } from 'reactstrap'
import toast from 'react-hot-toast';
import axios from 'axios';
import { notification, orderNotes, orderPost } from '../../../ApiConfigs/ApiConfig';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar, { timeAgo } from '../../navbar';
import FooterSection from '../../footer';
import { FaRegFileLines } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { AlertCircle, ChevronDown, ChevronRight, Eye, Plus } from 'react-feather';
import { BiCheck } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import step2 from "../../../assets/images/banner/step2.png";
import step1 from "../../../assets/images/banner/step1.png"
import './index.css'
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import PrintoutPage from './printout';
import ChangestatusPage from './changestatus';
import Preview from '../../myOrder/preview';

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'ordercompleted':
            return (
                <div className='disc-container-1 disc-con-clr-4 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-4'></div>
                    <p className='p-0 m-0'>New Order</p>
                </div>
            );
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>On Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-5 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-5'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};


function DashBoardViewPage() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [pdffile, setpdffile] = useState('');
    const navigate = useNavigate();

    const [addModal, setAddModal] = useState(false);
    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [customizeImages, setCustomizeImages] = useState([]);

    const handlechange = () => setAddModal(!addModal);
    const handleNoteModal = () => setOpenNoteModal(!openNoteModal);

    const formSchema = yup.object().shape({});
    const { } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const status = data?.status;

    const [openAccordion1, setOpenAccordion1] = useState(null);

    const toggleAccordion1 = (id) => {
        setOpenAccordion1(openAccordion1 === id ? null : id);
    };

    const getdata = useCallback(async () => {
        try {
            const response = await axios.get(`${orderPost}/${id}`);
            setData(response?.data?.result);
            setpdffile(response?.data?.result?.invoice);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    }, [id]);

    const getNotesdata = useCallback(async () => {
        try {
            const response = await axios.get(`${notification}?type=notes&order_id=${id}`);
            setNoteData(response?.data?.result);
        } catch (error) {
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        getdata();
        getNotesdata();
    }, [getdata, getNotesdata]);

    const orderNotesApi = async () => {
        const payload = {
            notes: noteText
        };
        try {
            const response = await axios.post(`${orderNotes}/${id}`, payload);
            handleNoteModal();
            setNoteText('');
            getdata();
            getNotesdata();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error.response.data.msg);
        }
    };

    const toggle = () => setModal(!modal);

    const viewCustom = (item) => {
        if (item?.customizeImages?.length > 1) {
            setCustomizeImages(item?.customizeImages);
        } else {
            setCustomizeImages([item?.file]);
        }
        toggle();
    };

    const handleDownload = async () => {
        const files = [...(data?.products[0]?.file || []), ...(data?.products[0]?.customizeImages || [])];

        if (files.length === 0) {
            alert("No files available to download.");
            return;
        }

        const zip = new JSZip();

        for (let i = 0; i < files.length; i++) {
            const fileUrl = files[i];
            try {
                const response = await fetch(fileUrl, { mode: 'cors' });
                if (!response.ok) throw new Error(`Failed to fetch ${fileUrl}`);
                const blob = await response.blob();
                const fileName = fileUrl.split('/').pop() || `file_${i}`;
                zip.file(fileName, blob);
            } catch (error) {
                console.error(`Error downloading ${fileUrl}:`, error);
                alert(`Download failed for file: ${fileUrl}`);
            }
        }

        const productName = data?.orderId || 'product';
        const currentDate = new Date().toISOString().split('T')[0]; // Format date as yyyy-mm-dd
        const zipFileName = `${productName}_${currentDate}.zip`;

        zip.generateAsync({ type: 'blob' })
            .then((content) => {
                saveAs(content, zipFileName);
            })
            .catch((err) => {
                console.error('Error generating zip file:', err);
                alert('Failed to generate zip file. Please try again.');
            });
    };

    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8', paddingBottom: '10%' }}>
                <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ opacity: '0.6' }} onClick={() => navigate('/admin/order-management')}>Order Management</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Action</span></p>

                    </div>
                    <div className='d-flex cent'>
                        <h3 style={{ fontSize: '28px' }}>Order Details</h3>
                        <hr className='ms-3 hrrr1' style={{ height: '2px', width: '85%', background: 'black' }} />
                    </div>
                </div>
                <span className='d-none'><PrintoutPage data={data} /></span>
                <div className='container mt-5' style={{
                    backgroundColor: 'rgba(255, 255, 255, 1)',
                    color: '#000',
                    borderRadius: '10px',
                    border: ' 1px solid #DFDFDF',
                    padding: '30px'
                }}>
                    <div style={{ marginLeft: '10px' }}>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order ID :</p>
                                <div className='ms-3 mt-1'>
                                    <p style={{ color: "#262525", fontSize: '20px', fontWeight: '500' }}>{data?.orderId}</p>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <p style={{ fontSize: '20px', fontWeight: '500', font: 'Manrope' }}>Order Status :</p>
                                <div className='ms-3'>
                                    {getDeliveryStatus(data?.status)}
                                </div>
                            </div>
                            {data?.status !== "ordercompleted" &&
                                <div className='d-flex'>
                                    <Button style={{ background: '#E4510B', color: 'white', border: 'none' }}>
                                        <a target='_blank' href={pdffile} style={{ background: '#E4510B', color: 'white', border: 'none' }} className='text-center text-decoration-none'>Download Invoice <FaRegFileLines size={20} /></a>
                                    </Button>
                                </div>
                            }

                        </div>
                        {data?.status === "ordercompleted" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >1</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage2' >2</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div>
                                        <Button className='statusUpdate1' onClick={handlechange}>
                                            Update Status as Process
                                        </Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                    </div>
                                    <Button className='statusUpdate2'>Update Status as Shipped</Button>
                                    <Button className='statusUpdate3'>Update Status as Delivered</Button>
                                </div>
                            </div>
                        }

                        {data?.status === "process" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >2</p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step1} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage3' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-50px' }}>
                                        <p className='papa'>
                                            Processed<br />
                                            on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </p>
                                    </div>
                                    <div>
                                        <Button onClick={handlechange} className='statusUpdate1 ms-3'>Update Status as Shipped</Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} address={data?.shipping} deliveryDate1={data?.expectedDeliveryDate?.split('T')[0]} />
                                    </div>
                                    <Button className='statusUpdate3'>Update Status as Delivered</Button>
                                </div>
                            </div>
                        }

                        {data?.status === "shipped" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage1' >3</p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                    <div>
                                        <Button style={{ marginRight: '-70px' }} onClick={handlechange} className='statusUpdate1'>Update Status as Delivered</Button>
                                        <ChangestatusPage open={addModal} handlechange={handlechange} getdata={getdata} dataId={id} status={status} />
                                    </div>
                                </div>
                            </div>
                        }

                        {data?.status === "delivered" &&
                            <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
                                <div style={{ paddingLeft: '5%', paddingRight: '3%', width: '100%' }} className='d-flex mt-4'>
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <img className='d-flex proces mt-3' src={step2} alt="" />
                                    <div>
                                        <p className='d-flex justify-content-center align-items-center mt-1 stage4' ><BiCheck style={{ color: 'white' }} /></p>
                                    </div>
                                    <hr />
                                </div>
                                <div style={{ paddingLeft: '2%', paddingRight: '2%' }} className='d-flex justify-content-between'>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-45px' }}>
                                        <p className='papa'>Processed<br />on {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'process')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginLeft: '-10px' }}>
                                        <p className='papa'>Shipped<br />on {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'shipped')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                    <div className='text-center' style={{ width: '200px', marginRight: '-60px' }}>
                                        <p className='papa'>Delivered<br />on {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleDateString()}
                                            <br />
                                            {new Date(data?.tracking?.find(status => status.status === 'delivered')?.date).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>

                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    <hr></hr>
                    <div id="invoice-container" >

                        <div style={{ marginLeft: '10px', marginTop: '30px' }} >
                            <Row>
                                <Col lg={12}>
                                    <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Order info</p>
                                </Col>
                                <Col lg={4} >
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Name<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.firstName} {data?.shipping?.lastName}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Email ID<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.email} </p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Mobile Number<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.mobile}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div className='mb-1 d-flex' >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '150px' }}>Alternate Number<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.alternateMobile}</p>
                                            </div>
                                        </Label>
                                    </div>


                                </Col>
                                <Col lg={4} >
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Address</p>
                                                <p className='ms-4 title1' >: {data?.shipping?.address}, {data?.shipping?.landmark},{data?.shipping?.city},{data?.shipping?.state}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Land Mark<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.landmark}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '90px' }}>Pincode<span ></span></p>
                                                <p className='title1'>: {data?.shipping?.pincode}</p>
                                            </div>
                                        </Label>
                                    </div>
                                </Col>
                                <Col className='mt-2' lg={4} >
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>GST Number<span ></span></p>
                                                <p className='title1'>: {data?.gstDetails?.gstNo}</p>
                                            </div>
                                            {/* <span style={{ color: 'gray', fontSize: '15px' }}> </span>
                                        <span>  </span> */}
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>HSN<span ></span></p>
                                                <p className='title1'>
                                                    :{data?.products?.length > 0 ? data.products[0].product?.hsnCode : "HSN Code not available"}
                                                </p>

                                            </div>
                                            {/* <span style={{ color: 'gray', fontSize: '15px' }}> </spa
                                        <span>  </span> */}
                                        </Label>
                                    </div>
                                    <div>
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>Ordered Date<span ></span></p>
                                                <p className='title1'>: {new Date(data?.createdAt).toLocaleDateString()}</p>
                                            </div>
                                        </Label>
                                    </div>
                                    <div >
                                        <Label>
                                            <div className='d-flex'>
                                                <p className='title' style={{ width: '190px' }}>Expected Delievery Date<span ></span></p>
                                                <p className='title1'>: {new Date(data?.expectedDeliveryDate).toLocaleDateString()}</p>
                                            </div>
                                        </Label>
                                    </div>

                                </Col>

                            </Row>
                        </div>
                        <hr></hr>
                        <Col lg={12}>
                            <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Product</p>
                        </Col>
                        <div>
                            <Row>
                                <Col lg={8}>
                                    <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px', height: 'auto' }} className="d-block">
                                        {data?.products && data.products.length > 0 ? (
                                            <>
                                                <div>
                                                    <p style={{ fontSize: '16px', fontWeight: '700', fontFamily: 'Lato', color: '#2B2B2D' }}>
                                                        {data.products[0]?.product?.name}
                                                    </p>
                                                </div>
                                                <div className='d-flex'>
                                                    <div className='d-block'>
                                                        <div className="d-flex">
                                                            <div>
                                                                {data.products[0]?.file?.[0] ? (
                                                                    // Check if the file is a PDF
                                                                    data.products[0]?.file[0].endsWith('.pdf') ? (
                                                                        // If it's a PDF, display a PDF icon instead of the file itself
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            <img
                                                                                src='https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png'
                                                                                alt="PDF Icon"
                                                                                style={{ width: '70px', height: '70px' }}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        // If it's an image, display the image
                                                                        <img
                                                                            style={{ width: '110px', height: '100%' }}
                                                                            src={data.products[0]?.file[0]}
                                                                            alt={data.products[0]?.product?.name || 'Product Image'}
                                                                        />
                                                                    )
                                                                ) : (
                                                                    <img
                                                                        style={{ width: '110px', height: '100%' }}
                                                                        src={data.products[0]?.customizeImages?.[0]}
                                                                        alt="Product Image"
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className='d-block'>
                                                                <div className='d-flex'>
                                                                    <div className="d-block ms-3">
                                                                        {data.products[0]?.field?.map((field, i) => (
                                                                            <div style={{ marginTop: i !== 0 ? '-10px' : '0' }} key={i}>
                                                                                <div className="d-flex">
                                                                                    <p className="title" style={{ width: '120px', fontWeight: '400' }}>
                                                                                        {field?.fieldType?.name}
                                                                                    </p>
                                                                                    <p className="title1">: <span className="ms-2">{field?.option?.name}</span></p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    <div className="d-block ms-5">
                                                                        <div className="d-flex">
                                                                            <p className="title" style={{ width: '120px', fontWeight: '400' }}>Quantity</p>
                                                                            <p className="title1">: <span className="ms-2">{data.products[0]?.quantity}</span></p>
                                                                        </div>
                                                                        <div style={{ marginTop: '-10px' }}>
                                                                            <div className="d-flex">
                                                                                <p className="title" style={{ width: '120px', fontWeight: '400' }}>Total Amount</p>
                                                                                <p className="title1">: <span className="ms-2">{data.products[0]?.total}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='ms-3'>
                                                                    {data?.products[0]?.sizeWithQuantity?.length > 0 && (
                                                                        <>
                                                                            {data?.products[0]?.sizeWithQuantity[0]?.quantity ? (
                                                                                <div className='mt-2'>
                                                                                    <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                        <div className='d-flex' onClick={() => toggleAccordion1(data?.products[0]?._id)}>
                                                                                            <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Size with Quantity</h6>
                                                                                            <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Collapse isOpen={openAccordion1 === data?.products[0]?._id}>
                                                                                        <div className='accordion-body'>
                                                                                            <div className='mt-2 mb-2'>
                                                                                                <table className="table">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>S. No</th>
                                                                                                            <th>Size</th>
                                                                                                            <th>Quantity</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {data?.products[0]?.sizeWithQuantity?.map((item, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td>{index + 1}</td>
                                                                                                                <td>{item.size || 'N/A'}</td>
                                                                                                                <td>{item.quantity || 'N/A'}</td>
                                                                                                            </tr>
                                                                                                        ))}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                            {/* <div>
                                                                                                {data?.products[0]?.sizeWithQuantity?.map((eachDetail, k) => (
                                                                                                    <div className='d-flex justify-content-between' key={k}>
                                                                                                        <p className='text-secondary'><ChevronRight /> {eachDetail?.size}</p>
                                                                                                        <p className='pe-5 text-dark' style={{ fontWeight: '500' }}>- {eachDetail?.quantity}</p>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div> */}
                                                                                        </div>
                                                                                    </Collapse>
                                                                                </div>
                                                                            ) : (
                                                                                <div className='mt-2'>
                                                                                    <div className='accordion-header' style={{ cursor: 'pointer' }} >
                                                                                        <div className='d-flex' onClick={() => toggleAccordion1(data?.products[0]?._id)}>
                                                                                            <h6 style={{ color: '#858585', fontWeight: '400' }} className=' p-0'>Names & Numbers</h6>
                                                                                            <span className='ms-2 p-0'><ChevronDown /></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <Collapse isOpen={openAccordion1 === data?.products[0]?._id}>
                                                                                        <div className='accordion-body'>
                                                                                            <div className='mt-2 mb-2'>
                                                                                                <table className="table">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th>S. No</th>
                                                                                                            <th>Name</th>
                                                                                                            <th>Number</th>
                                                                                                            <th>Size</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {data?.products[0]?.sizeWithQuantity?.map((item, index) => (
                                                                                                            <tr key={index}>
                                                                                                                <td>{index + 1}</td>
                                                                                                                <td>{item.name || 'N/A'}</td>
                                                                                                                <td>{item.number || 'N/A'}</td>
                                                                                                                <td>{item.size || 'N/A'}</td>
                                                                                                            </tr>
                                                                                                        ))}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Collapse>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-block ms-5'>
                                                        <div>
                                                            <Button style={{ color: 'white', background: '#E4510B', border: 'none' }} onClick={handleDownload}>
                                                                Download <MdOutlineFileDownload size={25} style={{ color: 'white' }} />
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            <Button
                                                                className='mt-2'
                                                                style={{ color: '#E4510B', background: 'white', border: '1px solid #E4510B', fontWeight: '400' }}
                                                                onClick={() => {
                                                                    const fileUrl = (data?.products[0]?.file?.[0]) || (data?.products[0]?.customizeImages?.[0]);

                                                                    // Check if fileUrl exists and is a string
                                                                    if (fileUrl && typeof fileUrl === 'string') {
                                                                        if (fileUrl.endsWith('.pdf')) {
                                                                            // Open PDF in a new tab
                                                                            window.open(fileUrl, '_blank', 'noopener,noreferrer');
                                                                        } else {
                                                                            // Handle viewing an image or other file types
                                                                            viewCustom(data?.products[0]);
                                                                        }
                                                                    } else {
                                                                        // Handle case where fileUrl is undefined or not a valid string
                                                                        alert('No valid file available to view.');
                                                                    }
                                                                }}
                                                            >
                                                                View <MdOutlineRemoveRedEye style={{ color: '#E4510B' }} />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <p>No product data available.</p>
                                        )}
                                    </div>
                                </Col>
                                <Col lg={4} >
                                    <div style={{ border: '1px solid #F0F0F0', borderRadius: '15px', padding: '20px' }}>
                                        <Col lg={12}>
                                            <p style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Payment info</p>
                                        </Col>
                                        <div className='mt-4' style={{ marginLeft: '10px' }}>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Payment Method<span ></span></p>
                                                        <p className='title1'>: <span className='ms-2'>{data?.payment?.mode}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            {data?.payment?.mode !== "cash" &&
                                                <>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Transaction Id<span ></span></p>
                                                                <p className='title1'>: <span className='ms-2'>{data?.payment?.paymentId?.bank_transaction_id}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                    <div style={{ marginTop: '-15px' }}>
                                                        <Label>
                                                            <div className='d-flex'>
                                                                <p className='title' style={{ width: '130px', fontWeight: '400' }}>Paid On<span ></span></p>
                                                                <p className='title1'>: <span className='ms-2'>{new Date(data?.payment?.paymentId?.paidOn).toLocaleDateString()}</span></p>
                                                            </div>
                                                        </Label>
                                                    </div>
                                                </>
                                            }
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Product Amount<span ></span></p>
                                                        <p className='title1'>:  <span className='ms-2'>{data?.totalGrossAmount ? data?.totalGrossAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Discount<span ></span></p>
                                                        <p style={{ color: '#308505' }} className='title1'>:<span className='ms-2'>- {data?.totalDiscount ? data?.totalDiscount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className="d-flex">
                                                        <p className="title" style={{ width: '130px', fontWeight: '400' }}>
                                                            Delivery Charge<span></span>
                                                        </p>
                                                        <p className="title1">
                                                            : <span className="ms-2" style={{ color: data?.shippingCharge === 0 ? '#308505' : 'inherit' }}>
                                                                {data?.shippingCharge === 0
                                                                    ? 'Free'
                                                                    : data?.shippingCharge?.toLocaleString("en-IN", {
                                                                        style: "currency",
                                                                        currency: "INR",
                                                                    }) || '0.00'}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </Label>
                                            </div>
                                            <div style={{ marginTop: '-15px' }}>
                                                <Label>
                                                    <div className='d-flex'>
                                                        <p className='title' style={{ width: '130px', fontWeight: '400' }}>Total Amount<span ></span></p>
                                                        <p className='title1'>:  <span className='ms-2'>{data?.totalAmount ? data?.totalAmount.toLocaleString("en-IN", {
                                                            style: "currency",
                                                            currency: "INR",
                                                        }) : '0.00'}</span></p>
                                                    </div>
                                                </Label>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12}>
                                    <div className='mt-3 d-flex gap-2 flex-column flex-lg-row justify-content-between align-items-center'>
                                        <p className='p-0 m-0' style={{ color: '#333333', fontSize: '20px', fontWeight: '700', fontFamily: 'Lato' }}>Notes <span className='fw-normal fs-6'>(Add Notes for your customer about the product/process)</span></p>
                                        <Button onClick={handleNoteModal} style={{ color: 'white', background: '#E4510B', border: 'none' }}>
                                            {/* Print <IoPrintOutline size={25} style={{ color: 'white' }} /> */}
                                            Add Notes <Plus size={20} style={{ color: 'white' }} />
                                        </Button>
                                    </div>
                                    <div className='mt-5'>
                                        <Row>
                                            {Array.isArray(noteData) && noteData.map((item, i) => (
                                                <Col key={i} sm={12} md={6} lg={4}>
                                                    <div style={{ border: '1px solid gray' }} className='mb-3 p-3 text-secondary rounded-1'>
                                                        <p className='text-secondary'><span className='text-danger'><AlertCircle size={18} /></span>  {item?.notes}</p>
                                                        <div className='d-flex justify-content-end'>
                                                            {timeAgo(item?.createdAt)}
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >
                    <Preview modal={modal} toggle={toggle} cardImages={customizeImages} />
                    <Modal isOpen={openNoteModal} toggle={handleNoteModal} className='sidebar-lg' modalClassName='modal-slide-in sidebar-todo-modal' contentClassName='p-0' size='md' >
                        <ModalHeader toggle={handleNoteModal}>
                            <div className='d-flex align-items-center justify-content-between mb-1 px-1' style={{ backgroundColor: '#ffff' }}>
                                <p className='modal-title' style={{ fontFamily: 'Lato', fontSize: '20px', fontWeight: '400' }}>
                                    <b>Add Note</b>
                                </p>
                            </div>
                        </ModalHeader>
                        <ModalBody>
                            <div>
                                <textarea onChange={(e) => setNoteText(e.target.value)} style={{ width: '100%', height: '100px', padding: '10px', borderRadius: '4px', border: '1px solid gray' }}>

                                </textarea>
                            </div>
                            <div className='d-bloick mt-2'>
                                <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the Note to 'Customer'?</p>
                            </div>
                            <div className='d-flex gap-2 justify-content-end'>
                                <Button onClick={handleNoteModal} style={{ borderColor: '#E4510B', color: '#E4510B', backgroundColor: 'white' }}>Cancel</Button>
                                <Button onClick={orderNotesApi} disabled={!noteText} style={{ backgroundColor: '#E4510B', border: 'none' }}>Submit</Button>
                            </div>
                        </ModalBody>
                    </Modal>
                </div >
            </div >
            <FooterSection />
        </>
    )
}

export default DashBoardViewPage
