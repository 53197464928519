import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import domtoimage from 'dom-to-image';
import logo from '../../../assets/images/logo/printon logo-01.png'
import { RxText } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { LuShirt } from "react-icons/lu";
import { BiImageAdd } from "react-icons/bi";
import { PiUploadLight } from "react-icons/pi";
import { IoShapesOutline } from "react-icons/io5";
import { FaRegTrashCan } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { BsQrCode } from "react-icons/bs";
import { IoIosInformationCircle } from "react-icons/io";
import { RiImageAddFill } from "react-icons/ri";
import { AiOutlineSync } from "react-icons/ai";
import line from "../../../assets/images/logo/custom line.png"
import './index.css';
import { ArrowLeft, ChevronDown, ShoppingCart } from 'react-feather';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { product, addCart, fileUpload, quantityCountApi, getCartRate, customizeDesign, customizeImage } from '../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast';
import { Card, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { QRCodeCanvas } from 'qrcode.react';
import { IoMdAdd } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import FinalAuthModal from '../../navbar/finalAuth';

function MobileCustomization() {
    const [activeTab, setActiveTab] = useState('');
    const [touchStartY, setTouchStartY] = useState(0);
    const [drawerPosition, setDrawerPosition] = useState(0);

    const { id } = useParams();
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modal1, setModal1] = useState(false);

    const toggle1 = () => {
        setModal1(!modal1); // Toggle the modal's visibility
    };
    const [color, setColor] = useState("");
    const [customPicture, setCustomPicture] = useState(null);
    const [imageOrder, setImageOrder] = useState([]);
    const [editedImage, setEditedImage] = useState([]);
    const [imagesUpdated, setImagesUpdated] = useState(false);
    const canvasRef = useRef(null);
    const fabricCanvasRef = useRef(null);
    const [fabricCanvas, setFabricCanvas] = useState(null);
    const [showDelBtn, setShowDeBtn] = useState(false)
    const [selectedImageUrl, setSelectedImageUrl] = useState("");
    const navigate = useNavigate();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const [loader, setLoader] = useState(false);
    const [isProduct, setIsProduct] = useState(true);
    const [isDesign, setIsDesign] = useState(false);
    const [isText, setIsText] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [isUpload, setIsUpload] = useState(false);
    const [isQr, setIsQr] = useState(false);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [quantityRate, setQuantityRate] = useState();
    const [quantity, setQuantity] = useState(1);
    const [maximumQuantity, setMaximumQuantity] = useState()
    const [minimumQuantity, setMinimumQuantity] = useState()
    const [quantityCount, setQauntityData] = useState();
    const [CardImgName1, setCardImgName] = useState([]); // Stores image names for the selected color
    const [selectedColorIndex, setSelectedColorIndex] = useState(null); // Stores the selected color index
    const [quantityError, setQuantityError] = useState('');
    const [textOptions, setTextOptions] = useState({
        text: '',
        fontSize: '20',
        fontFamily: 'Arial',
        textColor: '#000000'
    });

    const CardImg = [
        ...((selectedProduct?.customizeImages?.map(item =>
            item?.images.map(imageItem => imageItem?.image)
        ) ?? []))
    ];

    const productSize = [
        ...(selectedProduct?.customizeImageSize ?? [])
    ]
    const [mainImage, setMainImage] = useState("");

    const [canvasData, setCanvasData] = useState(Array(CardImg.length).fill(null));

    const [payloadData, setPayloadData] = useState({});

    const isLogin = localStorage.getItem('role')
    const isToken = localStorage.getItem('token')

    const quantity1 = localStorage.getItem('quantity') || quantity;
    const option = JSON.parse(localStorage.getItem('selectedOptions'));

    const amount = quantityRate?.amount
    const totalAmount = amount * quantity1;
    const actualAmount = selectedProduct?.amount * quantity1;
    const offerAmount = amount * quantity1;

    let canvas;

    const productName = window.location.pathname.split('/').pop();

    const [selectedOptions, setSelectedOptions] = useState({});

    const handleOptionChange = async (fieldType, selectedOption) => {
        setSelectedOptions(prevState => {
            const updatedOptions = {
                ...prevState,
                [fieldType]: selectedOption
            };
            // Convert updatedOptions to an array with fieldType included
            const optionsArray = Object.entries(updatedOptions).map(([fieldType, option]) => ({
                fieldType,
                option
            }));

            // Store as an array in localStorage
            localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
            return updatedOptions;
        });
    };

    const [selectedSize, setSelectedSize] = useState('');
    const size = localStorage.getItem('customizeImageSize');

    const handleSizeClick = (size) => {
        setSelectedSize(size);
    };

    useEffect(() => {
        quantityData(selectedProduct?._id)
    }, [selectedProduct?._id])

    const quantityData = async () => {
        try {
            const response = await axios.get(`${quantityCountApi}/${productName}`);
            setQauntityData(response?.data?.result);

            const min = response?.data?.result?.[0]?.from ?? 1;
            const lastIndex = response?.data?.result?.length - 1;
            const max = response?.data?.result?.[lastIndex]?.to;

            setMinimumQuantity(min);
            setMaximumQuantity(max);
            setQuantity(min);
        } catch (error) {
        }
    };
    const [clipArtSearch, setClipArtSearch] = useState('')
    const [clipArtData, setClipArtData] = useState()
    const [librarySearch, setLibrarySearch] = useState('')
    const [libraryData, setLibraryData] = useState()

    const getClipArtData = async () => {
        try {
            const response = await axios.get(`${customizeDesign}?search=${clipArtSearch}`)
            setClipArtData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }
    const getLibraryData = async () => {
        try {
            const response = await axios.get(`${customizeImage}?search=${librarySearch}`)
            setLibraryData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        }
    }

    useEffect(() => {
        getClipArtData()
        getLibraryData()
    }, [])


    useEffect(() => {

        const handleOnChange = async () => {
            if (!selectedProduct || !selectedProduct._id || !selectedProduct.fieldType) {
                return; // Exit early if dataa or its required properties are undefined
            }

            const payload = {
                product: selectedProduct?._id,
                quantity: quantity,
                field: selectedProduct?.fieldType?.map((eachItem) => (
                    {
                        fieldType: eachItem?.name?._id,
                        option: selectedOptions[eachItem?.name?._id] || null,
                    }
                )),
            };
            try {
                const response = await axios.post(quantityRate, payload);
                setQuantityRate(response?.data?.result)
            } catch (error) {
            }
        }

        handleOnChange();
        const optionsArray = selectedProduct?.fieldType?.map((eachItem) => (
            {
                fieldType: eachItem?.name?._id,
                option: selectedOptions[eachItem?.name?._id] || null,
            }
        ));
    }, [selectedOptions, selectedProduct, quantity])

    useEffect(() => {
        if (size) {
            setSelectedSize(size); // Set the first size as the default
        }
    }, [size]);

    const handleQuantityInput = (event) => {
        const value = event.target.value;

        if (value.length >= 2) {
            handleQuantityChange(event); // Trigger the main function only after two digits
        } else {
            setQuantity(value); // Update the state immediately with the input value
        }
    };

    const handleQuantityChange = async (e) => {
        const newQuantity = parseInt(e.target.value);

        // Handle manual input when quantityType is not 'dropdown' and the new value is invalid
        if (selectedProduct?.quantityType !== 'dropdown' && isNaN(newQuantity)) {
            setQuantity(''); // Clear the input box for manual input
            setQuantityError(''); // Clear error when the field is cleared
            localStorage.removeItem('quantity');
            return;
        }

        // Handle negative or zero quantity
        if (newQuantity <= 0) {
            setQuantity(0);
            localStorage.setItem('quantity', 0);
            return;
        }

        // Check if the new quantity is within the valid range (min and max)
        if (newQuantity >= minimumQuantity && newQuantity <= maximumQuantity) {
            setQuantity(newQuantity);
            localStorage.setItem('quantity', newQuantity);

            // Prepare payload for API call
            const payload = {
                product: selectedProduct?._id,
                quantity: newQuantity,
                field: selectedProduct?.fieldType?.map((eachItem) => ({
                    fieldType: eachItem?.name?._id,
                    option: selectedOptions[eachItem?.name?._id] || null,
                })),
            };

            try {
                // Call API to get cart rate
                const response = await axios.post(getCartRate, payload);
                setQuantityRate(response?.data?.result);
            } catch (error) {
                console.error('Error fetching cart rate:', error);
            }
        } else {
            // Handle errors when the quantity is out of bounds
            if (newQuantity < minimumQuantity) {
                setQuantityError(`Minimum quantity allowed is ${minimumQuantity}`);
            } else if (newQuantity > maximumQuantity) {
                setQuantityError(`Maximum quantity allowed is ${maximumQuantity}`);
            }
        }
    };

    const typeSpellings = {
        'general': 'General',
        'recemended': 'Recommended',
        'bulckprice': 'Bulk Price',
        'bestprice': 'Best Price'
    };

    useEffect(() => {
        // Retrieve and parse options from localStorage
        const storedOptions = localStorage.getItem('selectedOptions');
        let parsedOptions = [];

        try {
            parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
        } catch (error) {
            console.error('Error parsing selectedOptions from localStorage', error);
        }

        // If parsedOptions contains data, set it to selectedOptions state
        if (parsedOptions.length > 0) {
            const optionsObject = parsedOptions.reduce((acc, { fieldType, option }) => {
                acc[fieldType] = option;
                return acc;
            }, {});
            setSelectedOptions(optionsObject);
        } else {
            // Otherwise, set the first option from each fieldType, if available
            if (selectedProduct?.fieldType) {
                const initialOptions = selectedProduct?.fieldType?.reduce((acc, eachItem) => {
                    const fieldType = eachItem?.name?._id;
                    const firstOption = eachItem?.options[0]?._id;
                    if (fieldType && firstOption) {
                        acc[fieldType] = firstOption;
                    }
                    return acc;
                }, {});

                setSelectedOptions(initialOptions || {});

                // Store initial options in localStorage
                const optionsArray = Object.entries(initialOptions || {}).map(([fieldType, option]) => ({
                    fieldType,
                    option,
                }));
                localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
                localStorage.setItem('quantity', JSON.stringify(selectedProduct?.quantityBasedAmount[0]?.to));
                if (selectedProduct?.customizeImageSize?.length > 0) {
                    localStorage.setItem('customizeImageSize', JSON.stringify(selectedProduct?.customizeImageSize[0]));
                }
            }
        }
    }, [selectedProduct]);


    useEffect(() => {
        const additionalData = async () => {
            try {
                const response = await axios.get(`${product}/${id}`);
                const productData = response?.data?.result;
                setSelectedProduct(productData);

                // Set the 0th index image as the default image
                if (productData?.customizeImages && productData?.customizeImages?.length > 0) {
                    setMainImage(productData.customizeImages[0]?.images[0]?.image);
                    setSelectedImageIndex(0); // Set the first item as active
                }
            } catch (error) {
                console.error('Error fetching product data:', error);
            }
        };
        additionalData();
    }, [id]);

    const resetForm = () => {
        setSelectedImageUrl("");
        setColor("");
        setTexts([]);
        setCustomPicture(null);
        setTextOptions({
            text: "",
            fontSize: '20',
            fontFamily: "Arial",
            textColor: "#000000"
        });

        // Clear the fabric.js canvas
        if (fabricCanvas) {
            fabricCanvas.clear();
        }

        // Reset file input value
        const fileInput = document.getElementById("tshirt-custompicture");
        if (fileInput) {
            fileInput.value = "";
        }

        // Reset color input
        const colorInput = document.getElementById("tshirt-color");
        if (colorInput) {
            colorInput.value = "#fff";
        }

        // Reset background color
        const tshirtDiv = document.getElementById("tshirt-div");
        if (tshirtDiv) {
            tshirtDiv.style.backgroundColor = "#fff";
        }
    };

    const reset = () => {
        setSelectedImageUrl("");
        setColor("");
        setCustomPicture(null);
        setTextOptions({
            text: "",
            fontSize: '20',
            fontFamily: "Arial",
            textColor: "#000000"
        })
    };

    useEffect(() => {
        if (fabricCanvas && selectedImageUrl) {
            updateTshirtImage(selectedImageUrl);
        }
    }, [fabricCanvas, selectedImageUrl]);

    const updateTshirtImage = (imageURL) => {
        fabric.Image.fromURL(imageURL, function (img) {
            img.scaleToHeight(300);
            img.scaleToWidth(300);
            //   fabricCanvas.clear();
            fabricCanvas.add(img).renderAll();
        },
            {}
            // { crossOrigin: 'anonymous' }
        );
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = function (event) {
            const imgObj = new Image();
            imgObj.src = event.target.result;

            imgObj.onload = function () {
                if (!fabricCanvasRef.current) return;

                const img = new fabric.Image(imgObj);

                img.scaleToHeight(300);
                img.scaleToWidth(300);

                fabricCanvasRef.current.add(img);
            };
        };

        if (file) {
            reader.readAsDataURL(file);
        }

        const fileInput = document.getElementById("tshirt-custompicture");
        if (fileInput) {
            fileInput.value = "";
        }
    };


    const handleDesignChange = (e) => {
        setSelectedImageUrl(e.target.value);
    };

    const handleColorChange = (e) => {
        setColor(e.target.value);
        document.getElementById("tshirt-div").style.backgroundColor = e.target.value;
    };

    const downloadImage = () => {

        // Clear selection
        if (fabricCanvas) {
            fabricCanvas.discardActiveObject();
            fabricCanvas.renderAll();
        }

        // Set canvas border to transparent and width to zero
        const canvasElement = document.getElementById('drawingArea');
        if (canvasElement) {
            canvasElement.style.border = 'none';
        }


        domtoimage.toBlob(document.getElementById('tshirt-div'))
            .then(function (blob) {
                var link = document.createElement('a');
                link.download = 'custom-tshirt.png';
                link.href = window.URL.createObjectURL(blob);
                link.click();
                window.URL.revokeObjectURL(link.href);
                canvasElement.style.border = '1px solid #ccc';
            });
    };

    const deleteImage = () => {
        if (fabricCanvas && fabricCanvas.getActiveObject()) {
            fabricCanvas.remove(fabricCanvas.getActiveObject());
            fabricCanvas.discardActiveObject();
            fabricCanvas.renderAll();
        }
    }

    const updateTextOnCanvas = (textId, updates) => {
        const selectedObject = fabricCanvasRef.current.getObjects().find((obj) => obj === textId);
        if (selectedObject) {
            selectedObject.set(updates); // Update text object properties
            fabricCanvasRef.current.renderAll(); // Re-render the canvas to reflect changes
        }

        // Also update the text in the state
        setTexts((prevTexts) =>
            prevTexts.map((item) =>
                item.id === textId
                    ? { ...item, ...updates }
                    : item
            )
        );
    };

    const handleTextChange = (e) => {
        const newText = e.target.value;
        setTextOptions((prev) => ({ ...prev, text: newText }));

        // Update the active object on the canvas
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject.type === 'text') {
            activeObject.set('text', newText);
            fabricCanvasRef.current.renderAll();
        }

        // Update the card text if in editing mode
        if (isEditing && selectedTextId) {
            updateTextOnCanvas(selectedTextId, { text: newText });
        }
    };

    const handleFontSizeChange = (e) => {
        const newFontSize = parseInt(e.target.value, 10);
        setTextOptions((prev) => ({ ...prev, fontSize: newFontSize }));

        // Update the active object on the canvas
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject.type === 'text') {
            activeObject.set('fontSize', newFontSize);
            fabricCanvasRef.current.renderAll();
        }

        // Update the card font size if in editing mode
        if (isEditing && selectedTextId) {
            updateTextOnCanvas(selectedTextId, { fontSize: newFontSize });
        }
    };

    const handleFontFamilyChange = (e) => {
        const newFontFamily = e.target.value;
        setTextOptions((prev) => ({ ...prev, fontFamily: newFontFamily }));

        // Update the active object on the canvas
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject.type === 'text') {
            activeObject.set('fontFamily', newFontFamily);
            fabricCanvasRef.current.renderAll();
        }

        // Update the card font family if in editing mode
        if (isEditing && selectedTextId) {
            updateTextOnCanvas(selectedTextId, { fontFamily: newFontFamily });
        }
    };

    const handleTextColorChange = (e) => {
        const newTextColor = e.target.value;
        setTextOptions((prev) => ({ ...prev, textColor: newTextColor }));

        // Update the active object on the canvas
        const activeObject = fabricCanvasRef.current.getActiveObject();
        if (activeObject && activeObject.type === 'text') {
            activeObject.set('fill', newTextColor);
            fabricCanvasRef.current.renderAll();
        }

        // Update the card text color if in editing mode
        if (isEditing && selectedTextId) {
            updateTextOnCanvas(selectedTextId, { textColor: newTextColor });
        }
    };


    const [texts, setTexts] = useState([]); // Store added texts
    const [selectedTextId, setSelectedTextId] = useState(null);
    const [textItems, setTextItems] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    const addText = () => {
        if (!fabricCanvasRef.current) return;

        const newText = new fabric.Text(textOptions.text, {
            left: 50,
            top: 50,
            fontSize: textOptions.fontSize,
            fontFamily: textOptions.fontFamily,
            fill: textOptions.textColor,
        });

        fabricCanvasRef.current.add(newText);

        const textData = {
            id: newText,
            text: textOptions.text,
            fontSize: textOptions.fontSize,
            fontFamily: textOptions.fontFamily,
            textColor: textOptions.textColor,
        };

        setTexts((prevTexts) => [...prevTexts, textData]);
        reset(); // Reset the form
    };



    // Function to select text for editing
    const selectTextForEdit = (textId) => {
        const selectedText = texts.find((item) => item.id === textId);
        if (selectedText && fabricCanvasRef.current) {
            // Update input fields with the selected text values
            setTextOptions({
                text: selectedText.text,
                fontSize: selectedText.fontSize,
                fontFamily: selectedText.fontFamily,
                textColor: selectedText.textColor,
            });

            setSelectedTextId(textId); // Track selected text
            setIsEditing(true);

            // Highlight the selected text on the canvas for editing
            fabricCanvasRef.current.setActiveObject(selectedText.id);
            fabricCanvasRef.current.renderAll(); // Re-render the canvas
        }
    };

    // Function to delete text
    const deleteText = (e, textId) => {
        e.stopPropagation(); // To prevent the card click event

        fabricCanvasRef.current.remove(textId); // Remove from canvas
        setTexts((prevTexts) => prevTexts.filter((item) => item.id !== textId));
        reset();
    };

    const updateText = () => {
        // Reset the input fields and exit edit mode
        reset();
        setIsEditing(false);
        setSelectedTextId(null);

        // Clear selection on the Fabric.js canvas
        if (fabricCanvasRef.current) {
            fabricCanvasRef.current.discardActiveObject(); // Deselect the current object
            fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
        }
    };

    const addToCart = async () => {
        if (!editedImage || editedImage.length === 0) {
            console.error('editedImage is undefined or empty');
            return;
        }

        // Retrieve and parse options from localStorage
        const storedOptions = localStorage.getItem('selectedOptions');
        let parsedOptions = [];

        try {
            parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
        } catch (error) {
            console.error('Error parsing selectedOptions from localStorage', error);
            parsedOptions = [];
        }

        // Ensure parsedOptions is an array
        if (!Array.isArray(parsedOptions)) {
            console.error('parsedOptions is not an array');
            parsedOptions = [];
        }

        // Convert parsedOptions into a format that matches your payload
        const optionsMap = parsedOptions.reduce((acc, { fieldType, option }) => {
            acc[fieldType] = option;
            return acc;
        }, {});

        const customizeImages = editedImage.map(image => image.url);

        const payload = {
            product: selectedProduct?._id,
            quantity: quantity1,
            field: selectedProduct?.fieldType?.map((eachItem) => ({
                fieldType: eachItem?.name?._id,
                option: optionsMap[eachItem?.name?._id] || null,
            })),
            customizeImages: customizeImages,
            size: selectedSize
        };

        if (isLogin === 'USER') {
            try {
                const response = await axios.post(addCart, payload);
                toast.success(response?.data?.msg);
                localStorage.removeItem('AddCart');
                setTimeout(() => {
                    setLoading(false);
                    navigate('/myCart');
                }, 2000);
                navigate('/myCart');
                localStorage.removeItem('selectedOptions');
                localStorage.removeItem('quantity');
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            }
        } else {
            toggle();
            alert('You are not signed in as a User');
        }
    };

    const handleSaveAndProceed = async () => {
        setLoader(true);
        if (fabricCanvasRef.current) {
            const fabricCanvas = fabricCanvasRef.current; // Accessing the fabric.js canvas instance
            fabricCanvas.discardActiveObject(); // Deselect any active objects
            fabricCanvas.renderAll(); // Re-render the canvas to reflect the changes
        }

        if (!isToken) {
            toggle()
            setLoader(false)
            return;
        }
        const updatedOrder = [...imageOrder];

        try {
            const currentImages = CardImg[selectedColorIndex];

            for (let j = 0; j < currentImages.length; j++) {
                const imageUrl = currentImages[j];
                setSelectedImageIndex(j);
                setMainImage(imageUrl);

                await new Promise((resolve, reject) => {
                    const image = new Image();
                    image.crossOrigin = "Anonymous"; // Set crossOrigin before assigning the source

                    image.onload = () => resolve();
                    image.onerror = (error) => {
                        console.error(`Error loading image at index ${j}:`, error);
                        reject(new Error(`Error loading image at index ${j}: ${imageUrl}`));
                    };

                    const cacheBustedUrl = `${imageUrl}?t=${new Date().getTime()}`; // Cache busting
                    image.src = cacheBustedUrl;
                });

                await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay

                const element = document.getElementById('tshirt-div');
                if (!element) throw new Error(`Element with ID 'tshirt-div' not found.`);

                const blob = await domtoimage.toBlob(element);
                const randomIndex = getRandomNumberNearIndex(j);

                const formData = new FormData();
                formData.append('file', blob, `design${j}_${randomIndex}.png`);
                const response = await axios.post(fileUpload, formData);

                if (response.status === 200 && response.data.result && response.data.result.length > 0) {
                    const url = { url: response.data.result[0].location }; // Use {url: location}
                    updatedOrder[j] = url;
                } else {
                    throw new Error(`Error saving image at index ${j}: No result found in response.`);
                }
            }

            setEditedImage(updatedOrder);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            toggle1();
            // localStorage.('AddCart')
            // Open modal after images are processed
            // setImagesUpdated(true);
        } catch (error) {
            console.error('Error uploading images:', error);
            alert('Error uploading images. Please try again.');
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (imagesUpdated) {
            addToCart(); // Add to cart logic after confirmation
            setImagesUpdated(false); // Reset the flag after adding to cart
        }
    }, [imagesUpdated]);

    useEffect(() => {
        if (localStorage.getItem('AddCart')) {
            handleSaveAndProceed()
        }
    }, [localStorage.getItem('AddCart')])

    const handleImageClickWrapper = (image, index) => {
        setSelectedImageIndex(index);
        setMainImage(image);
    };

    const getRandomNumberNearIndex = (index) => {
        const min = 0;
        const max = 9;
        return index + Math.floor(Math.random() * (max - min + 1) + min);
    };

    const canvasElement = document.getElementById('drawingArea');
    if (canvasElement) {
        canvasElement.style.border = 'none';
    }

    const handleNavigateBack = () => {
        if (imageOrder.length > 0) {
            const confirmMessage = 'If you go back, the saved images will be deleted.';
            const shouldNavigate = window.confirm(confirmMessage);
            if (shouldNavigate) {
                // Perform navigation
                navigate(-1);
            }
        } else {
            const confirmMessage = 'Are you sure you want to navigate back?';
            const shouldNavigate = window.confirm(confirmMessage);
            if (shouldNavigate) {
                // Perform navigation
                navigate(-1);
            }
        }
        // Optionally, handle other logic based on user's choice
    };

    useEffect(() => {
        const canvas = new fabric.Canvas(canvasRef.current);
        setFabricCanvas(canvas);
        fabricCanvasRef.current = canvas;

        // Load canvas data if available for selected image index
        if (canvasData[selectedImageIndex]) {
            canvas.loadFromJSON(canvasData[selectedImageIndex], () => {
                canvas.renderAll();
            });
        } else {
            canvas.clear();
        }

        // Save canvas data when modified
        const handleCanvasChange = () => {
            const jsonData = JSON.stringify(canvas.toJSON());
            setCanvasData((prevData) => {
                const newData = [...prevData];
                newData[selectedImageIndex] = jsonData;
                return newData;
            });
        };

        // Listen for object selection
        canvas.on('selection:created', (e) => {
            const activeObject = e.target;
            if (activeObject && activeObject.type === 'text') {
                setTextOptions({
                    text: activeObject.text,
                    fontSize: activeObject.fontSize,
                    fontFamily: activeObject.fontFamily,
                    textColor: activeObject.fill
                });
            }
            setShowDeBtn(true);
        });


        // Listen for deselection
        canvas.on("selection:cleared", () => {
            setShowDeBtn(false);
        });

        canvas.on('object:modified', handleCanvasChange);
        canvas.on('object:added', handleCanvasChange);
        canvas.on('object:removed', handleCanvasChange);

        // Clean up canvas event listeners and references
        return () => {
            canvas.off('selection:created');
            canvas.off('selection:cleared');
            canvas.off('object:modified', handleCanvasChange);
            canvas.off('object:added', handleCanvasChange);
            canvas.off('object:removed', handleCanvasChange);
            canvas.dispose();
            fabricCanvasRef.current = null;
        };
    }, [selectedImageIndex]);

    const handleParaClick = () => {
        setIsProduct(true);
        setIsDesign(false);
        setIsText(false);
        setIsImage(false);
        setIsUpload(false);
        setIsQr(false);
    };

    const handleDesignClick = () => {
        setIsDesign(true);
        setIsProduct(false);
        setIsText(false);
        setIsImage(false);
        setIsUpload(false);
        setIsQr(false);
    };

    const handleTextClick = () => {
        setIsText(true);
        setIsDesign(false);
        setIsProduct(false);
        setIsImage(false);
        setIsUpload(false);
        setIsQr(false);
    };

    const handleImageClick = () => {
        setIsDesign(false);
        setIsProduct(false);
        setIsText(false);
        setIsImage(true);
        setIsUpload(false);
        setIsQr(false);
    };
    const handleUploadClick = () => {
        setIsDesign(false);
        setIsProduct(false);
        setIsText(false);
        setIsImage(false);
        setIsUpload(true);
        setIsQr(false);
    };

    const handleQrClick = () => {
        setIsDesign(false);
        setIsProduct(false);
        setIsText(false);
        setIsImage(false);
        setIsUpload(false);
        setIsQr(true);
    };

    const [colors, setColors] = useState([
        // Add your desired color hex codes here
        '#800000', '#000000', '#191919', '#800080', '#FF0000', '#A52A2A', '#B22222', '#0000FF', '#808080',
        '#C0C0C0', '#A9A9A9', '#228B22', '#FFA500', '#D2691E', '#008000', '#FFB6C1', '#ADD8E6', '#FFFF00',
        '#F0F8FF', '#FAEBD7', '#F0FFF0', '#FFFFF',
    ]);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);

    const handleColorChange1 = (color) => {
        setSelectedColor(color.hex); // Example for react-color
    };

    const handleAddColor = () => {
        if (selectedColor) {
            setColors([...colors, selectedColor]);
            setSelectedColor(null);
            setIsOpen(false);
        }
    };

    const navToProduct = (category_url) => {
        navigate('/products')
    }

    const applyColorToCanvas = (color) => {
        if (!fabricCanvasRef.current) return;

        // Apply the color to the canvas background
        fabricCanvasRef.current.backgroundColor = color;
        fabricCanvasRef.current.renderAll();
    };

    const [selectedOption, setSelectedOption] = useState('');
    const [inputValue, setInputValue] = useState({});
    const [qrCodeValue, setQrCodeValue] = useState('');

    const handleOptionChange1 = (e) => {
        setSelectedOption(e.target.value);
        setInputValue({});
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        // Update the input value
        setInputValue((prevInputValue) => ({
            ...prevInputValue,
            [name]: value,
        }));

        // Validate the field
        validateField(name, value);
    };


    const handleSubmit = () => {
        let qrValue = '';
        switch (selectedOption) {
            case 'Text':
                qrValue = inputValue.text || '';
                break;
            case 'Dial a Number':
                qrValue = inputValue.phone || '';
                break;
            case 'Open a Link':
                qrValue = inputValue.url || '';
                break;
            case 'Send an Email':
                qrValue = `mailto:${inputValue.email || ''}`;
                break;
            case 'Visiting Card':
                qrValue = `${inputValue.firstName || ''} ${inputValue.lastName || ''}\n${inputValue.position || ''}\n${inputValue.company || ''}\n${inputValue.address || ''}\n${inputValue.phoneNumber || ''}\n${inputValue.email || ''}\n${inputValue.website || ''}`;
                break;
            default:
                qrValue = '';
                break;
        }

        setQrCodeValue(qrValue);
    };

    const handleDownload = () => {
        const canvas = document.getElementById('tshirt-custompicture');
        const pngUrl = canvas
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
        let downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = 'qrcode.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const handleCanvasSet = () => {
        const canvas = document.getElementById('tshirt-custompicture');
        const qrCodeDataUrl = canvas.toDataURL('image/png');

        // Load the QR code image into Fabric.js canvas
        fabric.Image.fromURL(qrCodeDataUrl, function (img) {
            const canvas = canvasRef.current;
            if (canvas) {
                // canvas.add(img);
                fabricCanvasRef.current.add(img);
                // canvas.renderAll();
            }
        });
    };

    useEffect(() => {
        if (selectedProduct?.customizeImages && selectedProduct?.customizeImages.length > 0) {
            handleColorSelect(0); // Select the first color by default
        }
    }, [selectedProduct]);


    const handleColorSelect = (colorIndex) => {
        const selectedColorImages = selectedProduct?.customizeImages[colorIndex]?.images;
        if (selectedColorImages && selectedColorImages.length > 0) {
            setMainImage(selectedColorImages[0].image);
            setCardImgName(selectedColorImages.map(image => image.name));
            setSelectedImageIndex(0);
            setSelectedColorIndex(colorIndex); // Update the selected color index
        }
    };

    const addImageToCanvas = (imgSrc) => {
        fabric.Image.fromURL(imgSrc, (img) => {
            // Get the original dimensions of the image
            const imgWidth = img.width;
            const imgHeight = img.height;

            // Define the maximum dimensions you want the image to have on the canvas
            const maxWidth = 200;
            const maxHeight = 200;

            // Calculate scaling factors to maintain aspect ratio
            const scaleX = maxWidth / imgWidth;
            const scaleY = maxHeight / imgHeight;

            // Set the scale based on the smaller factor to maintain aspect ratio
            const scaleFactor = Math.min(scaleX, scaleY);

            img.set({
                left: 100,
                top: 100,
                scaleX: scaleFactor,
                scaleY: scaleFactor,
            });

            fabricCanvasRef.current.add(img);
        });
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const confirm = () => {
        setLoadings(true)
        addToCart();
        navigate('/myCart');// Close modal
    };

    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        const newErrors = { ...errors };

        switch (name) {
            case 'text':
                if (!value || value.length < 3) {
                    newErrors.text = 'Text must be at least 3 characters long';
                } else {
                    delete newErrors.text;
                }
                break;
            case 'phone':
                const phonePattern = /^[0-9]{10}$/;
                if (!phonePattern.test(value)) {
                    newErrors.phone = 'Phone number must be 10 digits';
                } else {
                    delete newErrors.phone;
                }
                break;
            case 'email':
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(value)) {
                    newErrors.email = 'Enter a valid email address';
                } else {
                    delete newErrors.email;
                }
                break;
            case 'url':
                const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
                if (!urlPattern.test(value)) {
                    newErrors.url = 'Enter a valid URL';
                } else {
                    delete newErrors.url;
                }
                break;
            // Add cases for other fields (visiting card, etc.)
            default:
                break;
        }

        setErrors(newErrors);
    };

    const renderInputField = () => {
        switch (selectedOption) {
            case 'Text':
                return (
                    <>
                        <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Text and click the ‘add’ button.</label>
                        <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="text" value={inputValue.text || ''} onChange={handleInputChange} placeholder="Enter text" />
                    </>
                );
            case 'Dial a Number':
                return (
                    <>
                        <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Phone Number and click the ‘add’ button.</label>
                        <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="tel" name="phone" value={inputValue.phone || ''} onChange={handleInputChange} placeholder="Enter phone number" />
                    </>
                );
            case 'Open a Link':
                return (
                    <>
                        <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid URL and click the ‘add’ button.</label>
                        <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="url" name="url" value={inputValue.url || ''} onChange={handleInputChange} placeholder="Enter URL" />
                    </>
                );
            case 'Send an Email':
                return (
                    <>
                        <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Email and click the ‘add’ button.</label>
                        <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="email" name="email" value={inputValue.email || ''} onChange={handleInputChange} placeholder="Enter email" />
                    </>
                );
            case 'Visiting Card':
                return (
                    <>
                        <div className='d-block'>
                            <div>
                                <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Visiting Details and click the ‘add’ button.</label>
                            </div>
                            <div>
                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter First Name</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="firstName" value={inputValue.firstName || ''} onChange={handleInputChange} placeholder="First Name" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Last Name</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="lastName" value={inputValue.lastName || ''} onChange={handleInputChange} placeholder="Last Name" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Company</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="company" value={inputValue.company || ''} onChange={handleInputChange} placeholder="Company" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Position</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="position" value={inputValue.position || ''} onChange={handleInputChange} placeholder="Position" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Phone Number</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="tel" name="phoneNumber" value={inputValue.phoneNumber || ''} onChange={handleInputChange} placeholder="Phone Number" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Email</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="email" name="email" value={inputValue.email || ''} onChange={handleInputChange} placeholder="Email Address" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Website</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="url" name="website" value={inputValue.website || ''} onChange={handleInputChange} placeholder="Website" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Address</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="address" value={inputValue.address || ''} onChange={handleInputChange} placeholder="Address" />
                                </div>

                                <div className='mt-2'>
                                    <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter QR Label</label>
                                    <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="label" value={inputValue.label || ''} onChange={handleInputChange} placeholder="Label for QR" />
                                </div>
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };


    useEffect(() => {
        // Reset drawer position when activeTab changes
        setDrawerPosition(0);
    }, [activeTab]);

    const handleTouchStart = (e) => {
        setTouchStartY(e.touches[0].clientY);
        setDrawerPosition(0); // Reset position when touch starts
    };

    const handleTouchMove = (e) => {
        const touchY = e.touches[0].clientY;
        const deltaY = touchY - touchStartY;

        if (deltaY > 0) { // Only move downwards
            setDrawerPosition(deltaY); // Move drawer down by deltaY amount

            // Prevent default behavior like page refresh
            e.preventDefault();
        }
    };

    const handleTouchEnd = () => {
        // If drawer is dragged down more than 100px, close it, else reset to original position
        if (drawerPosition > 100) {
            setActiveTab(''); // Close the drawer
        } else {
            setDrawerPosition(0); // Return to the original position
        }
    };
    return (
        <div style={{ backgroundColor: '#F8F7FA', height: '100vh', paddingTop: '5%', paddingBottom: '5%' }}>
            <div style={{ backgroundColor: 'white' }} className='fixed-top d-flex justify-content-between align-items-center pt-3 pb-2 pe-2'>
                <div className='d-flex align-items-center'>
                    <Link to='/'>
                        <img src={logo} height={40} alt="logo" />
                    </Link>
                </div>
                <div className='d-flex align-items-center'>
                    <h3 style={{ fontSize: '18px' }} className='heading-product'>{selectedProduct?.name}</h3>

                    <h3 style={{ fontSize: '16px' }} onClick={resetForm}><AiOutlineSync style={{ height: '18px', width: '18px' }} color='#EA5455' className='ms-2' /></h3>
                </div>
            </div>
            <div style={{ maxHeight: '700px', overflowY: 'auto', marginTop: '20px' }} className='d-flex flex-column justify-content-center align-items-center w-100 p-4'>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #EEEEEE',
                        borderRadius: '10px',
                        padding: '2%',
                        width: '100%', // Ensure it takes full width
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' // Optional shadow for depth
                    }}
                    className='d-flex flex-wrap justify-content-center align-items-center mt-4'
                >
                    {selectedColorIndex !== null && selectedProduct?.customizeImages[selectedColorIndex]?.images.map((imageData, index) => (
                        <p
                            key={index}
                            onClick={() => handleImageClickWrapper(imageData.image, index)}
                            className='d-flex justify-content-center align-items-center p-0 m-0'
                            style={{
                                borderRadius: '4px',
                                width: '100px',
                                height: '40px',
                                background: selectedImageIndex === index ? '#FDF5F2' : 'transparent',
                                cursor: 'pointer',
                                transition: 'background 0.3s', // Smooth background transition
                            }}
                        >
                            <span
                                style={{
                                    fontFamily: 'Lato',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    color: selectedImageIndex === index ? '#E45D25' : '#2B2B2B',
                                }}
                            >
                                {imageData.name}
                            </span>
                        </p>
                    ))}
                </div>

                <div style={{ height: 'auto', marginTop: '15px', background: '#FFFFFF', border: '1px solid #EEEEEE', borderRadius: '10px' }} className='container'>
                    <div className='row mt-2'>
                        <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '100%' }}>
                            <div style={{ position: 'relative', overflow: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '300px', height: '300px', position: 'relative' }} id="tshirt-div">
                                    <img
                                        style={{ width: '300px', height: '300px' }}
                                        id="tshirt-backgroundpicture"
                                        src={mainImage}
                                        alt="T-shirt"
                                    />
                                    <div
                                        id="drawingArea"
                                        style={{ position: 'absolute', top: 0, left: 0, width: '300px', height: '300px' }}
                                        className="drawing-area"
                                    >
                                        <div style={{ width: '300px', height: '300px' }} className="canvas-container">
                                            <canvas
                                                ref={canvasRef}
                                                id="tshirt-canvas"
                                                width='300px'
                                                height='300px'
                                                style={{ display: 'block', position: 'absolute', top: 0, left: 0 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='d-flex ms-5 position-relative' >
                            <div style={{ position: 'relative', overflow: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: `350px`, height: `350px`, position: 'relative' }} id="tshirt-div">
                                    <img
                                        style={{ width: `350px`, height: `350px` }}
                                        id="tshirt-backgroundpicture"
                                        src={mainImage}
                                        alt="T-shirt"
                                    // onLoad={handleImageLoad}
                                    />
                                    <div
                                        id="drawingArea"
                                        style={{ position: 'absolute', top: 0, left: 0, width: `350px`, height: `350px` }}
                                        className="drawing-area"
                                    >
                                        <div style={{ width: '350px', height: '350px' }} className="canvas-container">
                                            <canvas ref={canvasRef} id="tshirt-canvas" width='350px' height='350px' style={{ display: 'block' }} ></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className='col-lg-4 d-flex flex-column align-items-center'>
                            <div className="horizontal-images d-flex justify-content-center align-items-center flex-wrap">
                                {selectedProduct?.customizeImages
                                    ?.filter((_, colorIndex) => colorIndex === selectedColorIndex)
                                    ?.flatMap(color => color.images)
                                    ?.map((imageData, index) => (
                                        <div
                                            key={index}
                                            className='image-container m-2'
                                            style={{
                                                cursor: 'pointer',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <img
                                                src={imageData.image}
                                                alt=""
                                                style={{
                                                    width: '70px',
                                                    height: '70px',
                                                    borderRadius: '20px',
                                                    border: selectedImageIndex === index ? '2px solid #e44324' : 'none',
                                                }}
                                                onClick={() => handleImageClickWrapper(imageData.image, index)}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #EEEEEE',
                        borderRadius: '10px',
                        padding: '1.5%',
                        marginTop: '15px',
                        width: '100%', // Set full width for the container
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Optional shadow for depth
                    }}
                    className='d-flex justify-content-between align-items-center'
                >
                    {/* Left-aligned Back button */}
                    <div>
                        <button
                            style={{
                                color: '#E45D25',
                                border: '1px solid #E45D25',
                                background: 'transparent',
                                borderRadius: '4px',
                                height: '40px',
                                width: '90px',
                                fontWeight: '500',
                                transition: 'background 0.3s', // Smooth transition
                            }}
                            onClick={handleNavigateBack}
                        >
                            <ArrowLeft style={{ color: '#E45D25' }} /> Back
                        </button>
                    </div>

                    {/* Right-aligned content: Price info and Proceed button */}
                    <div className='d-flex align-items-center' style={{ marginLeft: 'auto' }}>
                        {totalAmount ? (
                            <div className='m-0 d-flex justify-content-center align-items-center' style={{ border: '1px solid #EEEEEE', padding: '0 10px', borderRadius: '4px', height: '45px' }}>
                                {selectedProduct?.offer ? (
                                    <>
                                        <div className='d-flex'>
                                            <div className='d-block'>
                                                <div className='d-flex'>
                                                    <span className='text-muted' style={{ textDecoration: 'line-through' }}>{actualAmount?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 0,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</span>
                                                    <span className='fw-bolder ms-2' style={{ color: 'green', fontSize: '18px' }}>{offerAmount?.toLocaleString('en-IN', {
                                                        maximumFractionDigits: 0,
                                                        style: 'currency',
                                                        currency: 'INR'
                                                    })}</span>
                                                </div>
                                                <div>
                                                    <span style={{ color: '#858585', fontSize: "14px", marginTop: '0px' }} className=''>For {quantity1} Pieces</span>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <IoIosInformationCircle className='ms-2' size={20} style={{ color: '#9E9E9E' }} />
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='d-block'>
                                            <span className='fw-bolder' style={{ color: 'green', fontSize: '20px', lineHeight: '21px' }}>{actualAmount?.toLocaleString('en-IN', {
                                                maximumFractionDigits: 0,
                                                style: 'currency',
                                                currency: 'INR'
                                            })}</span>
                                            <span style={{ color: '#858585', fontSize: "14px", marginTop: '0px' }} className=''>For {quantity1} Pieces</span>
                                        </div>
                                        <IoIosInformationCircle className='ms-2' style={{ color: '#9E9E9E' }} />
                                    </>
                                )}
                            </div>
                        ) : (null)}
                        {loader ? (
                            <Spinner />
                        ) : (
                            <div className='ms-3'>
                                <button
                                    style={{
                                        backgroundColor: loader || canvasData.length === 0 ? '#ccc' : '#E45D25',
                                        cursor: loader || canvasData.length === 0 ? 'not-allowed' : 'pointer',
                                        border: 'none', // Remove border for a cleaner look
                                        borderRadius: '4px',
                                        padding: '10px 15px', // Add some padding
                                        color: '#FFFFFF', // Change button text color for contrast
                                        transition: 'background 0.3s', // Smooth background transition
                                    }}
                                    disabled={loader || canvasData.length === 0}
                                    onClick={handleSaveAndProceed}
                                >
                                    Proceed <ShoppingCart size={22} />
                                </button>
                            </div>

                        )}
                    </div>
                </div>
            </div>



            {/* Overlay Background */}
            {activeTab && <div className="overlay" onClick={() => setActiveTab('')}></div>}

            {/* Bottom drawer */}
            {activeTab && (
                <div
                    className={`drawer ${activeTab ? 'open' : ''}`}
                    style={{ transform: `translateY(${drawerPosition}px)` }} // Move drawer based on scroll
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    {/* Drawer Drag Handle */}
                    <div className='drawer-handle'></div>

                    {/* Drawer Content */}
                    <div
                        className='drawer-content'
                        style={{
                            flex: 1, // Take up remaining space below the handle
                            overflowY: 'auto', // Enable vertical scrolling for the content only
                            padding: '0px', // Optional padding to prevent content from touching the edges
                            backgroundColor: '#FFFFFF',
                        }}
                    >
                        {activeTab === 'product' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        paddingBottom: '50px',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >
                                    <div className='d-flex justify-content-between'>
                                        <h3
                                            style={{
                                                font: 'Lato',
                                                fontWeight: '500',
                                                fontSize: '20px',
                                                color: '#2B2B2B',
                                            }}
                                        >
                                            {selectedProduct?.name}
                                        </h3>
                                        <a
                                            className='d-flex justify-content-end'
                                            style={{ color: '#E45D25', cursor: 'pointer' }}
                                            onClick={navToProduct}
                                        >
                                            Change
                                        </a>
                                    </div>
                                    <div>
                                        <img style={{ width: '100%' }} src={line} alt="" />
                                    </div>

                                    {loading ? (
                                        <div
                                            style={{
                                                height: "80vh",
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Spinner color="primary" size="">
                                                Loading...
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <Col lg={12}>
                                            <div className='d-flex mb-1'>
                                                <div style={{ width: '100%' }}>
                                                    <Row>
                                                        {selectedProduct?.fieldType?.map((eachItem, i) => (
                                                            <Col sm={12} lg={6} key={i}>
                                                                <div className='d-lg-block ms-1'>
                                                                    <Col sm={8}>
                                                                        <h4
                                                                            className='m-0 mt-2'
                                                                            style={{
                                                                                fontWeight: '400',
                                                                                fontSize: '16px',
                                                                                font: 'Lato',
                                                                                color: '#262525',
                                                                            }}
                                                                        >
                                                                            {eachItem?.name?.name}
                                                                        </h4>
                                                                    </Col>
                                                                    <Col
                                                                        sm={8}
                                                                        className="position-relative w-100"
                                                                    >
                                                                        <select
                                                                            style={{ backgroundColor: '#EFEFEF', width: '100%' }}
                                                                            name={eachItem?.name?._id}
                                                                            defaultValue={option?.[i]?.option || ""}
                                                                            className='form-control mt-1 pr-6'
                                                                            onChange={(e) =>
                                                                                handleOptionChange(
                                                                                    eachItem?.name?._id,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        >
                                                                            {eachItem?.options?.map((each, k) => (
                                                                                <option key={k} value={each?._id}>
                                                                                    {each?.name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        <i className="dropicon bi bi-caret-down position-absolute translate-middle-y">
                                                                            <ChevronDown />
                                                                        </i>
                                                                    </Col>
                                                                </div>
                                                            </Col>
                                                        ))}

                                                        <Col lg={6}>
                                                            <div className='ms-1'>
                                                                <Col sm={4}>
                                                                    <h4
                                                                        className='m-0 mt-2'
                                                                        style={{
                                                                            fontWeight: '400',
                                                                            fontSize: '16px',
                                                                            font: 'Lato',
                                                                            color: '#262525',
                                                                        }}
                                                                    >
                                                                        Quantity
                                                                    </h4>
                                                                </Col>
                                                                <Col className='w-100' sm={8}>
                                                                    <div>
                                                                        {selectedProduct?.quantityType === 'dropdown' ? (
                                                                            <select
                                                                                name='quantityCounter'
                                                                                className='form-control mt-1 mt-md-1'
                                                                                onChange={handleQuantityChange}
                                                                            >
                                                                                {quantityCount?.map((eachQuantity, i) => (
                                                                                    <option key={i} value={eachQuantity?.to}>
                                                                                        {eachQuantity?.to}
                                                                                        {eachQuantity?.type !== 'general' && (
                                                                                            <span className='ms-4'>
                                                                                                ({typeSpellings[eachQuantity?.type] || eachQuantity?.type})
                                                                                            </span>
                                                                                        )}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        ) : (
                                                                            <div>
                                                                                <input
                                                                                    type="number"
                                                                                    name='quantity'
                                                                                    style={{ border: '1px solid #D9D9D9' }}
                                                                                    className='form-control mt-1 mt-md-1'
                                                                                    value={quantity}
                                                                                    onChange={handleQuantityChange}
                                                                                    pattern="[0-9]+"
                                                                                    min="1"
                                                                                />
                                                                                {quantityError && <span className="text-danger">{quantityError}</span>}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Col>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    )}

                                    <div>
                                        <img style={{ width: '100%' }} src={line} alt="" />
                                    </div>
                                    <div>
                                        <div className="color-picker">
                                            <label
                                                style={{
                                                    font: 'Lato',
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                }}
                                                htmlFor="color"
                                            >
                                                Color
                                            </label>
                                            <div className='d-flex'>
                                                {selectedProduct?.customizeImages?.map((color, index) => (
                                                    <div
                                                        key={index}
                                                        className="color-swatch"
                                                        style={{ backgroundColor: color.colourCode }}
                                                        onClick={() => handleColorSelect(index)}
                                                    ></div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    {selectedProduct?.customizeImageSize?.length > 0 && (
                                        <>
                                            <div>
                                                <img style={{ width: '100%' }} src={line} alt="" />
                                            </div>
                                            <div>
                                                <label
                                                    style={{
                                                        font: 'Lato',
                                                        fontWeight: '400',
                                                        fontSize: '16px',
                                                    }}
                                                    htmlFor="size"
                                                >
                                                    Size
                                                </label>
                                                <div className='d-flex'>
                                                    {productSize?.map((size, index) => (
                                                        <div className='d-flex'>
                                                            <p style={{ cursor: 'pointer' }} className={`ms-3 size d-flex justify-content-center align-items-center ${selectedSize === size ? 'selected-size' : ''}`} onClick={() => handleSizeClick(size)}>
                                                                {size}
                                                            </p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    )}

                                    <div>
                                        {showDelBtn && (
                                            <div>
                                                <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                                <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'clip-art' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        paddingBottom: '50px',
                                        height: '88vh',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto',// Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >
                                    <div>
                                        <div className='position-relative'>
                                            <input style={{ width: '100%', height: '50px', color: '#BFBFBF', fontSize: '16px', fontWeight: '400', font: 'Lato', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '60px' }} type="text" name='search' placeholder='Search all Design' />
                                            <CiSearch className='mt-1' style={{ width: '40px', height: '40px', color: '#BFBFBF', position: 'absolute', left: '5px' }} />
                                        </div>
                                    </div>
                                    {clipArtData?.map((item, i) => (
                                        <>
                                            <div className='mt-2'>
                                                <img style={{ width: '100%' }} src={line} alt="" />
                                            </div>
                                            <div className='d-block'>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>{item?.name}</p>
                                                </div>
                                                <div style={{ marginTop: '-20px' }} className='d-flex row'>
                                                    {item?.images?.map((design) => (
                                                        <div
                                                            key={design?._id}
                                                            className='d-flex align-items-center justify-content-center ms-3 mt-3'
                                                            style={{ width: '70px', height: '70px' }}
                                                        >
                                                            <img
                                                                style={{ width: '60px', height: '60px', borderRadius: '50%', cursor: 'pointer' }}
                                                                src={design?.img_url}
                                                                alt={design?.name}
                                                                onClick={() => addImageToCanvas(design?.img_url)}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                    <div>
                                        {showDelBtn && (
                                            <div>
                                                <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                                <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'text' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        height: '88vh',
                                        paddingBottom: '50px',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >
                                    <div>
                                        <h4>Text Area</h4>
                                    </div>
                                    <div>
                                        <img style={{ width: '100%' }} src={line} alt="" />
                                    </div>
                                    {texts.map((item, index) => (
                                        <Row key={index}>
                                            <div className='d-flex'>
                                                <Col sm={12} lg={12} md={12}>
                                                    <div
                                                        className="text-card justify-content-between mt-2"
                                                        style={{
                                                            border: selectedTextId === item.id ? '2px solid green' : '1px solid #ccc', // Highlight selected card
                                                        }}
                                                    >
                                                        <div>
                                                            <div><strong>Title:</strong> {item.text}</div>
                                                            <div><strong>Font Size:</strong> {item.fontSize}</div>
                                                            <div><strong>Font Family:</strong> {item.fontFamily}</div>
                                                            <div>
                                                                <strong>Color:</strong>
                                                                <div
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        backgroundColor: item.textColor,
                                                                        borderRadius: '50%',
                                                                        display: 'inline-block',
                                                                        marginLeft: '10px',
                                                                        marginBottom: '-5px'
                                                                    }}
                                                                ></div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <button
                                                                className="btn btn-danger ms-3 d-flex justify-content-center align-items-center"
                                                                onClick={(e) => deleteText(e, item.id)}
                                                            >
                                                                <FaRegTrashCan />
                                                            </button>
                                                            <button
                                                                className="btn btn-primary mt-2 ms-3 d-flex justify-content-center align-items-center"
                                                                onClick={() => selectTextForEdit(item.id)}
                                                            >
                                                                <CiEdit />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>
                                    ))}

                                    <div className='mt-1' style={{ borderRadius: '10px' }}>
                                        <div style={{ padding: '5px 10px 5px 10px' }}>
                                            <div className='mb-3 mt-3 d-flex flex-column position-relative'>
                                                <label htmlFor="text" className='select-customize-label'>Title:</label>
                                                <input style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} type="text" id="text" className='customize-select' placeholder='Enter Text Here' value={textOptions.text} onChange={handleTextChange} />
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="fontSize" className='select-customize-label'>Font Size:</label>
                                                <input style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} type="number" id="fontSize" className='customize-select' placeholder='Enter Font Size ' value={textOptions.fontSize} onChange={handleFontSizeChange} />
                                            </div>
                                            <div className='mb-2'>
                                                <label htmlFor="fontFamily" className='select-customize-label mt-1 mb-1'>Font Style:</label>
                                                <select style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} id="fontFamily" className='customize-select mt-1 mb-1' value={textOptions.fontFamily} onChange={handleFontFamilyChange}>
                                                    <option value="Arial">Arial</option>
                                                    <option value="Helvetica">Helvetica</option>
                                                    <option value="Times New Roman">Times New Roman</option>
                                                    <option value="Courier New">Courier New</option>
                                                    <option value="Verdana">Verdana</option>
                                                    <option value="Georgia">Georgia</option>
                                                    <option value="Palatino">Palatino</option>
                                                    <option value="Garamond">Garamond</option>
                                                    <option value="Bookman">Bookman</option>
                                                    <option value="Comic Sans MS">Comic Sans MS</option>
                                                </select>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor="textColor" className='select-customize-label'>Text Color:</label>
                                                <input style={{ padding: '5px', borderRadius: '5px' }} type="color" id="textColor" className='customize-select' value={textOptions.textColor} onChange={handleTextColorChange} />
                                            </div>
                                            <div className="mb-3">
                                                {!isEditing ? (
                                                    <button
                                                        style={{ background: '#E4510B' }}
                                                        className="btn btn-secondary"
                                                        onClick={addText}
                                                    >
                                                        Add Text
                                                    </button>
                                                ) : (
                                                    <button
                                                        style={{ background: '#E4510B' }}
                                                        className="btn btn-secondary"
                                                        onClick={updateText}
                                                    >
                                                        Save Changes
                                                    </button>
                                                )}
                                            </div>
                                            {/* <div className='position-absolute'>
                                  {showDelBtn && (
                                    <div>
                                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                    </div>
                                  )}
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'library' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        paddingBottom: '50px',
                                        height: '88vh',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >

                                    <div>
                                        <div className='position-relative'>
                                            <input style={{ width: '100%', height: '50px', color: '#BFBFBF', fontSize: '16px', fontWeight: '400', font: 'Lato', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '60px' }} type="text" name='search' placeholder='Search images, Vetors & Patterns' />
                                            <CiSearch className='mt-1' style={{ width: '40px', height: '40px', color: '#BFBFBF', position: 'absolute', left: '5px' }} />
                                        </div>
                                    </div>

                                    {libraryData?.map((item, i) => (
                                        <>
                                            <div className='mt-2'>
                                                <img style={{ width: '100%' }} src={line} alt="" />
                                            </div>
                                            <div className='d-block'>
                                                <div className='d-flex justify-content-between'>
                                                    <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>{item?.name}</p>
                                                </div>
                                                <div style={{ marginTop: '-20px' }} className='d-flex flex-wrap gap-4 mt-2'>
                                                    {item?.images?.map((design) => (
                                                        <img
                                                            style={{ height: '80px', cursor: 'pointer' }}
                                                            src={design?.img_url}
                                                            alt={design?.name}
                                                            onClick={() => addImageToCanvas(design?.img_url)}
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                    ))}
                                    <div>
                                        {showDelBtn && (
                                            <div>
                                                <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                                <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'upload' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        height: '88vh',
                                        paddingBottom: '50px',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >
                                    <div>
                                        <h4>Upload your files</h4>
                                    </div>
                                    <div>
                                        <img style={{ width: '100%' }} src={line} alt="" />
                                    </div>
                                    <div>
                                        <div className='mt-3'>
                                            <p style={{ font: 'Lato', fontWeight: '400', color: '#858585', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please Check the guidelines before uploading the files</p> <br />
                                            <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-20px' }}>Supported file formats : PNG, JPG, JEG, JFIF </p>
                                            <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>(Maximum 60 MB /Minimum 224px x 224px)</p>
                                        </div>
                                        <div>
                                            <Card className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', border: '2px dashed #E4510B', borderRadius: '4px' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                    <h1>
                                                        <RiImageAddFill style={{ color: '#8B8B8B', width: '80px', height: '80px' }} />
                                                    </h1>
                                                    <h5 className='mt-1' style={{ color: '#2B2B2D' }}>Select a image to upload on it</h5>
                                                </div>
                                                <div>
                                                    <div className='mb-4 ps-2 mt-2 upload' style={{ width: '200px', height: '30px', background: '#E4510B', borderRadius: '5px' }}>
                                                        <label htmlFor="tshirt-custompicture" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', cursor: 'pointer' }}>
                                                            <span className='ms-2' style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: 'white' }}>Select Image</span>
                                                            <input
                                                                type="file"
                                                                id="tshirt-custompicture"
                                                                style={{ display: 'none' }}
                                                                onChange={handleFileUpload}
                                                            />
                                                        </label>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                        <div className='mt-2'>
                                            {showDelBtn && (
                                                <div>
                                                    <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                                    <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeTab === 'qr-code' && (
                            <div>
                                <div
                                    style={{
                                        padding: '20px',
                                        height: '88vh',
                                        paddingBottom: '50px',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '10px',
                                        border: '1px solid #EEEEEE',
                                        maxHeight: '50vh', // Adjust for mobile screen sizes
                                        overflowY: 'auto', // Enable vertical scrolling
                                    }}
                                    className='d-block'
                                >
                                    <div>
                                        <h4 style={{ color: '#000000', fontSize: '20px', fontWeight: '400', font: 'Lato', lineHeight: '24px' }}>Add QR Code </h4>
                                    </div>
                                    <div className='mt-4'>
                                        <h3 style={{ color: '#666666', fontSize: '18px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}>Choose an action the QR Code will trigger</h3>
                                        <div className='mt-3'>
                                            <select className='ps-2' style={{ width: '100%', borderRadius: '5px', height: '50px', fontSize: '16px', border: '1px solid #B0B0B0', font: 'Lato', lineHeight: '20px', fontWeight: '400', color: '#2F2F2F' }} value={selectedOption} onChange={handleOptionChange1}>
                                                <option value="">Select an option</option>
                                                <option value="Text">Text</option>
                                                <option value="Dial a Number">Dial a Number</option>
                                                <option value="Open a Link">Open a Link</option>
                                                <option value="Send an Email">Send an Email</option>
                                                <option value="Visiting Card">Visiting Card</option>
                                            </select>
                                        </div>
                                        <div className='mt-4'>
                                            {renderInputField()}
                                        </div>
                                        <button className='mt-3' style={{ width: '100%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleSubmit}>Submit</button>

                                        {qrCodeValue && (
                                            <div className='mt-4'>
                                                <div className='d-flex justify-content-center'>
                                                    <QRCodeCanvas style={{ width: '100px', height: '100px' }} id="tshirt-custompicture" value={qrCodeValue} size={100} includeMargin={true} />
                                                </div>
                                                <div className='d-flex'>
                                                    <button className='mt-3' style={{ width: '50%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleDownload}>Download QR Code</button>
                                                    <button className='mt-3 ms-3' style={{ width: '50%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleCanvasSet}><IoMdAdd style={{ color: 'white' }} />Add QR Code</button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <img style={{ width: '100%' }} src={line} alt="" />
                                    </div>
                                    <div>
                                        {showDelBtn && (
                                            <div>
                                                <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                                                <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}


            {/* Bottom Navigation */}
            <div style={{ backgroundColor: 'white' }} className='fixed-bottom overflow-auto p-2 ps-3 pe-3 d-flex justify-content-between align-items-center gap-2'>
                <div onClick={() => setActiveTab('product')} className={`cus-tab ${activeTab === 'product' ? 'cus-tab-active' : ''}`}>
                    <span><LuShirt size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Product</p>
                </div>
                <div onClick={() => setActiveTab('clip-art')} className={`cus-tab ${activeTab === 'clip-art' ? 'cus-tab-active' : ''}`}>
                    <span><IoShapesOutline size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Clip Art</p>
                </div>
                <div onClick={() => setActiveTab('text')} className={`cus-tab ${activeTab === 'text' ? 'cus-tab-active' : ''}`}>
                    <span><RxText size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Text</p>
                </div>
                <div onClick={() => setActiveTab('library')} className={`cus-tab ${activeTab === 'library' ? 'cus-tab-active' : ''}`}>
                    <span><BiImageAdd size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Library</p>
                </div>
                <div onClick={() => setActiveTab('upload')} className={`cus-tab ${activeTab === 'upload' ? 'cus-tab-active' : ''}`}>
                    <span><PiUploadLight size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Upload</p>
                </div>
                <div onClick={() => setActiveTab('qr-code')} className={`cus-tab ${activeTab === 'qr-code' ? 'cus-tab-active' : ''}`}>
                    <span><BsQrCode size={22} /></span>
                    <p className='fw-semibold p-0 m-0 mt-1'>Qr Code</p>
                </div>
            </div>
            <FinalAuthModal isOpen={modal} toggle={toggle} isCart={true} />
            {/* <Preview1 modal={modal1} toggle={toggle1} cardImages={editedImage} /> */}
            <Modal isOpen={modal1} toggle={toggle1}>
                <ModalHeader toggle={toggle1}>Preview</ModalHeader>
                <Form>
                    <ModalBody>
                        {editedImage?.length > 1 ? (
                            <Carousel
                                responsive={responsive}
                                autoPlay={true}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                arrows={true}
                                autoPlaySpeed={2000}
                                infinite={true}
                            >
                                {editedImage.map((image, index) => (
                                    <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img
                                            className='mb-3'
                                            style={{ width: '300px', height: '300px' }}
                                            alt={`Design preview`}
                                            src={image.url} // Use image.url for each image
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <img
                                    className='mb-3'
                                    style={{ width: '300px', height: '300px' }}
                                    alt="Design preview"
                                    src={editedImage[0]?.url} // Use editedImage[0]?.url for single image
                                />
                            </div>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex align-items-center justify-content-center'>
                            <div>
                                <button
                                    style={{
                                        background: 'white', color: '#E4510B', fontSize: '16px',
                                        fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B',
                                        width: '150px', padding: '10px'
                                    }}
                                    onClick={confirm} // Trigger confirm logic
                                >
                                    {loadings ? (
                                        <div className="loader">
                                            <Spinner>Loading...</Spinner>
                                        </div>
                                    ) : (
                                        <span>Confirm</span>
                                    )}

                                </button>
                            </div>
                            <div className='ms-3'>
                                <button
                                    type='button'
                                    style={{
                                        background: '#E4510B', color: 'white', fontSize: '16px',
                                        fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B',
                                        width: '150px', padding: '10px'
                                    }}
                                    onClick={toggle1} // Close modal
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
}

export default MobileCustomization;
