import React, { useEffect, useState } from 'react'
import Navbar, { timeAgo } from '../../navbar'
import FooterSection from '../../footer'
import axios from 'axios';
import { deliveryDetails } from '../../../ApiConfigs/ApiConfig';
import toast from 'react-hot-toast';
import { Col, Input, Row } from 'reactstrap';
import CourierList from './CourierList';

function ShippingIndex() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState()
    const [isNameNumber, setIsNameNumber] = useState(false)
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal)

    const getdata = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${deliveryDetails}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }

    console.log(data);


    useEffect(() => {
        getdata()
    }, [])

    return (
        <div>
            <Navbar />
            <div className='pt-5 s' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Shipping Management</h1>
                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                </div>
                <div className='mt-5'>
                    <Row>
                        <Col sm={12} md={6} lg={4}>
                            <div style={{ border: '1px solid grey', borderStyle: 'dashed', borderRadius: '8px' }} className='w-100 p-3 text-capitalize d-flex justify-content-between gap-3'>
                                <div>
                                    <h1 className='fs-5'>{`Manaul`}</h1>
                                    {data?.deliveryType === 'manual' ? (
                                        <p className='p-0 m-0'><span>Last Update:</span> {timeAgo(data?.updatedAt)}</p>
                                    ) : (
                                        <p className='p-0 m-0'>-</p>
                                    )}
                                </div>
                                <Input
                                    type="switch"
                                    checked={data?.deliveryType === 'manual'}
                                    onClick={() => {
                                        setIsNameNumber(!isNameNumber);
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={4}>
                            <div style={{ border: '1px solid grey', borderStyle: 'dashed', borderRadius: '8px' }} className='w-100 p-3 text-capitalize d-flex justify-content-between gap-3'>
                                <div>
                                    <h1 className='fs-5'>Ship Rocket</h1>
                                    {data?.deliveryType === 'shiprocket' && (
                                        <p className='p-0 m-0'><span>Last Update:</span> {timeAgo(data?.updatedAt)}</p>
                                    )}
                                </div>
                                <Input
                                    type="switch"
                                    checked={data?.deliveryType === 'shiprocket'}
                                    onClick={() => {
                                        toggle()
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
                <CourierList modal={modal} toggle={toggle} />
            </div>
            <FooterSection />
        </div>
    )
}

export default ShippingIndex
