import React from 'react'
import './index.css'
import { useNavigate } from 'react-router-dom';
import { convertTo12HourFormat, formatDate } from '.';

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'ordercompleted':
            return (
                <div className='disc-container-1 disc-con-clr-4 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-4'></div>
                    <p className='p-0 m-0'>Order Placed</p>
                </div>
            );
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>On Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-5 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-5'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};

function MyOrderCard({ data }) {

    const navigate = useNavigate()

    return (
        <div onClick={() => navigate(`/myOrder/view/${data?._id}`)} className='my-order-card p-3 mb-3'>
            <div className='d-md-flex'>
                <div>
                    {data?.customizeImages?.[0] ? (
                        <img
                            alt={`product image`}
                            src={data?.customizeImages?.[0]}
                            className='my-order-img'
                        />
                    ) : (
                        <img
                            alt={`product image`}
                            src={data?.file?.[0].endsWith('.pdf') ? 'https://aimmgr.s3.ap-south-1.amazonaws.com/mgr/public/socialpost/file-blvvhhfusm11y4jw4.png' : data?.file?.[0]}
                            className='my-order-img'
                        />
                    )}
                </div>
                <div className='ms-md-3 heaa'>
                    <h1 className='h5'>{data?.product?.name}</h1>
                    <p style={{ color: '#858585' }} className='p-0 m-0 mb-2'>Quantity : {data?.quantity}</p>
                    <h1 className='h6'>Order placed on : {convertTo12HourFormat(data?.date)}, {formatDate(data?.date)}</h1>
                    <h1 className='h5 text-success p-0 m-0'>₹{parseInt(data?.totalAmount)}</h1>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-between align-items-end state'>
                <div className='mb-2'>
                    {getDeliveryStatus(data?.status)}
                </div>
                {data?.expectedDeliveryDate && (
                    <h1 className='h6 delivery m-0 p-0'>Delivery expected by {formatDate(data?.expectedDeliveryDate)}</h1>
                )}
            </div>
        </div>
    )
}

export default MyOrderCard
