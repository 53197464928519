import React, { useEffect, useState } from 'react'
import { fieldOption, fieldOptionApi, fieldType, fieldTypeApi } from '../../../ApiConfigs/ApiConfig';
import { FaPlus } from "react-icons/fa6";
import DataTable from 'react-data-table-component';
import { Edit2, PlusCircle, Trash } from 'react-feather';
import axios from 'axios'
import './index.css'
import FieldTypeModal from './addmodal';
import { toast } from 'react-hot-toast'
import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import FieldtypeEdit from './editmodal';
import Navbar from '../../navbar';
import FooterSection from '../../footer';
import FieldOptionsModal from './addOption';
import FieldOptionsEdit from './editOption';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { GrPowerReset } from "react-icons/gr";
import { FiChevronRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { BiEditAlt } from "react-icons/bi";
import { GoTrash } from "react-icons/go";


function FieldTypeIndex() {

    // Options Add
    const [addModal, setModal] = useState(false);
    const toggleAdd = () => setModal(!addModal);
    const [fieldOptionId, setFieldOptionId] = useState()
    const navigate = useNavigate()

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const [optionModal, setOptionModal] = useState(false);

    const openOptionAddModal = (id) => {
        setOptionModal(!optionModal);
        setFieldOptionId(id)
    };

    const closeOptionModal = () => {
        setOptionModal(false);
    };

    // Options Edit
    const [editOptionData, setEditOptionData] = useState([])
    // const handleOptionEdit = () => setEditOptionData(!editModal)
    const [editOptionModal, setEditOptionModal] = useState(false)

    const openOptionEditModal = (id) => {
        getOptionEditModal(id)
        setEditOptionModal(true);
    };

    const closeOptionEditModal = () => {
        setEditOptionModal(false);
    };

    const getOptionEditModal = async (id) => {
        try {
            const response = await axios.get(`${fieldOption}/${id}`)
            setEditOptionData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        }

    }


    // Options Delete
    const [optionDelete, setOptionDelete] = useState(false)
    const [deleteOptionData, setDeleteOptionData] = useState();


    const openOptionDeleteModal = (id) => {
        setDeleteOptionData(id);
        setOptionDelete(true);
    }
    const closeOptionDeleteModal = () => {
        setOptionDelete(false);
    }
    const handleConfirmOptionDelete = async () => {
        try {
            const response = await axios.delete(`${fieldOptionApi}/${deleteOptionData}`)
            closeOptionDeleteModal()
            additionalOptionData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)

        }

    }


    // over all get field option
    const [optionLoading, setOptionLoading] = useState(false);


    const additionalOptionData = async () => {
        try {
            setOptionLoading(true);
            const response = await axios.get(fieldOption)
            additionalData()
        } catch (error) {
            toast.error(error?.response?.optiondata?.msg)
        } finally {
            setOptionLoading(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {
            additionalOptionData()
        }

    }, [localStorage.getItem('token')])





    // Over All get field type
    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${fieldType}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {

            additionalData()
        }
    }, [localStorage.getItem('token')])

    useEffect(() => {
        additionalData(searchKey)

    }, [sorts, searchKey, currentPage, rowsPerPage])



    // Field Type Delete 
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${fieldTypeApi}/${deleteData}`)
            closeDeleteModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }


    // Field Type Edit

    const [editModal, setEditModal] = useState(false)
    const [selectedData, setSelectedData] = useState('')

    const openEditModal = (row) => {
        setSelectedData(row)
        setEditModal(true);
    };

    const closeEditModal = () => {
        setEditModal(false);
    };

    const handleReset = () => {
        setSearchKey('')
        setSorts('');
        additionalData();
        setResetKey((prevKey) => prevKey + 1);
        // setResetInputKey((prevKey) => prevKey + 1);
    };

    const categorySortData = [
        {
            name: "latest",
            value: "latest"
        },
        {
            name: "oldest",
            value: "oldest"
        }
    ]


    const additionalTable = [
        {
            name: 'S No',
            minWidth: '2px',
            cell: (row, i) => i + 1,

        },
        {
            name: 'Image',
            sortable: false,
            minWidth: '200px',
            cell: row => <img src={row?.image} height={100} width={150} alt="" style={{ padding: '5px' }} />,
        },
        {
            name: 'Type',
            sortable: false,
            minWidth: '200px',
            cell: row => row?.name,
        },
        {
            name: 'Options',
            sortable: false,
            minWidth: '400px',
            cell: row => (
                <div className='mt-3 mb-3' >
                    {row?.options.map(option => (
                        <div className='d-flex mt-2'>
                            <p className='text-center d-flex' style={{ width: '200px' }} key={option._id}>
                                {option.name}
                            </p>
                            <div className='options-Actions d-flex ms-4'>
                                <div className='Edit cursor-pointer me-2' style={{ cursor: 'pointer' }} onClick={() => openOptionEditModal(option?._id)} ><Edit2 size={20} /></div>
                                <div className='text-danger me-5' style={{ cursor: 'pointer' }} onClick={() => openOptionDeleteModal(option?._id)}> <Trash /> </div>
                            </div>
                        </div>
                    ))}
                    <div className='d-flex justify-content-end mt-1 me-3 mb-2'>
                        <div className='plus' style={{ cursor: 'pointer' }} onClick={() => openOptionAddModal(row?._id)} >
                            <span className='fw-bold'>  <PlusCircle /> Add</span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            name: 'Description',
            sortable: false,
            minWidth: '300px',
            cell: row => row?.description,
        },
        {
            name: "Type Edit",
            minWidth: "100px",
            cell: (row) => (
                <>
                    <div className='Edit me-2' style={{ cursor: 'pointer' }} onClick={() => openEditModal(row)} ><Edit2 size={20} /></div>
                    <div className="Trash" style={{ cursor: 'pointer' }} onClick={() => openDeleteModal(row?._id)}><Trash size={20} color='red' /></div>
                </>
            )

        }
    ]

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const CustomPagination = (pageCount) => {
        return (
            <>

            </>
        );
    };

    const itemsPerPage = 5; // Example: Set the number of items per page
    const totalItems = data?.rows?.length || 0; // Get the total number of items
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate total pages



    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8' }}>
                <div className='pt-1' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Fields</span></p>
                    </div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Fields</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                </div>
                <div className='pt-2' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '15%' }}>

                    <div className='pb-2 d-block' style={{ display: 'flex', marginBottom: '-30px', background: 'white', border: '1px solid #DFDFDF', borderRadius: '12px', height: '150px' }}>
                        {/* <h1>FIELD</h1> */}
                        {/* <div className='total-count'> {data?.pagination?.total}</div> */}
                        <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                            <div className='d-flex align-items-center justify-content-between p-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='red-line me-2'></div>
                                    <h1 className='p-0 m-0 h5'>Fields List</h1>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex p-4'>
                            <div style={{ display: 'flex' }}>
                                <form className="search me-2"
                                >
                                    <input
                                        type="text"
                                        id="search-bar"
                                        key={`status${resetKey}`}
                                        placeholder="Search Name"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        style={{ color: 'black', border: '1px solid #BEBEBE', paddingRight: '50px' }}
                                    />
                                    <a href="#"><img style={{ left: '-300px', color: '#333333', opacity: '0.5' }} className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                                </form>
                            </div>
                            <div className="ms-4" >
                                <Select
                                    className="react-select me-2"
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999, }),
                                        border: '1px solid #BEBEBE'
                                    }}
                                    key={`status${resetKey}`}
                                    type='select'
                                    options={categorySortData?.map((item) => ({
                                        label: item?.name,
                                        value: item?.value
                                    }))}
                                    placeholder='Sort By'
                                    onChange={(selectedOption) => setSorts(selectedOption.value)}
                                />
                            </div>
                            <div style={{ marginRight: "15px", marginTop: '10px', color: '#e4510b' }}>
                                <GrPowerReset size={25} onClick={handleReset} />
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button className='productCategoryBtn' onClick={toggleAdd}><FaPlus /> Add Field</button>
                            </div>
                        </div>

                    </div>
                    {data?.rows?.map((field, index) => (
                        <div
                            key={field?._id} // Ensure to provide a unique key for each element
                            className='mt-5 d-block'
                            style={{
                                display: 'flex',
                                marginBottom: '-30px',
                                background: 'white',
                                border: '1px solid #DFDFDF',
                                borderRadius: '12px',
                                // Remove fixed height to allow the box to grow dynamically
                            }}
                        >
                            <div className='d-flex' style={{ width: '100%' }}>
                                <div
                                    className='p-4 d-flex justify-content-center align-items-start'
                                    style={{ borderRight: '1px solid #DFDFDF', minHeight: '150px' }} // Minimum height to ensure consistent design
                                >
                                    <p style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: '500', color: '#333333' }}>
                                        {(index + 1).toString().padStart(2, '0')}
                                    </p> {/* Adding serial number */}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ borderBottom: '1px solid #DFDFDF' }} className='d-flex justify-content-between p-3'>
                                        <div className='d-flex'>
                                            <p style={{ color: '#333333', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                                Type :
                                            </p>
                                            <p style={{ color: '#333333', fontSize: '18px', fontFamily: 'Lato', fontWeight: '600' }} className='ms-3'>
                                                {field?.name}
                                            </p>
                                        </div>
                                        <div>
                                            <Button style={{ background: '#EEEEEE', border: 'none' }} onClick={() => openEditModal(field)}>
                                                <BiEditAlt color='#4E4E4E' size={25} />
                                            </Button>
                                            <Button className='ms-2' style={{ background: '#D5383826', border: 'none' }} onClick={() => openDeleteModal(field?._id)}>
                                                <GoTrash color='#E42525' size={25} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='d-flex p-3'>
                                        <div className='d-flex p-1'>
                                            <p className='mt-3' style={{ color: '#333333', width: '70px', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                                Option :
                                            </p>
                                        </div>
                                        <div className='d-flex flex-wrap'>
                                            {field?.options?.map((option) => (
                                                <div key={option?._id} className='d-flex ms-2 align-items-center'> {/* Provide a key for each option */}
                                                    <p style={{ color: '#333333', fontSize: '16px', fontFamily: 'Lato', fontWeight: '500', background: '#F6F6F6' }} className='ms-3 p-2 mt-3'>
                                                        {option?.name}
                                                    </p>
                                                    <div>
                                                        <Button className='ms-2' style={{ background: '#D5383826', border: 'none' }} onClick={() => openOptionDeleteModal(option?._id)}>
                                                            <GoTrash color='#E42525' size={25} />
                                                        </Button>
                                                        <Button className='ms-2' style={{ background: '#EEEEEE', border: 'none' }} onClick={() => openOptionEditModal(option?._id)}>
                                                            <BiEditAlt color='#4E4E4E' size={25} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='mt-3' style={{ marginLeft: 'auto' }}>
                                                <button style={{ width: '45px', height: '40px' }} className='productCategoryBtn ms-2' onClick={() => openOptionAddModal(field?._id)}>
                                                    <FaPlus />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                    {/* <ReactPaginate
                        nextLabel="Next"
                        breakLabel="..."
                        previousLabel="Prev"
                        pageCount={totalPages} // Make sure to calculate totalPages correctly
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePagination}
                        containerClassName="pagination justify-content-end p-1"
                        activeClassName="active"
                        pageClassName="page-item"
                        breakClassName="page-item"
                        pageLinkClassName="page-link"
                        breakLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        forcePage={currentPage}
                    /> */}


                    {/* {loading ? (
                        <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner
                                color="primary"
                            >
                                Loading...
                            </Spinner>
                        </div>
                    ) : (
                        <DataTable
                            pagination
                            paginationServer
                            noHeader
                            highlightOnHover
                            fixedHeader
                            fixedHeaderScrollHeight='130vh'
                            data={data?.rows}
                            columns={additionalTable}
                            paginationDefaultPage={currentPage}
                            paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                        />
                    )} */}
                </div >
            </div>


            <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeDeleteModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-3'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}> Please confirm that you want to delete the Field type. This action will remove the type and all associated options. Ensure that this type is no longer needed, as this change cannot be undone. </p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="p-3 d-flex">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeDeleteModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmDelete} >
                        Delete Field Type
                    </Button>
                </div>
            </Modal >

            {/* Option Add ,Edit % Delete */}
            < FieldOptionsModal modal={optionModal} toggle={closeOptionModal} additionalData={additionalData} fieldId={fieldOptionId} />
            <FieldOptionsEdit open={editOptionModal} handleEdit={closeOptionEditModal} editData={editOptionData} additionalData={additionalData} />

            <Modal isOpen={optionDelete} toggle={closeOptionDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeOptionDeleteModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-3'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Please confirm that you want to delete this option. This action will permanently remove the option from the “Material” type, and it cannot be undone. Ensure that this option is no longer required before proceeding</p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="d-flex p-3">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeOptionDeleteModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmOptionDelete} >
                        Delete Field Option
                    </Button>
                </div>
            </Modal>


            {/* Field Type Add & Edit */}
            < FieldTypeModal modal={addModal} toggle={toggleAdd} additionalData={additionalData} />
            <FieldtypeEdit open={editModal} handleEdit={closeEditModal} editData={selectedData} additionalData={additionalData} />


            <FooterSection />
        </>
    )
}

export default FieldTypeIndex
