import React, { useEffect, useState } from 'react'
import * as yup from "yup";
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Col, Form, FormFeedback, Input, Label, Modal, ModalBody, Row, Spinner } from 'reactstrap';
import { PlusCircle, Trash2, Upload, X } from 'react-feather'
import { productFAQ } from '../../../../ApiConfigs/ApiConfig';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

function AddFaq({ modal, toggle, faqDataApi, editData, isEdit }) {

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')

    const { id } = useParams()
    const formSchema = yup.object().shape({
        faq: yup.array().of(
            yup.object().shape({
                title: yup.string().required('Title is required'),
                description: yup.string().required('Description is required'),
            })
        ).min(1, 'At least one FAQ entry is required')
    });
    const {
        reset,
        control,
        setValue,
        handleSubmit,
        formState: { errors }
    } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const { fields: fieldTypesFields, append: appendFieldType, remove: removeFieldType } = useFieldArray({
        control,
        name: "faq",
    });

    const editSubmitApi = async () => {
        const payload = {
            title: title,
            description: description
        }
        try {
            const response = await axios.put(`${productFAQ}/${id}?faq_id=${editData?._id}`, payload)
            toggle()
            faqDataApi()
            toast.success(response?.data?.msg)
            setDescription('')
            setTitle('')
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }
    }

    const submitForm = async (data) => {
        try {
            const response = await axios.post(`${productFAQ}/${id}`, data)
            toggle()
            faqDataApi()
            toast.success(response?.data?.msg)
            reset({})
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg)
        }
    }

    useEffect(() => {
        if (editData) {
            setTitle(editData?.title)
            setDescription(editData?.description)
        }
    }, [editData])

    useEffect(() => {
        appendFieldType({})
    }, [])

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: '#e4510b', borderTopLeftRadius: '13px', borderTopRightRadius: '13px' }}>
                <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: 'white' }}>
                    {isEdit ? 'Edit FAQ' : 'Add FAQ'}
                </h4>
                <X style={{ color: 'white', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={toggle} />
            </div>
            <ModalBody className='flex-grow-1 s'>
                {isEdit ? (
                    <>
                        <div className='mb-1'>
                            <Label className='modal-label'>FAQ Title <span className='text-danger'>*</span> </Label>
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                type="text"
                                placeholder="Enter FAQ Title"
                            />
                        </div>
                        <div className='mb-1'>
                            <Label className='modal-label'>FAQ Description  <span style={{ color: 'red' }}>*</span></Label>
                            <Input value={description}
                                onChange={(e) => setDescription(e.target.value)} type="text" placeholder="Enter FAQ Description" />
                        </div>
                        <hr></hr>
                        <button className='mt-1 add-modal-btn' onClick={editSubmitApi}>Submit</button>
                    </>
                ) : (
                    <Form
                        onSubmit={handleSubmit(submitForm)}
                    >
                        {fieldTypesFields.map((field, index) => (
                            <div key={field.id}>
                                <Row>
                                    <Col sm={12} >
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>{index + 1} Question</Label>
                                            <Controller
                                                name={`faq[${index}].title`}
                                                id='options'
                                                control={control}
                                                render={({ field }) => (
                                                    <Input style={{ height: '44px' }} type="text" {...field} placeholder="Enter your question" />
                                                )} />
                                            {errors.faq && errors.faq[index]?.title && (
                                                <FormFeedback>{errors.faq[index]?.title.message}</FormFeedback>
                                            )}
                                        </div>
                                    </Col>
                                    <Col sm={12}>
                                        <div className='mb-3'>
                                            <Label style={{ fontWeight: "500", color: '#262525' }}>Answer</Label>
                                            <Controller
                                                name={`faq[${index}].description`}
                                                id='options'
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field }) => (
                                                    <Input style={{ height: '44px' }} type="text" {...field} placeholder="Enter your answer" />
                                                )}
                                            />
                                            {errors.faq && errors.faq[index]?.description && (
                                                <FormFeedback>{errors.faq[index]?.description.message}</FormFeedback>
                                            )}
                                        </div>
                                        <hr />
                                    </Col>
                                    <Col sm={12}>
                                        <div className='d-flex justify-content-end' style={{ cursor: 'pointer', color: "#AF1B1B" }} onClick={(e) => {
                                            removeFieldType(index)
                                        }}>
                                            <Trash2 />
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        ))}
                        <div className='d-flex mb-3'>
                            <Button type="button" className='overview-btn' onClick={() => appendFieldType({})}>
                                <PlusCircle /> Add Field
                            </Button>
                        </div>
                        <button className='mt-1 add-modal-btn' type='submit'>Submit</button>
                    </Form >
                )}
            </ModalBody>
        </Modal>
    )
}

export default AddFaq
