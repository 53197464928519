import React, { useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import domtoimage from 'dom-to-image';
import logo from '../../../assets/images/logo/printon logo-01.png'
import { RxText } from "react-icons/rx";
import { LuRedo, LuShirt, LuTextCursorInput, LuUndo } from "react-icons/lu";
import { RiImageAddLine, RiUploadCloud2Line } from "react-icons/ri";
import { BiImageAdd } from "react-icons/bi";
import { PiUploadLight } from "react-icons/pi";
import { IoShapesOutline } from "react-icons/io5";
import { FaRegCommentDots, FaRegTrashCan, FaTrash } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { BsQrCode } from "react-icons/bs";
import { IoIosInformationCircle, IoMdClose } from "react-icons/io";
import { RiImageAddFill } from "react-icons/ri";
import { AiOutlineSync } from "react-icons/ai";
import line from "../../../assets/images/logo/custom line.png"
import ruller from "../../../assets/images/banner/ruller.png"
import backruller from "../../../assets/images/banner/ruller backside.png"
import namenumber from "../../../assets/images/banner/nameNumber.png"
import './index.css';
import { ArrowLeft, ChevronDown, ShoppingCart, X } from 'react-feather';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { product, addCart, fileUpload, quantityCountApi, getCartRate, customizeDesign, customizeImage } from '../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast';
import { Card, Col, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { QRCodeCanvas } from 'qrcode.react';
import { IoMdAdd } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import FinalAuthModal from '../../navbar/finalAuth';
import NameNumberModal from './NameNumberModal';
import { FaUndo } from 'react-icons/fa';
import BgRemover from './BgRemover';

function ProductCustomize() {
  const { id } = useParams();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modal1, setModal1] = useState(false);
  const [modalName, setModalName] = useState(false);

  const toggleName = () => {
    setModalName(!modalName)
  }

  const toggle1 = () => {
    setModal1(prevModal1 => !prevModal1); // Toggle modal visibility
  };
  const [color, setColor] = useState("");
  const [customPicture, setCustomPicture] = useState(null);
  const [imageOrder, setImageOrder] = useState([]);
  const [nameValue, setNameValue] = useState('');
  const [libraryImages, setLibraryImages] = useState([]);
  const [clipArtImages, setClipArtImages] = useState([]);
  const [editedImage, setEditedImage] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [orderType, setOrderType] = useState(quantity === 1 ? 'Single Order' : 'Bulk Order');
  const [imagesUpdated, setImagesUpdated] = useState(false);
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [showDelBtn, setShowDeBtn] = useState(false)
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedImageIndex1, setSelectedImageIndex1] = useState(1);
  const [currentSide, setCurrentSide] = useState(0);
  const [loader, setLoader] = useState(false);
  const [isProduct, setIsProduct] = useState(true);
  const [isDesign, setIsDesign] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isNameNum, setIsNameNum] = useState(false);
  const [modalMode, setModalMode] = useState(null);
  const [isQr, setIsQr] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingImageIndex, setLoadingImageIndex] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [continueAdd, setContinueAdd] = useState(true);
  const [addNameSize, setAddNameSize] = useState(false);
  const [quantityRate, setQuantityRate] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState()
  const [minimumQuantity, setMinimumQuantity] = useState()
  const [quantityCount, setQauntityData] = useState();
  const [CardImgName1, setCardImgName] = useState([]); // Stores image names for the selected color
  const [selectedColorIndex, setSelectedColorIndex] = useState(null);
  const [quantityError, setQuantityError] = useState('');
  const [nameNumber, setNameNumber] = useState([]);
  const [textOptions, setTextOptions] = useState({
    text: '',
    fontSize: '20',
    fontFamily: 'Arial',
    textColor: '#000000'
  });
  const [addName, setAddName] = useState(false);
  const [addNumber, setAddNumber] = useState(false);
  const [Color1, setColor1] = useState('#000000');
  const [scaleValue, setScaleValue] = useState(100);
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  console.log(undoStack, "undo------------")
  console.log(redoStack, "redo------------")

  const handleUndo = () => {
    const currentUndoStack = undoStack[selectedImageIndex];

    if (!currentUndoStack || currentUndoStack.length === 0) return;

    const prevState = currentUndoStack.pop();

    // Add current state to redo stack before undoing
    addStateToRedoStack(JSON.stringify(fabricCanvas.toJSON()));

    // Load the previous state
    fabricCanvas.loadFromJSON(prevState, () => {
      fabricCanvas.renderAll();
    });

    // Update undo stack for the current index
    setUndoStack((prevUndoStack) => ({
      ...prevUndoStack,
      [selectedImageIndex]: [...currentUndoStack],
    }));
  };

  const handleRedo = () => {
    const currentRedoStack = redoStack[selectedImageIndex];

    if (!currentRedoStack || currentRedoStack.length === 0) return;

    const redoState = currentRedoStack.pop();

    // Add current state to undo stack before redoing
    addStateToUndoStack();

    // Load the redo state
    fabricCanvas.loadFromJSON(redoState, () => {
      fabricCanvas.renderAll();
    });

    // Update redo stack for the current index
    setRedoStack((prevRedoStack) => ({
      ...prevRedoStack,
      [selectedImageIndex]: [...currentRedoStack],
    }));
  };

  const addStateToRedoStack = (state) => {
    setRedoStack((prevRedoStack) => {
      const newRedoStack = { ...prevRedoStack };

      // Ensure the selected index has an array
      if (!newRedoStack[selectedImageIndex]) {
        newRedoStack[selectedImageIndex] = [];
      }

      // Push the state into the array for the current image index
      newRedoStack[selectedImageIndex] = [...newRedoStack[selectedImageIndex], state];
      return newRedoStack;
    });
  };

  const addStateToUndoStack = () => {
    if (!fabricCanvas) return;  // Prevents null access if canvas is not initialized
    const canvasJSON = JSON.stringify(fabricCanvas.toJSON());

    setUndoStack((prevUndoStack) => {
      const newUndoStack = { ...prevUndoStack };

      if (!newUndoStack[selectedImageIndex]) {
        newUndoStack[selectedImageIndex] = [];
      }

      newUndoStack[selectedImageIndex] = [...newUndoStack[selectedImageIndex], canvasJSON];
      return newUndoStack;
    });
  };

  // useEffect(() => {
  //   // Initialize the fabric canvas instance if it hasn't been initialized
  //   if (canvasRef.current && !fabricCanvasRef.current) {
  //     fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
  //       height: 500,
  //       width: 420,
  //     });
  //   }

  //   // Cleanup the canvas on unmount or component update
  //   return () => {
  //     if (fabricCanvasRef.current) {
  //       fabricCanvasRef.current.clear(); // Remove all objects
  //       fabricCanvasRef.current.off(); // Remove all event listeners
  //       // fabricCanvasRef.current.dispose();
  //       fabricCanvasRef.current = null; // Clear the reference after disposal
  //     }
  //   };
  // }, []);

  // Handle object selection events to update zoom slider
  useEffect(() => {

    // Ensure the canvas reference is valid
    const canvas = fabricCanvasRef.current;
    if (!canvas) {
      return; // Early return if canvas is not available
    }

    const handleObjectSelected = () => {
      const activeObject = canvas.getActiveObject();

      if (activeObject) {
        // Log the current scale value
        const scalePercent = Math.round(activeObject.scaleX * 100);
        setScaleValue(scalePercent); // Set the scale percentage
      }
    };

    const handleSelectionCleared = () => {
      setScaleValue(100); // Reset scale to 100% when no object is selected
    };

    // Attach event listeners only if canvas exists
    if (canvas) {
      canvas.on('object:selected', handleObjectSelected);
      canvas.on('selection:cleared', handleSelectionCleared);
    }

    // Cleanup event listeners on unmount or re-render
    return () => {
      if (canvas) {
        canvas.off('object:selected', handleObjectSelected);
        canvas.off('selection:cleared', handleSelectionCleared);
      }
    };
  }, []); // Empty dependency array to ensure this runs once on mount

  // Handle scale change from the slider (zooming in/out the active object)
  const handleScaleChange = (event) => {
    const newScale = event.target.value / 100; // Convert slider value to a scale factor
    setScaleValue(event.target.value);

    const activeObject = fabricCanvasRef.current?.getActiveObject();
    if (activeObject) {
      // Reset the origin to the center (this is actually the default behavior)
      activeObject.set({
        originX: 'center',
        originY: 'center',
      });

      activeObject.scale(newScale);  // Apply new scale
      activeObject.setCoords(); // Update object's boundaries
      fabricCanvasRef.current.renderAll();  // Re-render the canvas
    }
  };

  const addNameAndNumberToCanvas = () => {
    if (!fabricCanvasRef.current) return;

    // Check if name and number already exist
    if (nameNumber.length > 0) return; // Prevent adding if there are existing entries

    // Add the name text
    const newNameText = new fabric.Text("NAME", {
      left: 50,
      top: 50,
      fontSize: 30,
      fontFamily: 'Collegiate',
      fill: Color1,
    });

    fabricCanvasRef.current.add(newNameText);

    const nameData = {
      id: newNameText, // Store reference to the Fabric.js text object
      text: "NAME",
      fontSize: 30,
      fontFamily: 'Collegiate',
      textColor: Color1,
    };

    // Add nameData to nameNumber state
    setNameNumber((prevTexts) => [...prevTexts, nameData]);

    // Add the number text below the name
    const newNumberText = new fabric.Text("00", {
      left: 50,
      top: 90, // Position it below the name
      fontSize: 30,
      fontFamily: 'Collegiate',
      fill: Color1,
    });

    fabricCanvasRef.current.add(newNumberText);

    const numberData = {
      id: newNumberText,
      text: "00",
      fontSize: 30,
      fontFamily: 'Collegiate',
      textColor: Color1,
    };

    // Add numberData to nameNumber state
    setNameNumber((prevTexts) => [...prevTexts, numberData]);
  };

  const [selectedSize1, setSelectedSize1] = useState(null);
  const [quantities, setQuantities] = useState({});
  const [sizeQuantities, setSizeQuantities] = useState([]);
  const [sizeQuantities1, setSizeQuantities1] = useState([]);

  const handleSizeClick1 = (size) => {
    setSelectedSize1(size);
    // Check if the size already exists in the sizeQuantities array
    const existingEntry = sizeQuantities.find(entry => entry.size === size);

    if (!existingEntry) {
      // Add new size if it doesn't exist
      setSizeQuantities(prev => [...prev, { size, quantity: 0 }]);
    }
  };

  const handleQuantityChange1 = (size, value) => {
    const quantity = Number(value) || 0; // Convert to number or default to 0
    setQuantities(prev => ({
      ...prev,
      [size]: quantity,
    }));

    // Update the sizeQuantities array
    setSizeQuantities(prev => {
      const existingEntry = prev.find(entry => entry.size === size);
      if (existingEntry) {
        // Update existing entry
        return prev.map(entry =>
          entry.size === size ? { ...entry, quantity } : entry
        );
      } else {
        // If not found, add new entry
        return [...prev, { size, quantity }];
      }
    });
  };

  const totalQuantity = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);

  const manualQuantity = sizeQuantities1.reduce((acc, item) => acc + item.quantity, 0);

  const handleNameAndNumberCheckboxChange = () => {
    setAddName((prevAddName) => {
      const newAddNameState = !prevAddName;

      if (newAddNameState && nameNumber.length === 0) {
        // Add only if 'nameNumber' is empty, to avoid duplicates
        addNameAndNumberToCanvas();
      } else if (!newAddNameState) {
        // If unchecking, remove all text from canvas
        nameNumber.forEach((textData) => {
          const textObject = textData.id;
          if (fabricCanvasRef.current && textObject) {
            fabricCanvasRef.current.remove(textObject);
          }
        });

        setNameNumber([]); // Clear the nameNumber state
        if (fabricCanvasRef.current) {
          fabricCanvasRef.current.renderAll(); // Re-render the canvas
        }
      }

      return newAddNameState;
    });
  };

  // const handleCheckboxChange = (option) => {
  //   if (option === 'nameAndNumber') {
  //     // handleNameAndNumberCheckboxChange();
  //     setAddNumber(false);
  //     setAddName(true);
  //     setQuantities({});
  //     setSizeQuantities([]);

  //   } else if (option === 'size') {
  //     // Clear the name and number if switching to "Add Size"
  //     if (nameNumber && nameNumber.length > 0) {
  //       nameNumber.forEach((textData) => {
  //         const textObject = textData.id;
  //         if (fabricCanvasRef.current && textObject) {
  //           fabricCanvasRef.current.remove(textObject);
  //         }
  //       });
  //     }

  //     setNameNumber([]); // Ensure nameNumber is reset
  //     if (fabricCanvasRef.current) {
  //       fabricCanvasRef.current.renderAll();
  //     }

  //     setAddNumber(true);
  //     setAddName(false);
  //     setQuantities({});
  //     setSizeQuantities([]);
  //   }
  // };

  const handleCheckboxChange = (type) => {
    if (type === 'nameAndNumber') {
      setAddName((prev) => !prev);
    } else if (type === 'size') {
      setAddNumber((prev) => !prev);
    }
  };

  const updateTextColor = (newColor) => {
    setColor1(newColor); // Update the color state

    // Change the color of existing text objects
    nameNumber.forEach((textData) => {
      const textObject = textData.id; // Assuming textData has an 'id' field referencing the fabric.Text object
      if (textObject) {
        textObject.set({ fill: newColor }); // Update the fill color
        if (textObject.canvas) {
          textObject.canvas.renderAll(); // Re-render the canvas if canvas exists
        } else if (fabricCanvasRef.current) {
          fabricCanvasRef.current.renderAll(); // Re-render the canvas directly if canvas reference is available
        }
      }
    });
  };

  // useEffect(() => {
  //   console.log('Updated nameValue:', nameValue); // Log to see updated nameValue
  // }, [nameValue]);

  // useEffect(() => {
  //   // Initialize fabric canvas if not done already
  //   if (!fabricCanvasRef.current) {
  //     fabricCanvasRef.current = new fabric.Canvas('tshirt-canvas');
  //   }
  // }, []);

  const [mainImage, setMainImage] = useState("");

  const handleOrderChange = (event) => {
    setOrderType(event.target.value); // Update state based on selected option
  };
  // const CardImg = [
  //   ...(selectedProduct?.customizeImages?.map(item => item?.images?.image) ?? [])
  // ];

  const CardImg = [
    ...((selectedProduct?.customizeImages?.map(item =>
      item?.images.map(imageItem => imageItem?.image)
    ) ?? []))
  ];


  // const CardImgName = [
  //   ...(selectedProduct?.customizeImages?.map(item => item.name) ?? [])
  // ];

  const productSize = [
    ...(selectedProduct?.customizeImageSize ?? [])
  ]

  const [canvasData, setCanvasData] = useState(Array(CardImg.length).fill(null));
  console.log(canvasData);

  const [payloadData, setPayloadData] = useState({});

  const isLogin = localStorage.getItem('role')
  const isToken = localStorage.getItem('token')

  const quantity1 = localStorage.getItem('quantity') || quantity;
  const option = JSON.parse(localStorage.getItem('selectedOptions'));

  const amount = quantityRate?.amount
  const totalAmount = amount * quantity1;
  const actualAmount = selectedProduct?.amount * quantity1;
  const offerAmount = amount * quantity1;

  // let canvas;

  const productName = window.location.pathname.split('/').pop();

  const [selectedOptions, setSelectedOptions] = useState({});

  useEffect(() => {
    const additionalData = async () => {
      try {
        const response = await axios.get(`${product}/${id}`);
        const productData = response?.data?.result;
        setSelectedProduct(productData);

        // Set the 0th index image as the default image
        if (productData?.customizeImages && productData?.customizeImages?.length > 0) {
          setMainImage(productData.customizeImages[0]?.images[0]?.image);
          setSelectedImageIndex(0); // Set the first item as active
        }
      } catch (error) {
        console.error('Error fetching product data:', error);
      }
    };
    additionalData();
  }, [id]);

  const handleOptionChange = async (fieldType, selectedOption) => {
    setSelectedOptions(prevState => {
      const updatedOptions = {
        ...prevState,
        [fieldType]: selectedOption
      };
      // Convert updatedOptions to an array with fieldType included
      const optionsArray = Object.entries(updatedOptions).map(([fieldType, option]) => ({
        fieldType,
        option
      }));

      // Store as an array in localStorage
      localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
      return updatedOptions;
    });
  };

  const [selectedSize, setSelectedSize] = useState(
    selectedProduct?.customizeImageSize?.[0] || ''
  );
  const size = localStorage.getItem('customizeImageSize');

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const quantityData = async () => {
    try {
      const response = await axios.get(`${quantityCountApi}/${productName}`);
      setQauntityData(response?.data?.result);

      // Determine the minimum quantity based on quantity type
      const min = selectedProduct?.quantityType === 'dropdown'
        ? response?.data?.result?.[0]?.to ?? 1
        : response?.data?.result?.[0]?.from ?? 1;

      const lastIndex = response?.data?.result?.length - 1;
      const max = response?.data?.result?.[lastIndex]?.to;

      setMinimumQuantity(min);
      setMaximumQuantity(max);
      setQuantity(min);
    } catch (error) {
      console.error("Error fetching quantity data:", error);
    }
  };
  const [clipArtSearch, setClipArtSearch] = useState('')
  const [clipArtData, setClipArtData] = useState()
  const [librarySearch, setLibrarySearch] = useState('')
  const [libraryData, setLibraryData] = useState()

  const getClipArtData = async () => {
    try {
      const response = await axios.get(`${customizeDesign}?search=${clipArtSearch}`)
      setClipArtData(response?.data?.result)
    } catch (error) {
      toast.error(error.response.data.msg)
    }
  }
  const getLibraryData = async () => {
    try {
      const response = await axios.get(`${customizeImage}?search=${librarySearch}`)
      setLibraryData(response?.data?.result)
    } catch (error) {
      toast.error(error.response.data.msg)
    }
  }

  useEffect(() => {
    getClipArtData()
    getLibraryData()
  }, [])

  useEffect(() => {
    quantityData(selectedProduct?._id)
  }, [selectedProduct?._id])

  useEffect(() => {

    const handleOnChange = async () => {
      if (!selectedProduct || !selectedProduct._id || !selectedProduct.fieldType) {
        return; // Exit early if dataa or its required properties are undefined
      }

      const payload = {
        product: selectedProduct?._id,
        quantity: quantity,
        field: selectedProduct?.fieldType?.map((eachItem) => (
          {
            fieldType: eachItem?.name?._id,
            option: selectedOptions[eachItem?.name?._id] || null,
          }
        )),
      };
      try {
        const response = await axios.post(payload);
        setQuantityRate(response?.data?.result)
      } catch (error) {
      }
    }

    handleOnChange();
    const optionsArray = selectedProduct?.fieldType?.map((eachItem) => (
      {
        fieldType: eachItem?.name?._id,
        option: selectedOptions[eachItem?.name?._id] || null,
      }
    ));

    // localStorage.setItem('selectedOptions', JSON.stringify(optionsArray)); // Save selected options to localStorage
    // localStorage.setItem('quantity', JSON.stringify(quantity));
  }, [selectedOptions, selectedProduct, quantity])

  useEffect(() => {
    if (size) {
      setSelectedSize(size); // Set the first size as the default
    }
  }, [size]);

  const handleQuantityInput = (event) => {
    const value = event.target.value;

    if (value.length >= 2) {
      handleQuantityChange(event); // Trigger the main function only after two digits
    } else {
      setQuantity(value); // Update the state immediately with the input value
    }
  };

  const handleQuantityChange = async (e) => {
    const newQuantity = parseInt(e.target.value);

    // Handle manual input when quantityType is not 'dropdown' and the new value is invalid
    if (selectedProduct?.quantityType !== 'dropdown' && isNaN(newQuantity)) {
      setQuantity(''); // Clear the input box for manual input
      setQuantityError(''); // Clear error when the field is cleared
      localStorage.removeItem('quantity');
      return;
    }

    // Handle negative or zero quantity
    if (newQuantity <= 0) {
      setQuantity(0);
      localStorage.setItem('quantity', 0);
      return;
    }

    // Check if the new quantity is within the valid range (min and max)
    if (newQuantity >= minimumQuantity && newQuantity <= maximumQuantity) {
      setQuantity(newQuantity);
      localStorage.setItem('quantity', newQuantity);

      // Prepare payload for API call
      const payload = {
        product: selectedProduct?._id,
        quantity: newQuantity,
        field: selectedProduct?.fieldType?.map((eachItem) => ({
          fieldType: eachItem?.name?._id,
          option: selectedOptions[eachItem?.name?._id] || null,
        })),
      };

      try {
        // Call API to get cart rate
        const response = await axios.post(getCartRate, payload);
        setQuantityRate(response?.data?.result);
      } catch (error) {
        console.error('Error fetching cart rate:', error);
      }
    } else {
      // Handle errors when the quantity is out of bounds
      if (newQuantity < minimumQuantity) {
        setQuantityError(`Minimum quantity allowed is ${minimumQuantity}`);
      } else if (newQuantity > maximumQuantity) {
        setQuantityError(`Maximum quantity allowed is ${maximumQuantity}`);
      }
    }
  };


  // const handleQuantityChange = (e) => {
  //   const value = e.target.value;

  //   // Clear the quantity if input is empty
  //   if (value === '') {
  //     setQuantity('');
  //     setQuantityError(null); // Optionally, reset error when cleared
  //     return;
  //   }

  //   // Update quantity if valid
  //   const newValue = parseInt(value, 10);
  //   if (newValue >= 1) {
  //     setQuantity(newValue);
  //     setQuantityError(null);
  //   } else {
  //     setQuantityError('Quantity must be at least 1');
  //   }
  // };


  const typeSpellings = {
    'general': 'General',
    'recemended': 'Recommended',
    'bulckprice': 'Bulk Price',
    'bestprice': 'Best Price'
  };

  // useEffect(() => {
  //   if (Object.keys(selectedOptions).length === 0) {
  //     const initialOptions = {};
  //     selectedProduct?.fieldType?.forEach((eachItem) => {
  //       const fieldType = eachItem?.name?._id;
  //       const firstOption = eachItem?.options[0]?._id;
  //       initialOptions[fieldType] = firstOption;
  //     });

  //     setSelectedOptions(initialOptions);
  //     localStorage.setItem('selectedOptions', JSON.stringify(initialOptions));
  //     localStorage.setItem('quantity', JSON.stringify(selectedProduct?.quantityBasedAmount[0]?.to));

  //   }
  // }, [selectedProduct]);

  useEffect(() => {
    // Retrieve and parse options from localStorage
    const storedOptions = localStorage.getItem('selectedOptions');
    let parsedOptions = [];

    try {
      parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
    } catch (error) {
      console.error('Error parsing selectedOptions from localStorage', error);
    }

    // If parsedOptions contains data, set it to selectedOptions state
    if (parsedOptions.length > 0) {
      const optionsObject = parsedOptions.reduce((acc, { fieldType, option }) => {
        acc[fieldType] = option;
        return acc;
      }, {});
      setSelectedOptions(optionsObject);
    } else {
      // Otherwise, set the first option from each fieldType, if available
      if (selectedProduct?.fieldType) {
        const initialOptions = selectedProduct?.fieldType?.reduce((acc, eachItem) => {
          const fieldType = eachItem?.name?._id;
          const firstOption = eachItem?.options[0]?._id;
          if (fieldType && firstOption) {
            acc[fieldType] = firstOption;
          }
          return acc;
        }, {});

        setSelectedOptions(initialOptions || {});

        // Store initial options in localStorage
        const optionsArray = Object.entries(initialOptions || {}).map(([fieldType, option]) => ({
          fieldType,
          option,
        }));
        localStorage.setItem('selectedOptions', JSON.stringify(optionsArray));
        localStorage.setItem('quantity', JSON.stringify(selectedProduct?.quantityBasedAmount[0]?.to));
        if (selectedProduct?.customizeImageSize?.length > 0) {
          localStorage.setItem('customizeImageSize', JSON.stringify(selectedProduct?.customizeImageSize[0]));
        }
      }
    }
  }, [selectedProduct]);

  const resetForm = () => {
    setUndoStack([]);
    setRedoStack([]);
    setCanvasData([]);
    setSelectedImageUrl("");
    setColor("");
    setTexts([]);
    setCustomPicture(null);
    setSizeQuantities([]);
    setUploadedImages([]);
    setNameNumber([]); // Initialize nameNumber as an empty array
    setTextOptions({
      text: "",
      fontSize: '20',
      fontFamily: "Arial",
      textColor: "#000000"
    });
    setTextOptions1({
      fontFamily: "Impact",
      textColor: "#000000"
    });
    setAddName(false);
    setAddNumber(false);

    // Clear the fabric.js canvas
    if (fabricCanvas) {
      fabricCanvas.clear();
    }

    // Reset file input value
    const fileInput = document.getElementById("tshirt-custompicture");
    if (fileInput) {
      fileInput.value = "";
    }

    // Reset color input
    const colorInput = document.getElementById("tshirt-color");
    if (colorInput) {
      colorInput.value = "#fff";
    }

    // Reset background color
    const tshirtDiv = document.getElementById("tshirt-div");
    if (tshirtDiv) {
      tshirtDiv.style.backgroundColor = "#fff";
    }
  };


  const reset = () => {
    setSelectedImageUrl("");
    setColor("");
    setCustomPicture(null);
    setTextOptions({
      text: "",
      fontSize: '20',
      fontFamily: "Arial",
      textColor: "#000000"
    })
  };

  useEffect(() => {
    if (fabricCanvas && selectedImageUrl) {
      updateTshirtImage(selectedImageUrl);
    }
  }, [fabricCanvas, selectedImageUrl]);

  const updateTshirtImage = (imageURL) => {
    fabric.Image.fromURL(imageURL, function (img) {
      img.scaleToHeight(300);
      img.scaleToWidth(300);
      //   fabricCanvas.clear();
      fabricCanvas.add(img).renderAll();
    },
      {}
      // { crossOrigin: 'anonymous' }
    );
  };


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const imgUrl = event.target.result;

      // Generate a unique ID for each image
      const imgId = Date.now();

      // Store the image URL with the ID in the state
      setUploadedImages((prevImages) => [...prevImages, { id: imgId, url: imgUrl }]);

      // Create the image and add it to the canvas after loading
      const imgObj = new Image();
      imgObj.src = imgUrl;

      imgObj.onload = function () {
        if (!fabricCanvasRef.current) return;

        const img = new fabric.Image(imgObj);
        img.scaleToHeight(300);
        img.scaleToWidth(300);

        // Set a custom property `id` on the fabric image to reference it later
        img.imgId = imgId;

        // Add the image to the canvas
        fabricCanvasRef.current.add(img);
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }

    // Reset the file input after reading the file
    const fileInput = document.getElementById("tshirt-custompicture");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleRemoveBackground = async (imageUrl, imgId, index) => {
    try {
      // Show loader while processing
      setLoadingImageIndex(index);

      // Fetch the image data as a Blob
      const responseImage = await fetch(imageUrl);
      const imageBlob = await responseImage.blob();

      // Prepare FormData with the Blob
      const formData = new FormData();
      formData.append('image_file', imageBlob, 'image.png'); // Add a filename for clarity

      // Send request to Remove.bg API
      const response = await fetch('https://api.remove.bg/v1.0/removebg', {
        method: 'POST',
        headers: {
          'X-Api-Key': '9QfbfozdkszREv2FzAmHLWqD', // Use your API key here
        },
        body: formData,
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error('Remove.bg API error:', errorMessage);
        throw new Error(`Failed to remove background: ${errorMessage}`);
      }

      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = () => {
        const processedImageUrl = reader.result;

        // Update the image with the same ID in setUploadedImages
        setUploadedImages((prevImages) =>
          prevImages.map((img) => (img.id === imgId ? { ...img, url: processedImageUrl } : img))
        );

        // Create a new Image object for the processed image
        const imgObj = new Image();
        imgObj.src = processedImageUrl;

        imgObj.onload = function () {
          if (!fabricCanvasRef.current) return;

          // Find the old image on the canvas by ID
          const oldImage = fabricCanvasRef.current.getObjects().find(
            (obj) => obj.imgId === imgId // Use imgId as a custom property to identify the image
          );

          if (oldImage) {
            // Get the position and scale of the old image
            const { left, top, scaleX, scaleY } = oldImage;

            imgObj.width = oldImage?.width;
            imgObj.height = oldImage?.height;

            // Remove the old image from the canvas
            fabricCanvasRef.current.remove(oldImage);

            // Create a new fabric image object with the new image
            const newImg = new fabric.Image(imgObj, {
              left,
              top,
              // scaleX: scaleX * 1.2,
              // scaleY: scaleY * 1.2,
              // width: oldImage.width,  // Use the width of the old image
              // height: oldImage.height,
            });

            // Set the custom property imgId to the new image
            newImg.imgId = imgId;

            // Add the new image to the canvas at the same position and scale
            fabricCanvasRef.current.add(newImg);
          }
          setLoadingImageIndex(null);
        };
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error removing background:', error);
      alert(`Error removing background. Please try again. Details: ${error.message}`);
    }
  };

  const deleteImage = () => {
    if (fabricCanvas && fabricCanvas.getActiveObject()) {
      fabricCanvas.remove(fabricCanvas.getActiveObject());
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }
  }

  const deleteImage1 = (imageId) => {
    // Find the image object on the canvas by its id
    const canvasObjects = fabricCanvasRef.current.getObjects();
    const imageToRemove = canvasObjects.find(obj => obj.id === imageId);

    if (imageToRemove) {
      // Remove the image from the canvas
      fabricCanvasRef.current.remove(imageToRemove);
      fabricCanvasRef.current.renderAll();

      // Remove the image from the state
      setLibraryImages((prevImages) => prevImages.filter((image) => image.id !== imageId));
    }
  };


  const deleteImage2 = (imageId1) => {
    const imageToRemove = fabricCanvasRef.current.getObjects().find(obj => obj.id === imageId1);

    if (imageToRemove) {
      // Remove the image from the canvas
      fabricCanvasRef.current.remove(imageToRemove);
      fabricCanvasRef.current.renderAll(); // Re-render the canvas after removal
    }

    setClipArtImages((prevImages) => prevImages.filter((image) => image.id !== imageId1));
  };

  const updateTextOnCanvas = (textId, updates) => {
    const selectedObject = fabricCanvasRef.current.getObjects().find((obj) => obj === textId);
    if (selectedObject) {
      selectedObject.set(updates); // Update text object properties
      fabricCanvasRef.current.renderAll(); // Re-render the canvas to reflect changes
    }

    // Also update the text in the state
    setTexts((prevTexts) =>
      prevTexts.map((item) =>
        item.id === textId
          ? { ...item, ...updates }
          : item
      )
    );
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setTextOptions((prev) => ({ ...prev, text: newText }));

    // Update the active object on the canvas
    // const activeObject = fabricCanvasRef.current.getActiveObject();
    // if (activeObject && activeObject.type === 'text') {
    //   activeObject.set('text', newText);
    //   fabricCanvasRef.current.renderAll();
    // }

    // Update the card text if in editing mode
    if (isEditing && selectedTextId) {
      updateTextOnCanvas(selectedTextId, { text: newText });
    }
  };

  const handleFontSizeChange = (e) => {
    const newFontSize = parseInt(e.target.value, 10);
    setTextOptions((prev) => ({ ...prev, fontSize: newFontSize }));

    // Update the active object on the canvas
    // const activeObject = fabricCanvasRef.current.getActiveObject();
    // if (activeObject && activeObject.type === 'text') {
    //   activeObject.set('fontSize', newFontSize);
    //   fabricCanvasRef.current.renderAll();
    // }

    // Update the card font size if in editing mode
    if (isEditing && selectedTextId) {
      updateTextOnCanvas(selectedTextId, { fontSize: newFontSize });
    }
  };

  const handleFontFamilyChange = (e) => {
    const newFontFamily = e.target.value;
    setTextOptions((prev) => ({ ...prev, fontFamily: newFontFamily }));

    // // Update the active object on the canvas
    // const activeObject = fabricCanvasRef.current.getActiveObject();
    // if (activeObject && activeObject.type === 'text') {
    //   activeObject.set('fontFamily', newFontFamily);
    //   fabricCanvasRef.current.renderAll();
    // }

    // Update the card font family if in editing mode
    if (isEditing && selectedTextId) {
      updateTextOnCanvas(selectedTextId, { fontFamily: newFontFamily });
    }
  };

  // const handleTextColorChange = (e) => {
  //   const newTextColor = e.target.value;
  //   setTextOptions((prev) => ({ ...prev, textColor: newTextColor }));

  // Update the active object on the canvas
  // const activeObject = fabricCanvasRef.current.getActiveObject();
  // if (activeObject && activeObject.type === 'text') {
  //   activeObject.set('fill', newTextColor);
  //   fabricCanvasRef.current.renderAll();
  // }

  //   // Update the card text color if in editing mode
  //   if (isEditing && selectedTextId) {
  //     updateTextOnCanvas(selectedTextId, { textColor: newTextColor });
  //   }
  // };

  const handleTextColorChange = (e) => {
    const newTextColor = e.target.value;
    setTextOptions((prev) => ({ ...prev, textColor: newTextColor }));

    const activeObject = fabricCanvasRef.current.getActiveObject();
    if (activeObject && activeObject.type === 'text') {
      activeObject.set('fill', newTextColor);
      fabricCanvasRef.current.renderAll();
    }

    // Update the card text color if in editing mode
    if (isEditing && selectedTextId) {
      updateTextOnCanvas(selectedTextId, { textColor: newTextColor });
    }
  };

  const handleEnterNameClick = (event) => {
    event.preventDefault(); // Prevent URL change
    setModalName(true); // Open the modal

    if (addName && addNumber) {
      setModalMode('nameAndNumber');
    } else if (addName) {
      setModalMode('name');
    } else if (addNumber) {
      setModalMode('number');
    }
  };

  const [texts, setTexts] = useState([]); // Store added texts
  const [texts1, setTexts1] = useState([]); // Store added texts
  const [selectedTextId, setSelectedTextId] = useState(null);
  const [textItems, setTextItems] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const addText1 = (text) => {
    if (!fabricCanvasRef.current) return;

    // Check if the text already exists on the canvas
    const existingTextObject = fabricCanvasRef.current.getObjects().find(
      (obj) => obj instanceof fabric.Text && obj.text === text
    );

    // If the text already exists, do not add it again
    if (existingTextObject) {
      return; // Exit function if the text is already on canvas
    }

    // Determine the font size based on the text (name or number)
    const fontSize = text === '00' ? 100 : 50;

    // Create a new text object with conditional font size
    const newText = new fabric.Text(text, {
      fontSize: fontSize,
      fontFamily: textOptions1.fontFamily,
      fill: textOptions1.textColor,
      selectable: true,
      editable: true,
    });

    // Center text horizontally
    const canvasWidth = fabricCanvasRef.current.getWidth();
    const textWidth = newText.width || 0;
    const left = (canvasWidth - textWidth) / 2;
    const top = text === '00' ? 160 : 100;

    newText.set({ left, top });

    // Add text to canvas
    fabricCanvasRef.current.add(newText);

    // Store text properties in texts1 (to track it properly)
    setTexts1((prevTexts) => [
      ...prevTexts,
      {
        id: newText,
        text: text,
        fontFamily: textOptions1.fontFamily,
        textColor: textOptions1.textColor,
      },
    ]);
  };

  const removeText = (text) => {
    if (!fabricCanvasRef.current) return;

    const textObject = texts1.find((t) => t.text === text)?.id;
    if (textObject) {
      fabricCanvasRef.current.remove(textObject);
      setTexts1((prevTexts) => prevTexts.filter((t) => t.text !== text));
    }
  };

  const handleCheckboxChange1 = (type) => {
    if (type === 'nameAndNumber') {
      setAddName(!addName);
      if (!addName) {
        addText1('NAME');
      } else {
        removeText('NAME');
      }
    } else if (type === 'size') {
      setAddNumber(!addNumber);
      if (!addNumber) {
        addText1('00');
      } else {
        removeText('00');
      }
    }
  };


  useEffect(() => {
    if (addName) {
      addText1('NAME');
    } else {
      removeText('NAME');
    }

    if (addNumber) {
      addText1('00');
    } else {
      removeText('00');
    }
  }, [addName, addNumber]);

  const reapplyTextProperties = () => {
    texts1.forEach((textItem) => {
      const { id, fontFamily, textColor } = textItem;
      id.set('fontFamily', fontFamily);
      id.set('fill', textColor);
    });
    fabricCanvasRef.current.renderAll();
  };

  const handleSideChange = (index) => {
    if (addName) removeText('NAME');
    if (addNumber) removeText('00');

    setCurrentSide(index);
    setSelectedImageIndex1(index);

    reapplyTextProperties();

    if (addName) addText1('NAME');
    if (addNumber) addText1('00');
  };

  const [textOptions1, setTextOptions1] = useState({
    fontFamily: 'Impact',
    textColor: '#000000',
  });

  // Handle font family change
  const handleFontFamilyChange1 = (event) => {
    const newFontFamily = event.target.value;
    updateTextFontFamily(newFontFamily);
  };

  const updateTextFontFamily = (newFontFamily) => {
    setTextOptions1((prevOptions) => ({ ...prevOptions, fontFamily: newFontFamily }));

    // Clear the canvas to avoid duplicate text objects
    fabricCanvasRef.current.clear();

    // Map through texts1 to update font family for "NAME" and "00"
    const updatedTexts = texts1.map((textObj) => {
      if (textObj.text === 'NAME' || textObj.text === '00') {
        textObj.id.set('fontFamily', newFontFamily);
        return { ...textObj, fontFamily: newFontFamily };
      }
      return textObj;
    });

    // Re-add only updated text objects to the canvas
    updatedTexts.forEach((textObj) => {
      fabricCanvasRef.current.add(textObj.id);
    });

    setTexts1(updatedTexts);
    fabricCanvasRef.current.renderAll(); // Refresh canvas
  };


  const handleColorChange = (event) => {
    const newTextColor = event.target.value;
    updateTextColor1(newTextColor);
  };

  const updateTextColor1 = (newTextColor) => {
    setTextOptions1((prevOptions) => ({ ...prevOptions, textColor: newTextColor }));

    // Clear the canvas to avoid duplicate text objects
    fabricCanvasRef.current.clear();

    // Map through texts1 to update color for "NAME" and "00"
    const updatedTexts = texts1.map((textObj) => {
      if (textObj.text === 'NAME' || textObj.text === '00') {
        textObj.id.set('fill', newTextColor);
        return { ...textObj, textColor: newTextColor };
      }
      return textObj;
    });

    // Re-add only updated text objects to the canvas
    updatedTexts.forEach((textObj) => {
      fabricCanvasRef.current.add(textObj.id);
    });

    setTexts1(updatedTexts);
    fabricCanvasRef.current.renderAll(); // Refresh canvas
  };

  const addText = () => {
    if (!fabricCanvasRef.current) return;

    const newText = new fabric.Text(textOptions.text, {
      left: 50,
      top: 50,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
    });

    fabricCanvasRef.current.add(newText);

    const textData = {
      id: newText,
      text: textOptions.text,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      textColor: textOptions.textColor,
    };

    setTexts((prevTexts) => [...prevTexts, textData]);
    reset(); // Reset the form
  };



  // Function to select text for editing
  const selectTextForEdit = (textId) => {
    const selectedText = texts.find((item) => item.id === textId);
    if (selectedText && fabricCanvasRef.current) {
      // Update input fields with the selected text values
      setTextOptions({
        text: selectedText.text,
        fontSize: selectedText.fontSize,
        fontFamily: selectedText.fontFamily,
        textColor: selectedText.textColor,
      });

      setSelectedTextId(textId); // Track selected text
      setIsEditing(true);

      // Highlight the selected text on the canvas for editing
      fabricCanvasRef.current.setActiveObject(selectedText.id);
      fabricCanvasRef.current.renderAll(); // Re-render the canvas
    }
  };

  // Function to delete text
  const deleteText = (e, textId) => {
    e.stopPropagation(); // To prevent the card click event

    fabricCanvasRef.current.remove(textId); // Remove from canvas
    setTexts((prevTexts) => prevTexts.filter((item) => item.id !== textId));
    reset();
    setIsEditing(false)
  };

  const updateText = () => {
    // Reset the input fields and exit edit mode
    reset();
    setIsEditing(false);
    setSelectedTextId(null);

    // Clear selection on the Fabric.js canvas
    if (fabricCanvasRef.current) {
      fabricCanvasRef.current.discardActiveObject(); // Deselect the current object
      fabricCanvasRef.current.renderAll(); // Re-render the canvas to apply changes
    }
  };

  const addToCart = async () => {
    if (!editedImage || editedImage.length === 0) {
      console.error('editedImage is undefined or empty');
      return;
    }

    // Retrieve and parse options from localStorage
    const storedOptions = localStorage.getItem('selectedOptions');
    let parsedOptions = [];

    try {
      parsedOptions = storedOptions ? JSON.parse(storedOptions) : [];
    } catch (error) {
      console.error('Error parsing selectedOptions from localStorage', error);
      parsedOptions = [];
    }

    // Ensure parsedOptions is an array
    if (!Array.isArray(parsedOptions)) {
      console.error('parsedOptions is not an array');
      parsedOptions = [];
    }

    // Convert parsedOptions into a format that matches your payload
    const optionsMap = parsedOptions.reduce((acc, { fieldType, option }) => {
      acc[fieldType] = option;
      return acc;
    }, {});

    const customizeImages = editedImage.map(image => image.url);

    const payload = {
      product: selectedProduct?._id,
      // quantity: quantity1,
      sizeWithQuantity: sizeQuantities?.length > 0 ? (sizeQuantities) : (sizeQuantities1),
      field: selectedProduct?.fieldType?.map((eachItem) => ({
        fieldType: eachItem?.name?._id,
        option: optionsMap[eachItem?.name?._id] || null,
      })),
      customizeImages: customizeImages,
      ...(sizeQuantities.length === 0 && {
        quantity: quantity1,
        size: selectedSize
      })
    };


    if (isLogin === 'USER') {
      try {
        const response = await axios.post(addCart, payload);
        toast.success(response?.data?.msg);
        localStorage.removeItem('AddCart');
        setTimeout(() => {
          setLoading(false);
          navigate('/myCart');
        }, 2000);
        navigate('/myCart');
        localStorage.removeItem('selectedOptions');
        localStorage.removeItem('quantity');
      } catch (error) {
        toast.error(error?.response?.data?.msg);
      }
    } else {
      toggle();
      alert('You are not signed in as a User');
    }
  };

  const handleSaveAndProceed = async () => {
    setLoader(true);
    if (fabricCanvasRef.current) {
      const fabricCanvas = fabricCanvasRef.current; // Accessing the fabric.js canvas instance
      fabricCanvas.discardActiveObject(); // Deselect any active objects
      fabricCanvas.renderAll(); // Re-render the canvas to reflect the changes
    }

    if (!isToken) {
      toggle()
      setLoader(false)
      return;
    }
    const updatedOrder = [...imageOrder];

    try {
      const currentImages = CardImg[selectedColorIndex];

      for (let j = 0; j < currentImages.length; j++) {
        const imageUrl = currentImages[j];
        setSelectedImageIndex(j);
        setMainImage(imageUrl);

        await new Promise((resolve, reject) => {
          const image = new Image();
          image.crossOrigin = "Anonymous"; // Set crossOrigin before assigning the source

          image.onload = () => resolve();
          image.onerror = (error) => {
            console.error(`Error loading image at index ${j}:`, error);
            reject(new Error(`Error loading image at index ${j}: ${imageUrl}`));
          };

          const cacheBustedUrl = `${imageUrl}?t=${new Date().getTime()}`; // Cache busting
          image.src = cacheBustedUrl;
        });

        await new Promise((resolve) => setTimeout(resolve, 1000)); // Delay

        const element = document.getElementById('tshirt-div');
        if (!element) throw new Error(`Element with ID 'tshirt-div' not found.`);

        const blob = await domtoimage.toBlob(element);
        const randomIndex = getRandomNumberNearIndex(j);

        const formData = new FormData();
        formData.append('file', blob, `design${j}_${randomIndex}.png`);
        const response = await axios.post(fileUpload, formData);

        if (response.status === 200 && response.data.result && response.data.result.length > 0) {
          const url = { url: response.data.result[0].location }; // Use {url: location}
          updatedOrder[j] = url;
        } else {
          throw new Error(`Error saving image at index ${j}: No result found in response.`);
        }
      }

      setEditedImage(updatedOrder);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      toggle1();
      // localStorage.('AddCart')
      // Open modal after images are processed
      // setImagesUpdated(true);
    } catch (error) {
      console.error('Error uploading images:', error);
      alert('Error uploading images. Please try again.');
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (imagesUpdated) {
      addToCart(); // Add to cart logic after confirmation
      setImagesUpdated(false); // Reset the flag after adding to cart
    }
  }, [imagesUpdated]);

  useEffect(() => {
    if (localStorage.getItem('AddCart')) {
      handleSaveAndProceed()
    }
  }, [localStorage.getItem('AddCart')])

  const deselectActiveObject = () => {
    if (fabricCanvasRef.current) {
      const fabricCanvas = fabricCanvasRef.current; // Accessing the fabric.js canvas instance

      fabricCanvas.discardActiveObject(); // Deselect any active object
      fabricCanvas.renderAll(); // Re-render the canvas to reflect the changes
    }
  };

  const handleImageClickWrapper = (image, index) => {
    deselectActiveObject();
    setSelectedImageIndex(index);
    setMainImage(image);
  };

  const getRandomNumberNearIndex = (index) => {
    const min = 0;
    const max = 9;
    return index + Math.floor(Math.random() * (max - min + 1) + min);
  };

  const canvasElement = document.getElementById('drawingArea');
  if (canvasElement) {
    canvasElement.style.border = 'none';
  }

  const handleNavigateBack = () => {
    if (imageOrder.length > 0) {
      const confirmMessage = 'If you go back, the saved images will be deleted.';
      const shouldNavigate = window.confirm(confirmMessage);
      if (shouldNavigate) {
        // Perform navigation
        navigate(-1);
      }
    } else {
      const confirmMessage = 'Are you sure you want to navigate back?';
      const shouldNavigate = window.confirm(confirmMessage);
      if (shouldNavigate) {
        // Perform navigation
        navigate(-1);
      }
    }
    // Optionally, handle other logic based on user's choice
  };

  // useEffect(() => {
  //   const canvas = new fabric.Canvas(canvasRef.current);
  //   setFabricCanvas(canvas);
  //   fabricCanvasRef.current = canvas;

  //   // Load canvas data if available for selected image index
  //   if (canvasData[selectedImageIndex]) {
  //     canvas.loadFromJSON(canvasData[selectedImageIndex], () => {
  //       canvas.renderAll();
  //     });
  //   } else {
  //     canvas.clear();
  //   }

  //   // Save canvas data when modified
  //   const handleCanvasChange = () => {
  //     const jsonData = JSON.stringify(canvas.toJSON());
  //     setCanvasData((prevData) => {
  //       const newData = [...prevData];
  //       newData[selectedImageIndex] = jsonData;
  //       return newData;
  //     });
  //     addStateToUndoStack(); // Save the current state to undo stack
  //     setRedoStack((prevRedoStack) => {
  //       const newRedoStack = { ...prevRedoStack };
  //       newRedoStack[selectedImageIndex] = []; // Clear the redo stack for current index
  //       return newRedoStack;
  //     });
  //   };

  // // Function to delete the active object
  // const deleteActiveObject = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject) {
  //     canvas.remove(activeObject);
  //     handleCanvasChange(); // Update canvas state after deletion
  //   }
  // };

  // // Handle keyboard events for deletion
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Delete' || event.key === 'Backspace') {
  //     deleteActiveObject();
  //   }
  // };

  // // Attach keydown listener for delete functionality
  // document.addEventListener('keydown', handleKeyDown);

  //   // Clean up event listener on unmount
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //     canvas.off('selection:created');
  //     canvas.off('selection:cleared');
  //     canvas.off('object:modified', handleCanvasChange);
  //     canvas.off('object:added', handleCanvasChange);
  //     canvas.off('object:removed', handleCanvasChange);
  //     canvas.dispose();
  //     fabricCanvasRef.current = null;
  //   };
  // }, [selectedImageIndex]);


  // useEffect(() => {
  //   const canvas = new fabric.Canvas(canvasRef.current);
  //   setFabricCanvas(canvas);
  //   fabricCanvasRef.current = canvas;

  //   // Load canvas data if available for selected image index
  //   if (canvasData[selectedImageIndex]) {
  //     canvas.loadFromJSON(canvasData[selectedImageIndex], () => {
  //       canvas.renderAll();
  //     });
  //   } else {
  //     canvas.clear();
  //   }

  //   // Save canvas data when modified
  //   const handleCanvasChange = () => {
  //     const jsonData = JSON.stringify(canvas.toJSON());
  //     setCanvasData((prevData) => {
  //       const newData = [...prevData];
  //       newData[selectedImageIndex] = jsonData;
  //       return newData;
  //     });
  //     addStateToUndoStack(); // Save the current state to undo stack
  //     setRedoStack((prevRedoStack) => {
  //       const newRedoStack = { ...prevRedoStack };
  //       newRedoStack[selectedImageIndex] = []; // Clear the redo stack for current index
  //       return newRedoStack;
  //     });
  //   };

  //   // Show delete button when an object is selected
  //   const handleSelectionCreated = () => {
  //     setShowDeBtn(true);
  //   };

  //   // Hide delete button when no object is selected
  //   const handleSelectionCleared = () => {
  //     setShowDeBtn(false);
  //   };

  //   // Attach canvas event listeners to track changes
  //   canvas.on('object:added', handleCanvasChange);
  //   canvas.on('object:modified', handleCanvasChange);
  //   canvas.on('object:removed', handleCanvasChange);
  //   canvas.on('selection:created', handleSelectionCreated);
  //   canvas.on('selection:cleared', handleSelectionCleared);

  // // Function to delete the active object
  // const deleteActiveObject = () => {
  //   const activeObject = canvas.getActiveObject();
  //   if (activeObject) {
  //     canvas.remove(activeObject);
  //     handleCanvasChange(); // Update canvas state after deletion
  //     setShowDeBtn(false); // Hide delete button after deletion
  //   }
  // };

  // // Handle keyboard events for deletion
  // const handleKeyDown = (event) => {
  //   if (event.key === 'Delete' || event.key === 'Backspace') {
  //     deleteActiveObject();
  //   }
  // };

  // // Attach keydown listener for delete functionality
  // document.addEventListener('keydown', handleKeyDown);

  //   // Clean up event listener and canvas on unmount
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //     canvas.off('object:added', handleCanvasChange);
  //     canvas.off('object:modified', handleCanvasChange);
  //     canvas.off('object:removed', handleCanvasChange);
  //     canvas.off('selection:created', handleSelectionCreated);
  //     canvas.off('selection:cleared', handleSelectionCleared);
  //     canvas.dispose();
  //     fabricCanvasRef.current = null;
  //   };
  // }, [selectedImageIndex, canvasData, setCanvasData]);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);
    setFabricCanvas(canvas);
    fabricCanvasRef.current = canvas;

    // Load canvas data if available for selected image index
    if (canvasData[selectedImageIndex]) {
      canvas.loadFromJSON(canvasData[selectedImageIndex], () => {
        canvas.renderAll();
      });
    } else {
      canvas.clear();
    }

    // Save canvas data when modified
    const handleCanvasChange = () => {
      const jsonData = JSON.stringify(canvas.toJSON());
      setCanvasData((prevData) => {
        const newData = [...prevData];
        newData[selectedImageIndex] = jsonData;
        return newData;
      });
    };

    // Function to delete the active object
    const deleteActiveObject = () => {
      const activeObject = canvas.getActiveObject();
      if (activeObject) {
        canvas.remove(activeObject);
        handleCanvasChange(); // Update canvas state after deletion
        setShowDeBtn(false); // Hide delete button after deletion
      }
    };

    // Handle keyboard events for deletion
    const handleKeyDown = (event) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        deleteActiveObject();
      }
    };

    // Attach keydown listener for delete functionality
    document.addEventListener('keydown', handleKeyDown);

    // Listen for object selection
    canvas.on('selection:created', (e) => {
      const activeObject = e.target;
      // if (activeObject && activeObject.type === 'text') {
      //   setTextOptions({
      //     text: activeObject.text,
      //     fontSize: activeObject.fontSize,
      //     fontFamily: activeObject.fontFamily,s
      //     textColor: activeObject.fill
      //   });
      // }
      setShowDeBtn(true);
    });


    // Listen for deselection
    canvas.on("selection:cleared", () => {
      setShowDeBtn(false);
    });

    canvas.on('object:modified', handleCanvasChange);
    canvas.on('object:added', handleCanvasChange);
    canvas.on('object:removed', handleCanvasChange);

    // Clean up canvas event listeners and references
    return () => {
      canvas.off('selection:created');
      canvas.off('selection:cleared');
      canvas.off('object:modified', handleCanvasChange);
      canvas.off('object:added', handleCanvasChange);
      canvas.off('object:removed', handleCanvasChange);
      canvas.dispose();
      fabricCanvasRef.current = null;
    };
  }, [selectedImageIndex]);

  const handleParaClick = () => {
    setIsProduct(true);
    setIsDesign(false);
    setIsText(false);
    setIsImage(false);
    setIsNameNum(false);
    setIsUpload(false);
    setIsQr(false);
  };

  const handleDesignClick = () => {
    setIsDesign(true);
    setIsProduct(false);
    setIsText(false);
    setIsImage(false);
    setIsNameNum(false);
    setIsUpload(false);
    setIsQr(false);
  };

  const handleTextClick = () => {
    setIsText(true);
    setIsDesign(false);
    setIsProduct(false);
    setIsImage(false);
    setIsNameNum(false);
    setIsUpload(false);
    setIsQr(false);
  };

  const handleImageClick = () => {
    setIsDesign(false);
    setIsProduct(false);
    setIsText(false);
    setIsImage(true);
    setIsNameNum(false);
    setIsUpload(false);
    setIsQr(false);
  };
  const handleUploadClick = () => {
    setIsDesign(false);
    setIsProduct(false);
    setIsText(false);
    setIsImage(false);
    setIsNameNum(false);
    setIsUpload(true);
    setIsQr(false);
  };

  const handleNameClick = () => {
    setIsDesign(false);
    setIsProduct(false);
    setIsText(false);
    setIsImage(false);
    setIsUpload(false);
    setIsNameNum(true);
    setIsQr(false);
  };

  const handleQrClick = () => {
    setIsDesign(false);
    setIsProduct(false);
    setIsText(false);
    setIsImage(false);
    setIsNameNum(false);
    setIsUpload(false);
    setIsQr(true);
  };

  const [colors, setColors] = useState([
    // Add your desired color hex codes here
    '#800000', '#000000', '#191919', '#800080', '#FF0000', '#A52A2A', '#B22222', '#0000FF', '#808080',
    '#C0C0C0', '#A9A9A9', '#228B22', '#FFA500', '#D2691E', '#008000', '#FFB6C1', '#ADD8E6', '#FFFF00',
    '#F0F8FF', '#FAEBD7', '#F0FFF0', '#FFFFF',
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorChange1 = (color) => {
    setSelectedColor(color.hex); // Example for react-color
  };

  const handleAddColor = () => {
    if (selectedColor) {
      setColors([...colors, selectedColor]);
      setSelectedColor(null);
      setIsOpen(false);
    }
  };

  const navToProduct = (category_url) => {
    navigate('/products')
  }

  const applyColorToCanvas = (color) => {
    if (!fabricCanvasRef.current) return;

    // Apply the color to the canvas background
    fabricCanvasRef.current.backgroundColor = color;
    fabricCanvasRef.current.renderAll();
  };

  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [qrCodeValue, setQrCodeValue] = useState('');

  const handleOptionChange1 = (e) => {
    setSelectedOption(e.target.value);
    setInputValue({});
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update the input value
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [name]: value,
    }));

    // Validate the field
    validateField(name, value);
  };


  const handleSubmit = () => {
    let qrValue = '';
    switch (selectedOption) {
      case 'Text':
        qrValue = inputValue.text || '';
        break;
      case 'Dial a Number':
        qrValue = inputValue.phone || '';
        break;
      case 'Open a Link':
        qrValue = inputValue.url || '';
        break;
      case 'Send an Email':
        qrValue = `mailto:${inputValue.email || ''}`;
        break;
      case 'Visiting Card':
        qrValue = `${inputValue.firstName || ''} ${inputValue.lastName || ''}\n${inputValue.position || ''}\n${inputValue.company || ''}\n${inputValue.address || ''}\n${inputValue.phoneNumber || ''}\n${inputValue.email || ''}\n${inputValue.website || ''}`;
        break;
      default:
        qrValue = '';
        break;
    }

    setQrCodeValue(qrValue);
  };

  const handleDownload = () => {
    const canvas = document.getElementById('tshirt-custompicture');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrcode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const handleCanvasSet = () => {
    const canvas = document.getElementById('tshirt-custompicture');
    const qrCodeDataUrl = canvas.toDataURL('image/png');

    // Load the QR code image into Fabric.js canvas
    fabric.Image.fromURL(qrCodeDataUrl, function (img) {
      const canvas = canvasRef.current;
      if (canvas) {
        // canvas.add(img);
        fabricCanvasRef.current.add(img);
        // canvas.renderAll();
      }
    });
  };

  // useEffect(() => {
  //   if (selectedProduct?.customizeImages && selectedProduct?.customizeImages.length > 0) {
  //     handleColorSelect(0); // Select the first color by default
  //   }
  // }, [selectedProduct]);

  useEffect(() => {
    // Retrieve selectedColorIndex from localStorage
    const selectedColorIndex = localStorage.getItem("selectedColorIndex");

    // Parse it as a number or default to 0 if it doesn't exist
    const colorIndex = selectedColorIndex !== null ? parseInt(selectedColorIndex, 10) : 0;

    // Check if customizeImages exist and then apply the selected color index
    if (selectedProduct?.customizeImages && selectedProduct?.customizeImages.length > 0) {
      handleColorSelect(colorIndex);
    }
  }, [selectedProduct]);

  const handleColorSelect = (colorIndex) => {
    const selectedColorImages = selectedProduct?.customizeImages[colorIndex]?.images;
    if (selectedColorImages && selectedColorImages.length > 0) {
      setMainImage(selectedColorImages[0].image);
      setCardImgName(selectedColorImages.map(image => image.name));
      setSelectedImageIndex(0);
      setSelectedColorIndex(colorIndex); // Update the selected color index
    }
  };

  // const addImageToCanvas = (imgSrc) => {
  //   fabric.Image.fromURL(imgSrc, (img) => {
  //     img.set({
  //       left: 100,
  //       top: 100,
  //       scaleX: 0.5,
  //       scaleY: 0.5,
  //     });
  //     fabricCanvasRef.current.add(img);
  //   });
  // };

  const addImageToCanvas = (imgSrc) => {
    // Check if the image already exists in the clipArtImages state
    const imageExists = clipArtImages.some(image => image.src === imgSrc);

    if (imageExists) {
      toast.error('Image is already in the clip art, skipping addition to canvas.');
      return;
    }

    // Proceed to add the image to the canvas if it's not already present
    fabric.Image.fromURL(imgSrc, (img) => {
      // Get the original dimensions of the image
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Define the maximum dimensions you want the image to have on the canvas
      const maxWidth = 200;
      const maxHeight = 200;

      // Calculate scaling factors to maintain aspect ratio
      const scaleX = maxWidth / imgWidth;
      const scaleY = maxHeight / imgHeight;

      // Set the scale based on the smaller factor to maintain aspect ratio
      const scaleFactor = Math.min(scaleX, scaleY);

      // Generate a unique id for the image
      const imageId = `image_${new Date().getTime()}`;

      // Set image properties and position on the canvas
      img.set({
        left: 100,
        top: 100,
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        id: imageId, // Assigning a unique id to track it later
      });

      // Add the image to the fabric.js canvas
      fabricCanvasRef.current.add(img);

      // Update the state with the new image's data, storing imgSrc and its id
      setClipArtImages((prevImages) => [
        ...prevImages,
        { id: imageId, src: imgSrc }, // Store both id and the image URL (src)
      ]);
    });
  };


  const addImageToCanvas1 = (imgSrc) => {
    // Check if the image already exists in the libraryImages state
    const imageExists = libraryImages.some(image => image.src === imgSrc);

    if (imageExists) {
      // If the image is already in the library, do not add it again
      toast.error('Image is already in the library, skipping addition to canvas.');
      return;
    }

    // Proceed to add the image to the canvas if it's not already present
    fabric.Image.fromURL(imgSrc, (img) => {
      // Get the original dimensions of the image
      const imgWidth = img.width;
      const imgHeight = img.height;

      // Define the maximum dimensions for the image on the canvas
      const maxWidth = 200;
      const maxHeight = 200;

      // Calculate scaling factors to maintain aspect ratio
      const scaleX = maxWidth / imgWidth;
      const scaleY = maxHeight / imgHeight;

      // Set the scale based on the smaller factor to maintain aspect ratio
      const scaleFactor = Math.min(scaleX, scaleY);

      // Create a unique id for the image to manage its state
      const imageId = `image_${new Date().getTime()}`;

      // Add the id property to the image object
      img.set({
        left: 100,
        top: 100,
        scaleX: scaleFactor,
        scaleY: scaleFactor,
        id: imageId, // Assigning a unique id to track it later
      });

      // Add the image to the canvas
      fabricCanvasRef.current.add(img);

      // Update the state with the new image's data, storing imgSrc and its id
      setLibraryImages((prevImages) => [
        ...prevImages,
        { id: imageId, src: imgSrc }, // Store both id and the image URL
      ]);
    });
  };


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const confirm = () => {
    setLoadings(true)
    addToCart();
    navigate('/myCart');// Close modal
  };

  const [errors, setErrors] = useState({});

  const validateField = (name, value) => {
    const newErrors = { ...errors };

    switch (name) {
      case 'text':
        if (!value || value.length < 3) {
          newErrors.text = 'Text must be at least 3 characters long';
        } else {
          delete newErrors.text;
        }
        break;
      case 'phone':
        const phonePattern = /^[0-9]{10}$/;
        if (!phonePattern.test(value)) {
          newErrors.phone = 'Phone number must be 10 digits';
        } else {
          delete newErrors.phone;
        }
        break;
      case 'email':
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(value)) {
          newErrors.email = 'Enter a valid email address';
        } else {
          delete newErrors.email;
        }
        break;
      case 'url':
        const urlPattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/;
        if (!urlPattern.test(value)) {
          newErrors.url = 'Enter a valid URL';
        } else {
          delete newErrors.url;
        }
        break;
      // Add cases for other fields (visiting card, etc.)
      default:
        break;
    }

    setErrors(newErrors);
  };

  const renderInputField = () => {
    switch (selectedOption) {
      case 'Text':
        return (
          <>
            <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Text and click the ‘add’ button.</label>
            <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="text" value={inputValue.text || ''} onChange={handleInputChange} placeholder="Enter text" />
          </>
        );
      case 'Dial a Number':
        return (
          <>
            <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Phone Number and click the ‘add’ button.</label>
            <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="tel" name="phone" value={inputValue.phone || ''} onChange={handleInputChange} placeholder="Enter phone number" />
          </>
        );
      case 'Open a Link':
        return (
          <>
            <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid URL and click the ‘add’ button.</label>
            <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="url" name="url" value={inputValue.url || ''} onChange={handleInputChange} placeholder="Enter URL" />
          </>
        );
      case 'Send an Email':
        return (
          <>
            <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Email and click the ‘add’ button.</label>
            <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="email" name="email" value={inputValue.email || ''} onChange={handleInputChange} placeholder="Enter email" />
          </>
        );
      case 'Visiting Card':
        return (
          <>
            <div className='d-block'>
              <div>
                <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px', color: '#666666' }}>Enter a valid Visiting Details and click the ‘add’ button.</label>
              </div>
              <div>
                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter First Name</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="firstName" value={inputValue.firstName || ''} onChange={handleInputChange} placeholder="First Name" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Last Name</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="lastName" value={inputValue.lastName || ''} onChange={handleInputChange} placeholder="Last Name" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Company</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="company" value={inputValue.company || ''} onChange={handleInputChange} placeholder="Company" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Position</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="position" value={inputValue.position || ''} onChange={handleInputChange} placeholder="Position" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Phone Number</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="tel" name="phoneNumber" value={inputValue.phoneNumber || ''} onChange={handleInputChange} placeholder="Phone Number" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Email</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="email" name="email" value={inputValue.email || ''} onChange={handleInputChange} placeholder="Email Address" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Website</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="url" name="website" value={inputValue.website || ''} onChange={handleInputChange} placeholder="Website" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter Address</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="address" value={inputValue.address || ''} onChange={handleInputChange} placeholder="Address" />
                </div>

                <div className='mt-2'>
                  <label style={{ fontSize: '17px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}> Enter QR Label</label>
                  <input style={{ height: '50px', width: '100%', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '20px' }} type="text" name="label" value={inputValue.label || ''} onChange={handleInputChange} placeholder="Label for QR" />
                </div>
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const calculateSizeQuantities = (sizeQuantities) => {
    const sizeCounts = {};

    sizeQuantities.forEach(({ size }) => {
      sizeCounts[size] = (sizeCounts[size] || 0) + 1;
    });

    return sizeCounts;
  };

  // const handleManualQuantityChange = (size, value) => {
  //   setQuantities((prevQuantities) => ({
  //     ...prevQuantities,
  //     [size]: value ? parseInt(value, 10) : 0,
  //   }));
  // };

  const handleManualQuantityChange = (size, value) => {
    const quantity = Number(value) || 0; // Convert to number or default to 0

    // Update the sizeQuantities array
    setSizeQuantities1(prevSizeQuantities => {
      const existingEntry = prevSizeQuantities.find(entry => entry.size === size);
      if (existingEntry) {
        // Update existing entry
        return prevSizeQuantities.map(entry =>
          entry.size === size ? { ...entry, quantity } : entry
        );
      } else {
        // If not found, add new entry
        return [...prevSizeQuantities, { size, quantity }];
      }
    });
  };

  // Use this function to calculate once when sizeQuantities is updated
  useEffect(() => {
    const calculatedQuantities = calculateSizeQuantities(sizeQuantities);
    setQuantities(calculatedQuantities);
  }, [sizeQuantities]);

  useEffect(() => {
    if (addNameSize && selectedImageIndex1 !== null) {
      const selectedImages = selectedProduct?.customizeImages
        ?.filter((_, colorIndex) => colorIndex === selectedColorIndex)
        ?.flatMap(color => color.images);

      if (selectedImages && selectedImages[selectedImageIndex1]) {
        const selectedImage = selectedImages[selectedImageIndex1];
        setMainImage(selectedImage.image);
        setSelectedImageIndex(selectedImageIndex1);
      }
    }
  }, [addNameSize, selectedImageIndex1, selectedColorIndex, selectedProduct]);

  return (
    <div style={{ backgroundColor: '#F8F7FA', height: '100vh' }} >
      {/* Customize Product Header  */}
      <div className='customize-header'>
        <div className='d-flex align-items-center'>
          {/* <span className='d-flex align-items-center'><GiHamburgerMenu style={{ width: '30px', height: '30px' }} /></span> */}
          {/* <h1 style={{ cursor: 'pointer' }} className='pb-3 m-0 me-3' onClick={handleNavigateBack}><ArrowLeft /></h1> */}
          {/* <Link to='/'>
            <img src={logo} height={45} alt="logo" />
          </Link> */}
          <Link to='/'>
            <img src={logo} className='mt-3 official-logo' alt="logo" />
          </Link>
        </div>
        <div>
          <h3 className='heading-product'>{selectedProduct?.name}</h3>
          {/* <div className='customize-question'>
            <span><FaQuestionCircle /></span>
          </div> */}
        </div>

        <div className='d-flex align-items-center mt-2 mt-md-0 s'>
          {/* <div style={{ border: 'none' }} className='customize-reset d-flex'>
            <span>Preview<LuEye style={{ height: '27px', width: '27px' }} color='#8C8C8C' className='ms-1' /></span>
          </div> */}
          {/* <button onClick={handleUndo} disabled={currentHistoryIndex <= 0}>Undo</button>
          <button onClick={handleRedo} disabled={currentHistoryIndex >= canvasHistory.length - 1}>Redo</button> */}
          {/*<div>
            <button style={{
              backgroundColor: undoStack.length === 0 ? '#ccc' : 'white',
              Color: undoStack.length === 0 ? 'white' : '#E45D25',
              cursor: undoStack.length === 0 ? 'not-allowed' : 'pointer',
              borderRadius: '10px'
            }} onClick={handleUndo} disabled={!undoStack[selectedImageIndex]?.length}>
              <LuUndo /> Undo
            </button>
            <button style={{
              backgroundColor: redoStack.length === 0 ? '#ccc' : 'white',
              Color: redoStack.length === 0 ? 'white' : '#E45D25',
              cursor: redoStack.length === 0 ? 'not-allowed' : 'pointer',
              borderRadius: '10px'
            }} className='ms-2' onClick={handleRedo} disabled={!redoStack[selectedImageIndex]?.length}>
              Redo <LuRedo />
            </button>
          </div>*/}
          <div style={{ border: 'none', cursor: 'pointer' }} className='customize-reset d-flex ms-3' onClick={resetForm}>
            <span><AiOutlineSync style={{ height: '27px', width: '27px' }} color='#EA5455' className='ms-1' /></span>
          </div>
          {loader ? (
            <Spinner />
          ) : (
            <div>
              <button
                style={{
                  backgroundColor: loader || canvasData.length === 0 ? '#ccc' : '#E45D25', // Enable when canvasData has length > 0
                  cursor: loader || canvasData.length === 0 ? 'not-allowed' : 'pointer',   // Set the cursor accordingly
                }}
                className='ms-3 btn-save-customize'
                disabled={loader || canvasData.length === 0} // Disable if canvasData is empty or if loader is true
                onClick={handleSaveAndProceed}
              >
                Proceed <ShoppingCart size={22} />
              </button>
            </div>

          )}
          {/* {loader ? (
            <Spinner />
          ) : (
            <button style={{ boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.5)" }} className='ms-3 btn-save-customize' disabled={loader || canvasData.length !== CardImg.length} onClick={handleSaveAndProceed}>
              Save design & Proceed <ArrowRight />
            </button>
          )} */}
        </div>
      </div>

      <div className='d-flex' style={{ paddingLeft: '2%', paddingTop: '1%', height: '90vh' }}>
        <Col sm={6} md={1} lg={1}>
          <div style={{ border: '1px solid #EEEEEE', height: '100%', padding: '3%', overflowY: 'auto', maxHeight: '88vh' }} className='d-block design-bar'>
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isProduct ? 'additional-class-name' : ''}`} onClick={handleParaClick}>
              <span><LuShirt className={`logo-icon mt-2 ${isProduct ? 'logo-icon1' : ''}`} size={30} /></span>
              <p className='mt-2 icon' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Product</p>
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isDesign ? 'additional-class-name' : ''}`} onClick={handleDesignClick}>
              <span><IoShapesOutline style={{ height: '30', width: '30' }} className={`logo-icon mt-2 ${isDesign ? 'logo-icon1' : ''}`} /></span>
              <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Clip Art</span>
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isText ? 'additional-class-name' : ''}`} onClick={handleTextClick}>
              <span><RxText className={`logo-icon mt-2 ${isText ? 'logo-icon1' : ''}`} size={30} /></span>
              <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Add Text</span>
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isImage ? 'additional-class-name' : ''}`} onClick={handleImageClick}>
              <span><BiImageAdd className={`logo-icon mt-2 ${isImage ? 'logo-icon1' : ''}`} size={30} /></span>
              <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Library</span>
            </div>
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isUpload ? 'additional-class-name' : ''}`} onClick={handleUploadClick}>
              <span><PiUploadLight className={`logo-icon mt-2 ${isUpload ? 'logo-icon1' : ''}`} size={30} /></span>
              <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Upload</span>
            </div>
            {selectedProduct?.productCategory?.customizedPrinting &&
              <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isNameNum ? 'additional-class-name' : ''}`} onClick={handleNameClick}>
                <span><FaRegCommentDots className={`logo-icon mt-2 ${isNameNum ? 'logo-icon1' : ''}`} size={30} /></span>
                <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Names & Numbers</span>
              </div>
            }
            <div className={`d-flex flex-column justify-content-center align-items-center design-icon ${isQr ? 'additional-class-name' : ''}`} onClick={handleQrClick}>
              <span><BsQrCode className={`logo-icon mt-2 ${isQr ? 'logo-icon1' : ''}`} size={30} /></span>
              <span className='mt-2' style={{ font: 'Lato', fontWeight: '500', fontSize: '20px', lineHeight: '24px' }}>Qr code</span>
            </div>
          </div>
        </Col>
        <Col sm={6} lg={4} md={4}>
          {/*Product Tab */}
          {isProduct && (
            <div style={{ paddingLeft: '20px' }}>
              <div
                style={{
                  padding: '20px',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  border: '1px solid #EEEEEE',
                  minHeight: '88vh',
                  maxHeight: '88vh', // Set a maximum height
                  overflowY: 'auto', // Enable vertical scrolling
                }}
                className='d-block'
              >
                <div className='d-flex justify-content-between '>
                  <h3
                    style={{
                      font: 'Lato',
                      fontWeight: '500',
                      fontSize: '20px',
                      color: '#2B2B2B',
                    }}
                    className=''
                  >
                    {selectedProduct?.name}
                  </h3>
                  <a
                    className='d-flex justify-content-end'
                    style={{ color: '#E45D25', cursor: 'pointer' }}
                    onClick={navToProduct}
                  >
                    Change
                  </a>
                </div>
                <div>
                  <img style={{ width: '100%' }} src={line} alt="" />
                </div>
                {loading ? (
                  <div
                    style={{
                      height: "80vh",
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Spinner color="primary" size="">
                      Loading...
                    </Spinner>
                  </div>
                ) : (
                  <Col lg={12}>
                    <div className='d-flex mb-1'>
                      <div style={{ width: '100%' }}>
                        <Row>
                          {selectedProduct?.fieldType?.map((eachItem, i) => (
                            <Col sm={12} lg={6}>
                              <React.Fragment key={i}>
                                <div className='d-lg-block ms-1'>
                                  <Col sm={8}>
                                    <h4
                                      className='m-0 mt-2'
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        font: 'Lato',
                                        color: '#262525',
                                      }}
                                    >
                                      {eachItem?.name?.name}
                                    </h4>
                                  </Col>
                                  <Col
                                    sm={8}
                                    className="position-relative w-100"
                                  >
                                    <select
                                      style={{ backgroundColor: '#EFEFEF', width: '100%' }}
                                      name={eachItem?.name?._id}
                                      defaultValue={option?.[i]?.option || ""}
                                      className='form-control mt-1 pr-6'
                                      onChange={(e) =>
                                        handleOptionChange(
                                          eachItem?.name?._id,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {eachItem?.options?.map((each, k) => (
                                        <option key={k} value={each?._id}>
                                          {each?.name}
                                        </option>
                                      ))}
                                    </select>
                                    <i className=" dropicon bi bi-caret-down position-absolute translate-middle-y">
                                      <ChevronDown />
                                    </i>
                                  </Col>
                                </div>
                              </React.Fragment>
                            </Col>
                          ))}
                          {!selectedProduct?.productCategory?.customizedPrinting &&
                            <>
                              <Col lg={6}>
                                <div className='ms-1'>
                                  <Col sm={4}>
                                    <h4
                                      className='m-0 mt-2'
                                      style={{
                                        fontWeight: '400',
                                        fontSize: '16px',
                                        font: 'Lato',
                                        color: '#262525',
                                      }}
                                    >
                                      Quantity
                                    </h4>
                                  </Col>
                                  <Col className='w-100' sm={8}>
                                    <div>
                                      {selectedProduct?.quantityType === 'dropdown' ? (
                                        <select
                                          name='quantityCounter'
                                          className='form-control mt-1 mt-md-1'
                                          onChange={handleQuantityChange}
                                        >

                                          {quantityCount?.map((eachQuantity, i) => (
                                            <option key={i} value={eachQuantity?.to}>
                                              {eachQuantity?.to}
                                              {eachQuantity?.type !== 'general' && (
                                                <span className='ms-4'> ({typeSpellings[eachQuantity?.type] || eachQuantity?.type})</span>
                                              )}
                                            </option>
                                          ))}

                                        </select>
                                      ) : (
                                        <div>
                                          <input
                                            type="number"
                                            name='quantity'
                                            style={{ border: '1px solid #D9D9D9' }}
                                            className='form-control mt-1 mt-md-1'
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                            pattern="[0-9]+"
                                            min="1" // Set minimum quantity
                                          />
                                          {quantityError && <span className="text-danger">{quantityError}</span>}
                                        </div>

                                        // <div>
                                        //   <input
                                        //     type="number"
                                        //     name='quantity'
                                        //     style={{ border: '1px solid #D9D9D9' }}
                                        //     className='form-control mt-1 mt-md-3'
                                        //     value={quantity}
                                        //     onChange={handleQuantityInput}
                                        //     required // Makes sure input is mandatory
                                        //   />
                                        //   {quantityError && <span className="text-danger">{quantityError}</span>}
                                        // </div>
                                      )}
                                    </div>
                                  </Col>
                                </div>
                              </Col>
                            </>
                          }
                        </Row>
                      </div>
                    </div>
                  </Col>
                )}
                <div>
                  <img style={{ width: '100%' }} src={line} alt="" />
                </div>
                {/*Custom image color*/}
                {selectedProduct?.customizeImages?.length > 1 &&
                  <div>
                    <div className="color-picker">
                      <label
                        style={{
                          font: 'Lato',
                          fontWeight: '400',
                          fontSize: '16px',
                        }}
                        htmlFor="color"
                      >
                        Color
                      </label>
                      <div className='d-flex'>
                        {selectedProduct?.customizeImages?.map((color, index) => (
                          <div
                            key={index}
                            className="color-swatch"
                            style={{ backgroundColor: color.colourCode }}
                            onClick={() => handleColorSelect(index)}
                          ></div>
                        ))}
                      </div>

                    </div>
                  </div>
                }


                {/* <div>
                  {showDelBtn && (
                    <div>
                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          )
          }
          {/*ClipArt Tab */}
          {isDesign && (
            <div style={{ paddingLeft: '20px' }}>
              <div
                style={{
                  padding: '20px',
                  height: '88vh',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  border: '1px solid #EEEEEE',
                  maxHeight: '90vh', // Set a maximum height
                  overflowY: 'auto', // Enable vertical scrolling
                }}
                className='d-block'
              >
                <div>
                  <div className='position-relative'>
                    <input style={{ width: '100%', height: '50px', color: '#BFBFBF', fontSize: '16px', fontWeight: '400', font: 'Lato', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '60px' }} type="text" name='search' placeholder='Search all Design' />
                    <CiSearch className='mt-1' style={{ width: '40px', height: '40px', color: '#BFBFBF', position: 'absolute', left: '5px' }} />
                  </div>
                </div>
                <div className='d-Block'>
                  <div className='d-flex justify-content-between'>
                    <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>Recent Upload Images</p>
                  </div>
                  <div className='d-flex flex-wrap'> {/* Added flex-wrap to allow wrapping */}
                    {clipArtImages?.map((image, index) => (
                      <div className='mt-2 ms-1' key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
                        <img
                          alt={`Uploaded ${index}`} // Improved alt text for accessibility
                          src={image?.src} // Assuming image has imgSrc as a property
                          style={{ width: '100px', objectFit: 'cover', marginRight: '10px' }}
                        />
                        <div
                          className='image-remove'
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '-15px',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the image click
                            deleteImage2(image?.id); // Call the delete function, passing the index
                          }}
                        >
                          <X /> {/* Replace X with your actual delete icon/component */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {clipArtData?.map((item, i) => (
                  <>
                    <div className='mt-2'>
                      <img style={{ width: '100%' }} src={line} alt="" />
                    </div>
                    <div className='d-block'>
                      <div className='d-flex justify-content-between'>
                        <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>{item?.name}</p>
                      </div>
                      <div style={{ marginTop: '-20px' }} className='d-flex row'>
                        {item?.images?.map((design) => (
                          <div
                            key={design?._id}
                            className='d-flex align-items-center justify-content-center ms-3 mt-3'
                            style={{ width: '70px', height: '70px' }}
                          >
                            <img
                              style={{ width: '60px', height: '60px', borderRadius: '50%', cursor: 'pointer' }}
                              src={design?.img_url}
                              alt={design?.name}
                              onClick={() => addImageToCanvas(design?.img_url)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ))}
                {/* <div>
                  {showDelBtn && (
                    <div>
                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          )}
          {/*Add Text Tab */}
          {isText && (<div style={{ paddingLeft: '20px' }}>
            <div
              style={{
                padding: '20px',
                height: '88vh',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                border: '1px solid #EEEEEE',
                maxHeight: '90vh', // Set a maximum height
                overflowY: 'auto', // Enable vertical scrolling
              }}
              className='d-block'
            >
              <div>
                <h4>Text Area</h4>
              </div>
              <div>
                <img style={{ width: '100%' }} src={line} alt="" />
              </div>
              {texts.map((item, index) => (
                <Row key={index}>
                  <div className='d-flex'>
                    <Col sm={12} lg={12} md={12}>
                      <div
                        className="text-card justify-content-between mt-2"
                        style={{
                          border: selectedTextId === item.id ? '2px solid green' : '1px solid #ccc', // Highlight selected card
                        }}
                      >
                        <div>
                          <div><strong>Title:</strong> {item.text}</div>
                          <div><strong>Font Size:</strong> {item.fontSize}</div>
                          <div><strong>Font Family:</strong> {item.fontFamily}</div>
                          <div>
                            <strong>Color:</strong>
                            <div
                              style={{
                                width: '20px',
                                height: '20px',
                                backgroundColor: item.textColor,
                                borderRadius: '50%',
                                display: 'inline-block',
                                marginLeft: '10px',
                                marginBottom: '-5px'
                              }}
                            ></div>
                          </div>
                        </div>
                        <div>
                          <button
                            className="btn btn-danger ms-3 d-flex justify-content-center align-items-center"
                            onClick={(e) => deleteText(e, item.id)}
                          >
                            <FaRegTrashCan />
                          </button>
                          <button
                            className="btn btn-primary mt-2 ms-3 d-flex justify-content-center align-items-center"
                            onClick={() => selectTextForEdit(item.id)}
                          >
                            <CiEdit />
                          </button>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>
              ))}

              <div className='mt-1' style={{ borderRadius: '10px' }}>
                <div style={{ padding: '5px 10px 5px 10px' }}>
                  <div className='mb-3 mt-3 d-flex flex-column position-relative'>
                    <label htmlFor="text" className='select-customize-label'>Title:</label>
                    <input style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} type="text" id="text" className='customize-select' placeholder='Enter Text Here' value={textOptions.text} onChange={handleTextChange} />
                  </div>
                  <div className='mb-3'>
                    <label htmlFor="fontSize" className='select-customize-label'>Font Size:</label>
                    <input style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} type="number" id="fontSize" className='customize-select' placeholder='Enter Font Size ' value={textOptions.fontSize} onChange={handleFontSizeChange} />
                  </div>
                  <div className='mb-2'>
                    <label htmlFor="fontFamily" className='select-customize-label mt-1 mb-1'>Font Style:</label>
                    <select style={{ padding: '10px', borderRadius: '5px', boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)", border: '1px' }} id="fontFamily" className='customize-select mt-1 mb-1' value={textOptions.fontFamily} onChange={handleFontFamilyChange}>
                      <option value="Arial">Arial</option>
                      <option value="Helvetica">Helvetica</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Palatino">Palatino</option>
                      <option value="Garamond">Garamond</option>
                      <option value="Bookman">Bookman</option>
                      <option value="Comic Sans MS">Comic Sans MS</option>
                      <option value="Varsity">Varsity</option>
                    </select>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor="textColor" className='select-customize-label'>Text Color:</label>
                    <input style={{ padding: '5px', borderRadius: '5px' }} type="color" id="textColor" className='customize-select' value={textOptions.textColor} onChange={handleTextColorChange} />
                  </div>
                  <div className="mb-3">
                    {!isEditing ? (
                      <button
                        style={{ background: '#E4510B' }}
                        className="btn btn-secondary"
                        onClick={addText}
                      >
                        Add Text
                      </button>
                    ) : (
                      <button
                        style={{ background: '#E4510B' }}
                        className="btn btn-secondary"
                        onClick={updateText}
                      >
                        Save Changes
                      </button>
                    )}
                  </div>
                  {/* <div className='position-absolute'>
                      {showDelBtn && (
                        <div>
                          <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                          <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                        </div>
                      )}
                    </div> */}
                </div>
              </div>
            </div>
          </div>)}
          {/*Library Tab */}
          {isImage && (
            <div style={{ paddingLeft: '20px' }}>
              <div
                style={{
                  padding: '20px',
                  height: '88vh',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  border: '1px solid #EEEEEE',
                  maxHeight: '90vh', // Set a maximum height
                  overflowY: 'auto', // Enable vertical scrolling
                }}
                className='d-block'
              >
                {/* <div>
                  <h4>Image Area <span style={{ color: '#E45D25' }}> (under development)</span></h4>
                </div>
                <div>
                  <img style={{ width: '100%' }} src={line} alt="" />
                </div> */}

                <div>
                  <div className='position-relative'>
                    <input style={{ width: '100%', height: '50px', color: '#BFBFBF', fontSize: '16px', fontWeight: '400', font: 'Lato', border: '1px solid #B0B0B0', borderRadius: '5px', paddingLeft: '60px' }} type="text" name='search' placeholder='Search images, Vetors & Patterns' />
                    <CiSearch className='mt-1' style={{ width: '40px', height: '40px', color: '#BFBFBF', position: 'absolute', left: '5px' }} />
                  </div>
                </div>
                <div className='d-Block'>
                  <div className='d-flex justify-content-between'>
                    <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>Recent Upload Images</p>
                  </div>
                  <div className='d-flex flex-wrap'> {/* Added flex-wrap to allow wrapping */}
                    {libraryImages?.map((image, index) => (
                      <div className='mt-2 ms-3' key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', position: 'relative' }}>
                        <img
                          alt={`Uploaded ${index}`} // Improved alt text for accessibility
                          src={image?.src} // Assuming image has imgSrc as a property
                          style={{ width: '100px', objectFit: 'cover', marginRight: '10px' }}
                        />
                        <div
                          className='image-remove'
                          style={{
                            position: 'absolute',
                            top: '0px',
                            right: '-15px',
                            cursor: 'pointer',
                            color: 'red',
                          }}
                          onClick={(e) => deleteImage1(image.id)}
                        >
                          <X /> {/* Replace X with your actual delete icon/component */}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {libraryData?.map((item, i) => (
                  <>
                    <div className='mt-2'>
                      <img style={{ width: '100%' }} src={line} alt="" />
                    </div>
                    <div className='d-block'>
                      <div className='d-flex justify-content-between'>
                        <p style={{ fontSize: '16px', fontWeight: '400', color: '#000000' }}>{item?.name}</p>
                      </div>
                      <div style={{ marginTop: '-20px' }} className='d-flex flex-wrap gap-4 mt-2'>
                        {item?.images?.map((design) => (
                          <img
                            style={{ height: '80px', cursor: 'pointer' }}
                            src={design?.img_url}
                            alt={design?.name}
                            onClick={() => addImageToCanvas1(design?.img_url)}
                          />
                        ))}
                      </div>
                    </div>
                  </>
                ))}
                {/* <div>
                  {showDelBtn && (
                    <div>
                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                    </div>
                  )}
                </div> */}
              </div>

            </div>
          )}
          {/*Add Name and Number Tab */}
          {isNameNum && (
            <div style={{ paddingLeft: '20px' }}>
              <div
                style={{
                  padding: '20px',
                  height: '88vh',
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  border: '1px solid #EEEEEE',
                  maxHeight: '90vh', // Set a maximum height
                  overflowY: 'auto', // Enable vertical scrolling
                }}
                className='d-block'
              >
                <div>
                  {continueAdd &&
                    <div>
                      <h3>Add Names and Numbers</h3>
                      <p className='pb-3' style={{ borderBottom: '1px solid #cccfd6' }}>Use personalized names and numbers for projects like team jerseys, where each item requires a unique name and/or number. </p>
                      <img width='100%' height='300px' src={namenumber} alt="" />
                      <hr style={{ borderColor: '#cccfd6', borderWidth: '1px', borderStyle: 'solid' }} />
                      <button
                        style={{ background: '#E4510B' }}
                        className="btn btn-secondary"
                        onClick={() => {
                          setContinueAdd(false); // Sets `continueAdd` to false when clicked
                          setAddNameSize(true);  // Sets `addNameSize` to true when clicked
                        }}
                      >
                        Continue to Add
                      </button>
                    </div>
                  }
                  {addNameSize &&
                    <div>
                      <div>
                        {sizeQuantities?.length > 0 &&
                          <div className='mt-2 mb-2'>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>S. No</th>
                                  <th>Name</th>
                                  <th>Number</th>
                                  <th>Size</th>
                                </tr>
                              </thead>
                              <tbody>
                                {sizeQuantities.map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name || 'N/A'}</td>
                                    <td>{item.number || 'N/A'}</td>
                                    <td>{item.size || 'N/A'}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        }
                      </div>
                      <div className='d-flex justify-content-between'>
                        <h3>Names & Numbers Tools</h3>
                        <IoMdClose size={25} onClick={() => {
                          setContinueAdd(true); // Sets `continueAdd` to false when clicked
                          setAddNameSize(false);  // Sets `addNameSize` to true when clicked
                        }} />
                      </div>
                      <div className='d-flex justify-content-between mt-3'>
                        <div>
                          <label>Step 1</label>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <label className='d-flex'>
                            <input
                              type="checkbox"
                              style={{ width: '20px', height: '20px' }}
                              checked={addName}
                              onChange={() => handleCheckboxChange1('nameAndNumber')}
                            />
                            <p className='ms-2 d-flex align-items-center justify-content-center'>Add Name</p>
                          </label>
                          <label className='d-flex ms-3'>
                            <input
                              type="checkbox"
                              style={{ width: '20px', height: '20px' }}
                              checked={addNumber}
                              onChange={() => handleCheckboxChange1('size')}
                            />
                            <p className='ms-2 d-flex align-items-center justify-content-center'>Add Number</p>
                          </label>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mt-1'>
                        <div className='d-flex align-items-center'>
                          <label>Side</label>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          {selectedProduct?.customizeImages
                            ?.filter((_, colorIndex) => colorIndex === selectedColorIndex)
                            ?.flatMap(color => color.images)
                            ?.slice(0, 2)
                            ?.map((imageData, index) => (
                              <div
                                key={index}
                                className='image-container mt-2'
                                style={{
                                  height: 'auto',
                                  cursor: 'pointer',
                                  border: selectedImageIndex1 === index ? '2px solid #E4510B' : '1px solid #d3d3d3',
                                  padding: '10px 20px',
                                  textAlign: 'center',
                                  fontFamily: 'Arial, sans-serif',
                                  fontWeight: 'bold',
                                  color: selectedImageIndex1 === index ? '#E4510B' : 'black',
                                  borderRadius: '4px',
                                  display: 'inline-block',
                                  marginRight: '10px'
                                }}
                                onClick={() => handleSideChange(index)}
                              >
                                <p className='d-flex justify-content-center m-0'>{imageData?.name}</p>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mt-1'>
                        <div className='d-flex align-items-center'>
                          <label>Font</label>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <div className='mb-2'>
                            <select
                              style={{
                                paddingRight: '10px',
                                paddingLeft: '10px',
                                width: '250px',
                                borderRadius: '5px',
                                border: '1px solid black', // Fixed typo from "soild" to "solid"
                              }}
                              id="fontFamily"
                              className='customize-select mt-1 mb-1'
                              value={textOptions1.fontFamily}
                              onChange={handleFontFamilyChange1}
                            >
                              <option value="Impact" style={{ fontFamily: 'Impact' }}>Impact (Bold & Strong)</option>
                              <option value="Bebas Neue" style={{ fontFamily: '"Bebas Neue", sans-serif' }}>Bebas Neue (Bold Block)</option>
                              <option value="Futura" style={{ fontFamily: 'Futura, sans-serif' }}>Futura (Retro)</option>
                              <option value="Helvetica" style={{ fontFamily: 'Helvetica, sans-serif' }}>Helvetica (Minimalistic)</option>
                              <option value="Rockwell" style={{ fontFamily: 'Rockwell, serif' }}>Rockwell (Vintage)</option>
                              <option value="Streetbrush" style={{ fontFamily: '"Streetbrush", cursive' }}>Streetbrush (Graffiti)</option>
                              <option value="Teko" style={{ fontFamily: 'Teko, sans-serif' }}>Teko (Modern 3D)</option>
                              <option value="Pacifico" style={{ fontFamily: 'Pacifico, cursive' }}>Pacifico (Cursive)</option>
                              <option value="Varsity" style={{ fontFamily: 'Varsity, sans-serif' }}>Varsity (Collegiate)</option>
                              <option value="Neon 80s" style={{ fontFamily: '"Neon 80s", sans-serif' }}>Neon 80s (Glow)</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-between mt-1'>
                        <div className='d-flex align-items-center'>
                          <label>Color</label>
                        </div>
                        <div className='d-flex justify-content-between mt-2'>
                          <div className='mb-3'>
                            <input style={{ padding: '5px', borderRadius: '5px', width: '250px' }} type="color" id="textColor" className='customize-select' value={textOptions1.textColor} onChange={handleColorChange} />
                          </div>
                        </div>
                      </div>
                      {/* {addName &&
                          <>
                            <div>
                              <button
                                style={{ background: '#E4510B' }}
                                className="btn btn-secondary"
                                onClick={() => setModalName(true)} // Wrap in an arrow function
                              >
                                Enter Name
                              </button>
                            </div>
                          </>
                        }
                        {addNumber &&
                          <div>
                            <button
                              style={{ background: '#E4510B' }}
                              className="btn btn-secondary"
                              onClick={() => setModalName(true)}
                            >
                              Enter Name/Number
                            </button>
                          </div>
                        } */}

                      {(addName || addNumber) && (
                        <div className='d-flex justify-content-end mt-2'>
                          <button
                            style={{ background: '#E4510B' }}
                            className="btn btn-secondary"
                            onClick={() => {
                              setModalName(true);
                              // Set modalMode based on the selected checkboxes
                              if (addName && addNumber) {
                                setModalMode('nameAndNumber');
                              } else if (addName) {
                                setModalMode('name');
                              } else if (addNumber) {
                                setModalMode('number');
                              }
                            }}
                          >
                            {addName && addNumber ? 'Enter Name/Number' : addName ? 'Enter Name' : 'Enter Number'}
                          </button>
                        </div>
                      )}
                    </div>
                  }
                </div>
                {/* )} */}
                {/* <div>
                  {showDelBtn && (
                    <div>
                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          )}
          {/*Upload image Tab */}
          {isUpload && (<div style={{ paddingLeft: '20px' }}>
            <div
              style={{
                padding: '20px',
                height: '88vh',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                border: '1px solid #EEEEEE',
                maxHeight: '90vh', // Set a maximum height
                overflowY: 'auto', // Enable vertical scrolling
              }}
              className='d-block'
            >
              <div>
                <h4>Upload your files</h4>
              </div>
              <div>
                <img style={{ width: '100%' }} src={line} alt="" />
              </div>
              <Row>
                <Col lg={4}>
                  <div className='d-flex'>
                    {uploadedImages.map((imageUrl, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          marginBottom: '10px'
                        }}
                      >
                        <img
                          src={imageUrl?.url}
                          alt="Uploaded"
                          style={{
                            width: '100px',
                            height: '100px',
                            objectFit: 'cover',
                            marginRight: '10px',
                            pointerEvents: 'none' // Disable interactions
                          }}
                          onContextMenu={(e) => e.preventDefault()} // Prevent context menu on right-click
                        />
                        {loadingImageIndex === index ? (
                          <div className="loader">
                            <Spinner>Loading...</Spinner>
                          </div>
                        ) : (
                          <button
                            onClick={() => handleRemoveBackground(imageUrl?.url, imageUrl?.id, index)}
                            style={{
                              marginTop: '0px',
                              cursor: 'pointer',
                              color: '#E4510B',
                              textDecoration: 'underline',
                              border: 'none',
                              background: 'transparent'
                            }}
                          >
                            Remove BG
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
              <div>
                <div className='mt-1'>
                  <p style={{ font: 'Lato', fontWeight: '400', color: '#858585', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Please Check the guidelines before uploading the files</p> <br />
                  <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-20px' }}>Supported file formats : PNG, JPG, JEG, JFIF </p>
                  <p style={{ font: 'Lato', fontWeight: '400', fontSize: '16px', color: '#646464', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>(Maximum 60 MB /Minimum 224px x 224px)</p>
                </div>
                <div>
                  <Card className='' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', border: '2px dashed #E4510B', borderRadius: '4px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                      <h1>
                        <RiImageAddFill style={{ color: '#8B8B8B', width: '80px', height: '80px' }} />
                      </h1>
                      <h5 className='mt-1' style={{ color: '#2B2B2D' }}>Select an image to upload on it</h5>
                    </div>
                    <div>
                      <div className='mb-4 ps-2 mt-2 upload' style={{ width: '200px', height: '30px', background: '#E4510B', borderRadius: '5px' }}>
                        <label htmlFor="tshirt-custompicture" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', cursor: 'pointer' }}>
                          <span className='ms-2' style={{ fontFamily: 'Lato, sans-serif', fontWeight: 'bold', color: 'white' }}>Select Image</span>
                          <input
                            type="file"
                            id="tshirt-custompicture"
                            style={{ display: 'none' }}
                            onChange={handleFileUpload}
                          />
                        </label>
                      </div>
                    </div>
                  </Card>
                </div>
                {/* <div className='mt-2'>
                  {showDelBtn && (
                    <div>
                      <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                      <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                    </div>
                  )}
                </div> */}
              </div>
            </div>
          </div>)}
          {/*QR Code Tab */}
          {isQr && (<div style={{ paddingLeft: '20px' }}>
            <div
              style={{
                padding: '20px',
                height: '88vh',
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                border: '1px solid #EEEEEE',
                maxHeight: '90vh', // Set a maximum height
                overflowY: 'auto', // Enable vertical scrolling
              }}
              className='d-block'
            >
              <div>
                <h4 style={{ color: '#000000', fontSize: '20px', fontWeight: '400', font: 'Lato', lineHeight: '24px' }}>Add QR Code </h4>
              </div>
              <div className='mt-4'>
                <h3 style={{ color: '#666666', fontSize: '18px', fontWeight: '400', font: 'Lato', lineHeight: '20px' }}>Choose an action the QR Code will trigger</h3>
                <div className='mt-3'>
                  <select className='ps-2' style={{ width: '100%', borderRadius: '5px', height: '50px', fontSize: '16px', border: '1px solid #B0B0B0', font: 'Lato', lineHeight: '20px', fontWeight: '400', color: '#2F2F2F' }} value={selectedOption} onChange={handleOptionChange1}>
                    <option value="">Select an option</option>
                    <option value="Text">Text</option>
                    <option value="Dial a Number">Dial a Number</option>
                    <option value="Open a Link">Open a Link</option>
                    <option value="Send an Email">Send an Email</option>
                    <option value="Visiting Card">Visiting Card</option>
                  </select>
                </div>
                <div className='mt-4'>
                  {renderInputField()}
                </div>
                <button className='mt-3' style={{ width: '100%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleSubmit}>Submit</button>

                {qrCodeValue && (
                  <div className='mt-4'>
                    <div className='d-flex justify-content-center'>
                      <QRCodeCanvas style={{ width: '100px', height: '100px' }} id="tshirt-custompicture" value={qrCodeValue} size={100} includeMargin={true} />
                    </div>
                    <div className='d-flex'>
                      <button className='mt-3' style={{ width: '50%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleDownload}>Download QR Code</button>
                      <button className='mt-3 ms-3' style={{ width: '50%', height: '50px', background: '#E4510B', color: 'white', borderRadius: '5px', border: 'none' }} onClick={handleCanvasSet}><IoMdAdd style={{ color: 'white' }} />Add QR Code</button>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <img style={{ width: '100%' }} src={line} alt="" />
              </div>
              <div>
                {showDelBtn && (
                  <div>
                    <p>To remove the selected item on image Press <kbd>DEL</kbd> button.</p>
                    <button className='btn btn-danger ms-2' onClick={deleteImage}>Delete</button>
                  </div>
                )}
              </div>
            </div>
          </div>)}
        </Col >
        <Col md={7} lg={7} sm={12}>
          <div style={{ paddingRight: '20px', paddingLeft: '20px', height: '90vh' }} className='d-block'>
            <div style={{ height: '88vh', background: '#FFFFFF', border: '1px solid #EEEEEE', borderRadius: '10px' }}>
              <div className='d-flex align-items-center justify-content-center ms-5 mt-3'>
                {selectedProduct?.productCategory?.customizedPrinting &&
                  <>
                    <p className='text-secondary text-center'>
                      <span className='text-danger'>Note:</span> Add Text or Image in the printable area. {/*<br />To remove the selected item on image Press <kbd>Delete</kbd>  Button or <kbd>Backspace</kbd> button*/}
                    </p>
                  </>
                }
              </div>
              <div className='left-container-img d-flex flex-row mt-3 pl-sm-5'>
                <div>
                  <div className='d-flex ms-5 position-relative'>
                    <div style={{ position: 'relative', overflow: 'hidden', width: '420px', height: 'auto', display: 'flex', justifyContent: 'center' }}>
                      <div style={{ width: '420px', height: 'auto', position: 'relative' }} id="tshirt-div">
                        <img
                          style={{ width: '420px', height: 'auto' }}
                          id="tshirt-backgroundpicture"
                          src={mainImage}
                          alt="T-shirt"
                        />
                        {selectedProduct?.productCategory?.customizedPrinting &&
                          <>

                            {/* Ruler Image */}
                            {showDelBtn && selectedImageIndex === 0 && (
                              <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
                                <img
                                  style={{ width: '420px', height: '450px' }}
                                  id="tshirt-ruller"
                                  src={ruller}
                                  alt="T-shirt Ruler"
                                />
                              </div>
                            )}
                            {showDelBtn && selectedImageIndex === 1 && (
                              <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}>
                                <img
                                  style={{ width: '420px', height: '450px' }}
                                  id="tshirt-ruller"
                                  src={backruller}
                                  alt="T-shirt Ruler"
                                />
                              </div>
                            )}
                          </>
                        }

                        {/* Canvas */}
                        <div
                          id="drawingArea"
                          style={{ position: 'absolute', top: 0, left: 0, width: '420px', height: 'auto' }}
                          className="drawing-area"
                        >
                          <div style={{ width: '420px', height: 'auto' }} className="canvas-container">
                            <canvas ref={canvasRef} id="tshirt-canvas" width='420px' height='500px' style={{ display: 'block' }}></canvas>
                          </div>
                        </div>
                        {showDelBtn &&
                          <div style={{ marginTop: '0px' }}>
                            <label>Zoom:</label>
                            <input
                              type="range"
                              min="20"    // Min zoom 50%
                              max="200"   // Max zoom 200%
                              value={scaleValue}
                              onChange={handleScaleChange}
                              style={{ width: '100%' }}
                            />
                          </div>
                        }
                      </div>
                      <div style={{ position: 'absolute', top: '30%', left: '50%', transform: 'translateX(-50%)', zIndex: '200', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {!canvasData[0] && (
                          <>
                            {selectedImageIndex === 0 && (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2  text-capitalize ' onClick={handleTextClick}><LuTextCursorInput size={15} /> ADD TEXT</button>
                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={handleUploadClick}><RiUploadCloud2Line size={15} /> UPLOAD ART</button>
                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={handleDesignClick}><RiImageAddLine size={15} /> ADD CLIPART</button>
                              </div>
                            )}
                          </>
                        )}
                        {!canvasData[1] && (
                          <>
                            {selectedImageIndex === 1 && (
                              <div className="d-flex flex-column justify-content-center align-items-center">
                                <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={handleUploadClick}><RiUploadCloud2Line size={15} /> UPLOAD ART</button>
                                {selectedProduct?.productCategory?.customizedPrinting &&
                                  <button style={{ borderRadius: '20px', border: '1px solid #e44324', fontSize: '12px' }} className='btn  btn-light mb-2 text-capitalize' onClick={handleNameClick}><FaRegCommentDots size={15} /> NAMES & NUMBERS</button>
                                }
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                </div>

                <div style={{ right: '0px' }} className='d-flex position-absolute pe-5 pt-2'>
                  <div className="vertical-images">
                    {selectedProduct?.customizeImages
                      ?.filter((_, colorIndex) => colorIndex === selectedColorIndex)
                      ?.flatMap(color => color.images)
                      ?.map((imageData, index) => (
                        <div
                          key={index}
                          className='image-container mt-2'
                          style={{
                            height: 'auto',
                            cursor: 'pointer'
                          }}
                        >
                          <img
                            src={imageData.image}
                            alt=""
                            style={{
                              width: '70px',
                              height: '70px',
                              borderRadius: '20px',
                              border: selectedImageIndex === index ? '2px solid #e44324' : 'none',
                            }}
                            onClick={() => handleImageClickWrapper(imageData.image, index)}
                          />
                          <p style={{ color: selectedImageIndex === index ? '#e44324' : 'black' }} className='d-flex justify-content-center'>{imageData?.name}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>

            </div>
            {/* <div style={{ backgroundColor: '#FFFFFF', border: '1px solid #EEEEEE', borderRadius: '10px', height: '10vh', paddingRight: '3%', paddingLeft: '3%', marginTop: '15px' }} className='d-flex justify-content-between align-items-center'>
              <div>
                <button style={{ color: '#E45D25', border: '1px solid #E45D25', background: 'transparent', borderRadius: '4px', height: '40px', width: '90px', fontWeight: '500' }} onClick={handleNavigateBack}><ArrowLeft style={{ color: '#E45D25' }} /> Back</button>
              </div>
              <div className='d-flex'>
                {totalAmount ? (
                  <div className='m-0  d-flex justify-content-center align-items-center' style={{ border: '1px solid #EEEEEE', paddingLeft: '10px', paddingRight: '10px', borderRadius: '4px', height: '45px' }}>
                    {selectedProduct?.offer ? (
                      <>
                        <div className='d-flex'>
                          <div className='d-block'>
                            <div className='d-flex'>
                              <span className='text-muted' style={{ textDecoration: 'line-through' }}>{actualAmount?.toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                              })}</span>
                              <span className='fw-bolder ms-2' style={{ color: 'green', fontSize: '18px' }}>{offerAmount?.toLocaleString('en-IN', {
                                maximumFractionDigits: 0,
                                style: 'currency',
                                currency: 'INR'
                              })}</span>
                            </div>
                            <div>
                              <span style={{ color: '#858585', fontSize: "14px", display: 'flex', marginTop: '0px' }} className=''>For {quantity1} Pieces</span>
                            </div>
                          </div>
                          <div className='d-flex align-items-center'>
                            <IoIosInformationCircle className='ms-2' size={20} style={{ color: '#9E9E9E' }} />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='d-block'>
                          <span className='fw-bolder' style={{ color: 'green', fontSize: '20px', lineHeight: '21px' }}>{actualAmount?.toLocaleString('en-IN', {
                            maximumFractionDigits: 0,
                            style: 'currency',
                            currency: 'INR'
                          })}</span>
                          <span style={{ color: '#858585', fontSize: "14px", display: 'flex', marginTop: '0px' }} className=''>For {quantity1} Pieces</span>
                        </div>
                        <IoIosInformationCircle className='ms-2' style={{ color: '#9E9E9E' }} />
                      </>
                    )}
                  </div>
                ) : (null)}
                {loader ? (
                  <Spinner />
                ) : (
                  <div>
                    <button
                      style={{
                        backgroundColor: loader || canvasData.length === 0 ? '#ccc' : '#E45D25', // Enable when canvasData has length > 0
                        cursor: loader || canvasData.length === 0 ? 'not-allowed' : 'pointer',   // Set the cursor accordingly
                      }}
                      className='ms-3 btn-save-customize'
                      disabled={loader || canvasData.length === 0} // Disable if canvasData is empty or if loader is true
                      onClick={handleSaveAndProceed}
                    >
                      Proceed <ShoppingCart size={22} />
                    </button>
                  </div>

                )}
              </div>
            </div> */}
          </div>
        </Col>
      </div >
      <NameNumberModal
        openModal={modalName}
        handleModal={toggleName}
        color={selectedColorIndex}
        values={productSize}
        name={selectedProduct}
        onSave={setSizeQuantities}
        modalMode={modalMode}
        fontStyle={textOptions1.fontFamily}
        textColor={textOptions1.textColor}
      />
      <FinalAuthModal isOpen={modal} toggle={toggle} isCart={true} />
      {/* <Preview1 modal={modal1} toggle={toggle1} cardImages={editedImage} /> */}
      <Modal size='xl' isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>Final Preview</ModalHeader>
        <Form>
          <ModalBody>
            <Row>
              <Col sm={12} lg={6}>
                {editedImage?.length > 1 ? (
                  <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    arrows={true}
                    autoPlaySpeed={2000}
                    infinite={true}
                  >
                    {editedImage.map((image, index) => (
                      <div key={index} style={{ display: 'flex', justifyContent: 'center' }}>
                        <img
                          className='mb-3'
                          style={{ width: '300px', height: '100%' }}
                          alt={`Design preview`}
                          src={image.url} // Use image.url for each image
                        />
                      </div>
                    ))}
                  </Carousel>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                      className='mb-3'
                      style={{ width: '300px', height: '300px' }}
                      alt="Design preview"
                      src={editedImage[0]?.url} // Use editedImage[0]?.url for single image
                    />
                  </div>
                )}
              </Col>
              <Col sm={12} lg={6}>
                <div style={{ position: 'relative' }}>
                  <div className='d-flex flex-column flex-lg-row justify-content-lg-between gap-3'>
                    <div>
                      <h1 className='fs-3'>{selectedProduct?.name}</h1>
                      <div className="d-flex gap-2">
                        <div
                          style={{
                            height: '20px',
                            width: '20px',
                            backgroundColor: selectedProduct?.customizeImages[selectedColorIndex]?.colourCode || 'white',
                            border: '1px solid grey',
                            borderRadius: '4px'
                          }}
                        ></div>
                        <p className='m-0 p-0'>{selectedProduct?.customizeImages[selectedColorIndex]?.colourCode}</p>
                      </div>
                    </div>
                    {sizeQuantities.length > 0 ? (
                      <h1 className='mt-2 fs-5 text-secondary'>
                        Total Quantity: <span className='text-success'>{totalQuantity}</span>
                      </h1>
                    ) : (
                      <h1 className='mt-2 fs-5 text-secondary'>
                        Total Quantity: <span className='text-success'>{manualQuantity}</span>
                      </h1>
                    )}

                  </div>
                  {selectedProduct?.customizeImageSize?.length > 0 ? (
                    <>
                      <div>
                        <img style={{ width: '100%' }} src={line} alt="" />
                      </div>
                      <div>
                        <h1 className='fs-5'>Size / Quantity</h1>
                        <div style={{ width: '100px' }} className="d-block">
                          <div className='d-flex'>
                            {productSize?.map((size) => (
                              <>
                                {sizeQuantities.length > 0 ? (
                                  <div className="d-block mt-2" key={size}>
                                    <p
                                      style={{ cursor: 'pointer' }}
                                      className={`ms-3 me-3 size d-flex justify-content-center align-items-center ${selectedSize1 === size ? 'selected-size' : ''
                                        }`}
                                    // onClick={() => handleSizeClick1(size)}
                                    >
                                      {size}
                                    </p>
                                    <div style={{ height: '100px' }}>
                                      <input
                                        type="text"
                                        value={sizeQuantities.length > 0 ? (quantities[size] > 0 ? quantities[size] : '-') : quantities[size] || ''}
                                        readOnly={sizeQuantities.length > 0}
                                        // onChange={(e) => handleManualQuantityChange(size, e.target.value)}
                                        style={{ width: '50px', height: '50px', marginLeft: '10px', borderRadius: '8px', textAlign: 'center' }}
                                        placeholder="Qty"
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-block mt-2" key={size}>
                                    <p
                                      style={{ cursor: 'pointer' }}
                                      className={`ms-3 me-3 size d-flex justify-content-center align-items-center ${selectedSize1 === size ? 'selected-size' : ''
                                        }`}
                                    // onClick={() => handleSizeClick1(size)}
                                    >
                                      {size}
                                    </p>
                                    <div style={{ height: '100px' }}>
                                      <input
                                        type="text"
                                        value={!sizeQuantities.length > 0 ? (sizeQuantities1.find(item => item.size === size)?.quantity || '') : ''}
                                        // readOnly={sizeQuantities.length > 0}
                                        onChange={(e) => handleManualQuantityChange(size, e.target.value)}
                                        style={{ width: '50px', height: '50px', marginLeft: '10px', borderRadius: '8px', textAlign: 'center' }}
                                        placeholder="Qty"
                                      />
                                    </div>
                                  </div>
                                )}

                              </>
                            ))}
                          </div>
                          <div>
                            {sizeQuantities?.length > 0 && (addName || addNumber) && (
                              <p onClick={handleEnterNameClick} style={{ marginTop: '-30px', cursor: 'pointer', color: '#E4510B', width: '200px', textDecoration: 'underline' }}>
                                {addName && addNumber
                                  ? 'Edit Name/Number'
                                  : addName
                                    ? 'Edit Name'
                                    : 'Edit Number'}
                              </p>
                              // <div style={{ marginTop: '-50px' }} className='d-flex justify-content-start w-100'>
                              //   <button
                              //     style={{ color: '#E4510B', border: 'none', background: 'transparent', width: '100%' }}
                              //     className="btn btn-secondary"
                              //     onClick={handleEnterNameClick}
                              //   >
                              //     {addName && addNumber
                              //       ? 'Edit Name/Number'
                              //       : addName
                              //         ? 'Edit Name'
                              //         : 'Edit Number'}
                              //   </button>
                              // </div>
                            )}
                          </div>
                        </div>
                      </div>

                    </>
                  ) : null}
                  <p className='text-danger fw-bold'>Note* <span className='text-secondary fw-normal'>Once satisfied with your customization, click the Confirm button to finalize your selection and add it to the cart.</span></p>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='d-flex align-items-center justify-content-center'>
              <div>
                <button
                  type="button" // Prevents form submission
                  style={{
                    background: 'white', color: '#E4510B', fontSize: '16px',
                    fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B',
                    width: '225px', padding: '10px'
                  }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent any default action
                    toggle1(); // Toggle the modal visibility
                  }}
                >
                  {loadings ? (
                    <div className="loader">
                      <Spinner>Loading...</Spinner>
                    </div>
                  ) : (
                    <span>Cancel</span>
                  )}
                </button>
              </div>
              <div className='ms-3'>
                <button
                  type='button'
                  style={{
                    background: '#E4510B', color: 'white', fontSize: '16px',
                    fontWeight: '700', lineHeight: '24px', border: '1px solid #E4510B',
                    width: '225px', padding: '10px'
                  }}
                  onClick={confirm} // Close modal
                >
                  Confirm
                </button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

    </div >
  );
}

export default ProductCustomize;