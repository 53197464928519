import React, { useEffect, useState } from 'react'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import './index.css'
import { Plus, Trash } from 'react-feather'

function NameNumberModal({ openModal, color, handleModal, name, values, onSave, modalMode, fontStyle,
    textColor }) {

    const [rows, setRows] = useState([{ id: Date.now(), selectedSize: '', name: '', number: '' }]);
    const [activeIndex, setActiveIndex] = useState(null);
    const [nameAndNumber, setNameAndNumber] = useState([]);

    const handleAddRow = () => {
        setRows(prevRows => [...prevRows, { id: Date.now(), selectedSize: '', name: '', number: '' }]); // Add new row
    };

    const handleRemoveRow = (id) => {
        setRows(prevRows => {
            const updatedRows = prevRows.filter(row => row.id !== id); // Remove the row
            // If activeIndex was the deleted row's index, reset it
            if (activeIndex !== null && activeIndex >= updatedRows.length) {
                setActiveIndex(updatedRows.length - 1); // Set to the last row if available
            }
            return updatedRows;
        });
    };

    const handleSelectChange = (id, value) => {
        setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, selectedSize: value } : row))); // Update selected size
    };

    const handleNameChange = (id, value) => {
        setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, name: value.toUpperCase() } : row))); // Update name to uppercase
    };

    const handleNumberChange = (id, value) => {
        setRows((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, number: value } : row
            )
        );
    };

    const saveAndClose = () => {
        const updatedNameAndNumber = rows.map((row) => ({
            name: row?.name,
            number: row?.number,
            size: row?.selectedSize
        }));

        onSave(updatedNameAndNumber);
        handleModal();
    };

    const handleRowClick = (index) => {
        setActiveIndex(index); // Set the clicked row as active
    };

    const totalEntries = rows.length; // Total number of rows
    const filledNamesCount = rows.filter(row => row.name && row.name.trim() !== '').length;
    const filledNumbersCount = rows.filter(row => row.number !== undefined && row.number !== '').length;
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);

    useEffect(() => {
        // Determine button enablement based on modal mode
        if (modalMode === 'name') {
            // Enable if all rows have names filled
            setIsButtonEnabled(filledNamesCount === totalEntries);
        } else if (modalMode === 'number') {
            // Enable if all rows have numbers filled
            setIsButtonEnabled(filledNumbersCount === totalEntries);
        } else if (modalMode === 'nameAndNumber') {
            // Enable if all rows have both names and numbers filled
            setIsButtonEnabled(filledNamesCount === totalEntries && filledNumbersCount === totalEntries);
        }
    }, [rows, modalMode, filledNamesCount, filledNumbersCount, totalEntries]);

    return (
        <div>
            <Modal isOpen={openModal} toggle={handleModal} className='sidebar-lg' modalClassName='modal-slide-in sidebar-todo-modal' contentClassName='p-0' size='lg' >
                <ModalHeader toggle={handleModal}>
                    <div className='d-flex align-items-center justify-content-between mb-1 px-1' style={{ backgroundColor: '#ffff' }}>
                        <div>
                            <p style={{ fontSize: '16px', color: '#E45D25' }}>NAMES & NUMBERS</p>
                            <p className='modal-title' style={{ fontFamily: 'Lato', fontSize: '20px', fontWeight: '400' }}>
                                <b>Edit Name & Number List</b>
                            </p>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <Row>
                            <Col sm={12} lg={4}>
                                <div
                                    className="name-number-box"
                                    style={{
                                        backgroundColor: name?.customizeImages[color]?.colourCode || 'transparent',
                                    }}
                                >
                                    {modalMode === 'name' && (
                                        <h1 style={{ fontFamily: fontStyle, color: textColor }}>
                                            {activeIndex !== null && activeIndex < rows.length ? rows[activeIndex].name.toUpperCase() : 'NAME'}
                                        </h1>
                                    )}
                                    {modalMode === 'number' && (
                                        <h1 style={{ fontFamily: fontStyle, color: textColor }}>
                                            {activeIndex !== null && activeIndex < rows.length ? (rows[activeIndex].number || '00') : '00'}
                                        </h1>
                                    )}
                                    {modalMode === 'nameAndNumber' && (
                                        <>
                                            <h1 style={{ fontFamily: fontStyle, color: textColor }}>
                                                {activeIndex !== null && activeIndex < rows.length ? rows[activeIndex].name.toUpperCase() : 'NAME'}
                                            </h1>
                                            <h1 style={{ fontFamily: fontStyle, color: textColor }}>
                                                {activeIndex !== null && activeIndex < rows.length ? (rows[activeIndex].number || '00') : '00'}
                                            </h1>
                                        </>
                                    )}
                                </div>
                                <div className='mt-5 mb-2 d-flex flex-column justify-content-center align-content-center '>
                                    <button
                                        className={`save-cls-btn ${isButtonEnabled ? 'enabled' : 'disabled'}`}
                                        onClick={saveAndClose}
                                        disabled={!isButtonEnabled}
                                        style={{
                                            backgroundColor: isButtonEnabled ? '#E45D25' : '#d3d3d3',
                                            color: isButtonEnabled ? '#fff' : '#999'
                                        }}
                                    >
                                        {modalMode === 'name' ? 'Save Names' : modalMode === 'number' ? 'Save Numbers' : 'Save & Close'}
                                    </button>
                                    <span className='text-center mt-3 mb-3'>or</span>
                                    <button className='save-cls-btn-1' onClick={handleModal}>Exit without Saving</button>
                                </div>
                            </Col>
                            <Col sm={12} lg={8}>
                                <div className='d-flex gap-2'>
                                    <img height={80} width={80} className='rounded-2' src={name?.customizeImages[color]?.images[1]?.image} />
                                    <div>
                                        <h1 className='fs-5'>{name?.name}</h1>
                                        <div className="d-flex gap-2">
                                            <div
                                                style={{
                                                    height: '20px',
                                                    width: '20px',
                                                    backgroundColor: name?.customizeImages[color]?.colourCode || 'white',
                                                    border: '1px solid grey',
                                                    borderRadius: '4px'
                                                }}
                                            ></div>
                                            <p>{name?.customizeImages[color]?.colourCode}</p>
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col sm={3}>
                                        <h1 className='fs-5'>Size</h1>
                                    </Col>
                                    {modalMode === 'name' || modalMode === 'nameAndNumber' ? (
                                        <Col sm={6}>
                                            <h1 className='fs-5'>Name</h1>
                                        </Col>
                                    ) : null}
                                    {modalMode === 'number' || modalMode === 'nameAndNumber' ? (
                                        <Col sm={3}>
                                            <h1 className='fs-5'>#</h1>
                                        </Col>
                                    ) : null}
                                </Row>
                                {rows.map((row, index) => (
                                    <Row onClick={() => handleRowClick(index)} className='mt-2' key={row.id}>
                                        <Col sm={3}>
                                            <select
                                                className='style-name-input'
                                                value={row.selectedSize}
                                                onChange={(e) => handleSelectChange(row.id, e.target.value)} // Handle size change
                                            >
                                                <option value="">---Select---</option>
                                                {values.map((value) => (
                                                    <option key={value} value={value}>{value}</option> // Display all size options
                                                ))}
                                            </select>
                                        </Col>
                                        {modalMode === 'name' || modalMode === 'nameAndNumber' ? (
                                            <Col sm={6}>
                                                <input
                                                    type='text'
                                                    className='style-name-input'
                                                    value={row.name} // Bind the name input to the state
                                                    onChange={(e) => handleNameChange(row.id, e.target.value)} // Handle name change
                                                />
                                            </Col>
                                        ) : null}
                                        {modalMode === 'number' || modalMode === 'nameAndNumber' ? (
                                            <Col sm={3}>
                                                <div className='d-flex gap-2'>
                                                    <input
                                                        type='number'
                                                        className='style-name-input'
                                                        value={row.number} // Bind the number input to the state
                                                        onChange={(e) => handleNumberChange(row.id, e.target.value)} // Handle number change
                                                    />
                                                    <span className='mt-1' onClick={() => handleRemoveRow(row.id)}>
                                                        <Trash size={22} />
                                                    </span>
                                                </div>
                                            </Col>
                                        ) : null}
                                    </Row>
                                ))}
                                <div className='mt-2'>
                                    <span style={{ color: '#E45D25', cursor: 'pointer' }} onClick={handleAddRow}>
                                        <Plus size={16} /> <span className='pt-2'>Add Another</span>
                                    </span>
                                </div>
                                <div className='bg-light p-3 mt-2'>
                                    {modalMode === 'name' && (
                                        <>Totals: {filledNamesCount} out of {totalEntries} have Names</>
                                    )}
                                    {modalMode === 'number' && (
                                        <>Totals: {filledNumbersCount} out of {totalEntries} have Numbers</>
                                    )}
                                    {modalMode === 'nameAndNumber' && (
                                        <>Totals: {filledNamesCount} out of {totalEntries} have Names | {filledNumbersCount} out of {totalEntries} have Numbers</>
                                    )}
                                </div>

                            </Col>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default NameNumberModal