import React, { useEffect, useState } from 'react'
import AboutHome from './about'
import InteractiveHome from './interactive'
import Navbar from '../navbar'
import FooterSection from '../footer'
import FastMoving from './fastMoving'
import Landing from './landingPage'




function HomeIndex() {
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [offerModal, setOfferModal] = useState(false);
    const offertoggle = () => setOfferModal(!offerModal);

    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!token) {
            const timeoutId = setTimeout(() => {
                toggle();
            }, 120000);
            return () => clearTimeout(timeoutId);
        }
    }, []);

    useEffect(() => {
        if (!token) {
            const timeoutId = setTimeout(() => {
                offertoggle();
            }, 220000);
            return () => clearTimeout(timeoutId);
        }
    }, []);


    return (
        <div>
            <Navbar />
            <Landing />
            {/* <BannerHome /> */}
            {/* <CartRecommendation /> */}
            <FastMoving />
            {/* <TargetAudience /> */}
            <AboutHome />
            {/* <SocialFeeds /> */}
            <InteractiveHome />
            {/* <TestimonialIndex /> */}
            {/* <LoginPopup modal={modal} toggle={toggle} />
            <OfferPopup modal={offerModal} toggle={offertoggle} /> */}
            <FooterSection />
            {/* <ChatBot /> */}
        </div>
    )
}
export default HomeIndex
