import React, { useEffect, useState } from 'react'
import './index.css'
import Navbar from '../../navbar'
import FooterSection from '../../footer'
import { Button, Col, Modal, ModalBody, ModalHeader, Row, Spinner } from 'reactstrap'
import img1 from '../../../assets/images/order-icons/Group 1597883090 (1).png'
import img2 from '../../../assets/images/order-icons/Group 1597883091.png'
import img3 from '../../../assets/images/order-icons/Group 1597883092.png'
import img4 from '../../../assets/images/order-icons/Group 1597883065 (1).png'
import img5 from '../../../assets/images/order-icons/Group 1597883093.png'
import img6 from '../../../assets/images/order-icons/Group 1597883094 (1).png'
import { Eye, RotateCcw } from 'react-feather'
import axios from 'axios'
import { deliveryDays, orderPost, orderStats } from '../../../ApiConfigs/ApiConfig'
import toast from 'react-hot-toast'
import ReactPaginate from 'react-paginate'
import DataTable from 'react-data-table-component'
import { useNavigate } from 'react-router-dom'
import { FiEdit3 } from 'react-icons/fi'
import StatsCard from './StatsCard'

export const getDeliveryStatus = (status) => {
    switch (status) {
        case 'ordercompleted':
            return (
                <div className='disc-container-1 disc-con-clr-4 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-4'></div>
                    <p className='p-0 m-0'>New Order</p>
                </div>
            );
        case 'process':
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>On Progress</p>
                </div>
            );
        case 'shipped':
            return (
                <div className='disc-container-1 disc-con-clr-5 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-5'></div>
                    <p className='p-0 m-0'>Shipped</p>
                </div>
            );
        case 'delivered':
            return (
                <div className='disc-container-1 disc-con-clr-2 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-2'></div>
                    <p className='p-0 m-0'>Delivered</p>
                </div>
            );
        case 'cancelled':
            return (
                <div className='disc-container-1 disc-con-clr-3 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-3'></div>
                    <p className='p-0 m-0'>Cancelled</p>
                </div>
            );
        default:
            return (
                <div className='disc-container-1 disc-con-clr-1 gap-1 d-flex justify-content-center align-items-center'>
                    <div className='disc-1 disc-clr-1'></div>
                    <p className='p-0 m-0'>In Progress</p>
                </div>
            );
    }
};

export const customStyles = {
    headCells: {
        style: {
            backgroundColor: '#FCEDE6',
            color: '#333333',
            fontWeight: '500',
            fontSize: '16px',
        },
    },
    cells: {
        style: {
            cursor: 'pointer'
        },
    },
};

function OrderManagement() {
    const navigate = useNavigate()
    const [searchText, setSearchText] = useState('');
    const [orderStatus, setOrderStatus] = useState('all');
    const [selectedDate, setSelectedDate] = useState('');
    const toggle = () => setModal(!modal);
    const [modal, setModal] = useState(false);
    const [orderStatsData, setOrderStats] = useState([])
    const [deliveryDay, setDeliveryDay] = useState('')
    const [editedDeliveryDate, setEditedDeliveryDate] = useState('')
    const [data, setData] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const refresh = () => {
        setSearchText('')
        setOrderStatus('all')
        setSelectedDate('')
        setCurrentPage(1)
    }

    const getdata = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${orderPost}?status=${orderStatus}&date=${selectedDate}&search=${searchText}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }

    const getStats = async () => {
        try {
            const response = await axios.get(orderStats)
            setOrderStats(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const deliverydetails = async () => {
        try {
            const response = await axios.get(deliveryDays)
            setDeliveryDay(response?.data?.result)
            setEditedDeliveryDate(response?.data?.result?.deliveryDays);
            // toast.success(response?.data?.msg)
            // set(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const deliveryday = async () => {
        const payload = {
            deliveryDays: editedDeliveryDate,
        }
        try {
            const response = await axios.post(`${deliveryDays}`, payload)
            toast.success(response?.data?.msg)
            deliverydetails();
            getdata();
            getStats();
            toggle();
            // set(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const handleQuantityChange = (e) => {
        setEditedDeliveryDate(e.target.value); // Update the value based on user input
    };

    useEffect(() => {
        getdata()
        getStats()
        deliverydetails()
    }, [])



    const dataTable = [
        {
            name: 'SI No',
            minWidth: '50px',
            cell: (row, i) => i + 1,
        },
        {
            name: 'Order ID',
            minWidth: '60px',
            cell: (row, i) => <span onClick={() => navigate(`/admin/dashboardViewData/${row?._id}`)}>{row?.orderId}</span>,
        },
        {
            name: 'Product Name',
            sortable: false,
            minWidth: '100px',
            cell: row => (<span>{row?.products[0]?.product?.name}</span>),
        },
        {
            name: 'Mobile Number',
            sortable: false,
            minWidth: '100px',
            cell: row => row?.shipping?.mobile,
        },
        {
            name: 'Ordered Date',
            sortable: false,
            minWidth: '100px',
            cell: row => new Date(row?.date).toLocaleDateString()
        },
        {
            name: 'Expected Delivery',
            sortable: false,
            minWidth: '100px',
            cell: row => new Date(row?.expectedDeliveryDate).toLocaleDateString()
        },
        {
            name: 'Delivered Date',
            sortable: false,
            minWidth: '100px',
            cell: row => row?.deliveriedDate ? new Date(row?.deliveriedDate).toLocaleDateString() : null
        },
        {
            name: 'Order Status',
            sortable: false,
            minWidth: '150px',
            cell: (row) => (
                <div>
                    {getDeliveryStatus(row?.status)}
                </div>
            )
        },
        {
            name: 'Action',
            sortable: false,
            minWidth: "50px",
            cell: (row) => (
                <div style={{ cursor: "pointer" }} onClick={() => navigate(`/admin/dashboardViewData/${row?._id}`)}><Eye /></div>
            )
        }
    ];

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };
    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount || 1}
                    activeClassName="active"
                    breakClassName="page-item"
                    pageClassName={'page-item'}
                    breakLinkClassName="page-link"
                    nextLinkClassName={'page-link'}
                    pageLinkClassName={'page-link'}
                    nextClassName={'page-item next'}
                    previousLinkClassName={'page-link'}
                    previousClassName={'page-item prev'}
                    onPageChange={(page) => handlePagination(page)}
                    forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                    containerClassName={'pagination react-paginate justify-content-end p-1'}
                />
            </>
        );
    };

    useEffect(() => {
        getdata()
        getStats()
    }, [selectedDate, orderStatus, searchText, currentPage])


    return (
        <div>
            <Navbar />
            <div className='pt-5 s' style={{ paddingLeft: '5%', paddingRight: '5%', paddingBottom: '10%', backgroundColor: '#F8F8F8' }}>
                <div style={{ width: '100%' }} className='d-flex align-items-center'>
                    <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Order Management</h1>
                    <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                </div>
                <div className='mt-5'>
                    <div>
                        <StatsCard data={orderStatsData} md={3} xl={2} />
                    </div>
                </div>
                <div className='mt-5 d-flex justify-content-between'>
                    <p style={{ color: '#333333', opacity: '0.8', fontSize: '20px', fontWeight: '500' }} className='cart-quantity'>
                        Expected delivery Days:
                        <span
                            className='ms-3'
                            style={{ color: '#e6703a', cursor: 'pointer', border: '1px solid #DFDFDF', borderRadius: '5px', padding: '10px', background: 'white', height: '20px' }}
                            onClick={toggle} // Open modal on click
                        >
                            <span className='text-dark'>{deliveryDay?.deliveryDays} Days</span>
                            <FiEdit3 className='ms-3' />
                        </span>
                    </p>
                </div>
                <div className='mt-2 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Order List</h1>
                        </div>
                        <span onClick={refresh} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center s'>
                            <div className='d-flex flex-column'>
                                <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                <input
                                    type='text'
                                    className='search-order'
                                    placeholder='Search by mobile no, name, order ID'
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>
                            <div className='d-flex'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Order Status</label>
                                    <select
                                        className='select-order'
                                        value={orderStatus}
                                        onChange={(e) => setOrderStatus(e.target.value)}
                                    >
                                        <option value='all'>All Order</option>
                                        <option value='ordercompleted'>New Order</option>
                                        <option value='process'>Processed</option>
                                        <option value='shipped'>Shipped</option>
                                        <option value='delivered'>Delivered</option>
                                        <option value='cancelled'>Cancelled</option>
                                        {/* Add more options as needed */}
                                    </select>
                                </div>
                                <div className='d-flex flex-column ms-2'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Select Date</label>
                                    <input
                                        type='date'
                                        className='select-order'
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div style={{ height: "80vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <div className="mt-5">
                                <DataTable
                                    pagination
                                    paginationServer
                                    noHeader
                                    highlightOnHover
                                    fixedHeader
                                    fixedHeaderScrollHeight='50vh'
                                    data={data?.rows}
                                    columns={dataTable}
                                    paginationDefaultPage={currentPage}
                                    paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                                    customStyles={customStyles}
                                    onRowClicked={(row) => navigate(`/admin/dashboardViewData/${row?._id}`)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <p>Update Expected Delivery Days</p>
                </ModalHeader>
                <ModalBody>
                    <div style={{ marginTop: '-10px', marginBottom: '15px' }} className='d-block'>
                        <div>
                            <label
                                style={{ color: '#333333', opacity: '0.8', fontSize: '20px', fontWeight: '500' }}
                                className=''
                            >
                                Expected Delivery Days:
                            </label>
                        </div>
                        <div className='pe-3'>
                            <input
                                className='ms-2 editQuantityInput ps-2 pe-2'
                                style={{
                                    border: '1px solid #DFDFDF', background: 'white', width: '100%', marginRight: '20px', borderRadius: '5px', height: '40px', fontSize: '16px', color: '#333333', opacity: '0.8'
                                }}
                                name='editQuantity'
                                value={editedDeliveryDate}
                                onChange={handleQuantityChange}  // Update state as you type
                                placeholder='Enter New Delivery Days'
                                type="number"
                                pattern="[0-9]+"
                                title="Please enter only digits (0-9)"
                            />
                        </div>
                        <div className='d-flex flex-column mt-2'>
                            <div className='d-bloick mt-2'>
                                <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                                <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Are you sure you want to update the expected delivery date for all selected products? This action will apply the new date to all products</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex'>
                        <Button style={{ width: '49%', border: '1px solid #E4510B', color: '#E4510B', background: 'white' }} onClick={toggle} type='button'>
                            <b>Cancel</b>
                        </Button>
                        <Button className='ms-2' style={{ width: '49%', border: 'none', background: '#E4510B', color: 'white' }} onClick={deliveryday} type='submit'>
                            <b>Update</b>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
            <FooterSection />
        </div>
    )
}

export default OrderManagement
