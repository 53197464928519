import React from 'react'
import './index.css'
import { Col } from 'reactstrap'
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import about from "../../../assets/images/banner/about.png";
import about1 from "../../../assets/images/banner/about1.png";
import about2 from "../../../assets/images/banner/about2.png";
import about3 from "../../../assets/images/banner/about3.png";
import about4 from "../../../assets/images/banner/about4.png";
import { Link, useNavigate } from 'react-router-dom'
import { FaAngleRight } from "react-icons/fa6";

function AboutHome() {

    const navigate = useNavigate()

    // const navToGuarante = () => {
    //     navigate('/printonGuarante')
    // }
    // const navToDelivery = () => {
    //     navigate('/shippingPolicy')
    // }
    // const navToReach = () => {
    //     navigate('/reachUs')
    // }

    return (
        <>
            <div className='about-con'>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className='mt-3 mt-lg-5 d-flex'>
                            <div>
                                <img className='img1' src={categoryLeft} alt="" />
                            </div>
                            <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                <h3 className='pp' style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>About Printon</h3>
                            </div>
                            <div>
                                <img className='img1' src={categoryRight} alt="" />
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-lg-flex d-sm-block about-img1' style={{ padding: '5%', position: 'relative' }}>
                        <Col className='about-body d-flex flex-column align-items-center justify-content-center' lg={7} md={12} sm={12}>
                            <div style={{ marginTop: '-50px', marginLeft: '50px', opacity: '0.3' }} className='position-absolute style1'>
                                <img style={{}} src={about1} alt="" />
                            </div>
                            <div style={{ marginTop: '-15px', marginLeft: '400px' }} className='position-absolute style1'>
                                <img style={{}} src={about4} alt="" />
                            </div>
                            <div className='about'>
                                <div style={{ padding: '30px', position: 'relative' }}>
                                    <div>
                                        <p className='about-para'>
                                            Welcome to Printon! We specialize in high-quality custom products, from apparel and accessories to home decor and office supplies. Our mission is to bring your unique visions to life using cutting-edge printing technology and eco-friendly materials. We prioritize quality, sustainability, and customer satisfaction, ensuring vibrant and durable products delivered quickly and affordably. With a user-friendly platform and dedicated customer support, Printon makes customization easy and enjoyable. Join our community and discover the endless possibilities of personalized products with Printon. Thank you for choosing us to bring your ideas to life!
                                        </p>
                                    </div>
                                    <div>
                                        <Link to={`/aboutus`}>
                                            <button className='btn-read'>Read more <FaAngleRight size={15} /></button>
                                        </Link>
                                    </div>
                                </div>
                                <div style={{ marginTop: '-50px', marginLeft: '280px', opacity: '0.3' }} className='position-absolute style1'>
                                    <img style={{}} src={about1} alt="" />
                                </div>
                                <div style={{ marginTop: '-100px', marginLeft: '600px' }} className='position-absolute style1'>
                                    <img style={{}} src={about2} alt="" />
                                </div>
                                <div style={{ marginTop: '-53px', marginLeft: '790px' }} className='d-md-none position-absolute style1'>
                                    <img style={{}} src={about3} alt="" />
                                </div>
                            </div>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-end' lg={5} md={12} sm={12}>
                            <div className=' d-flex align-items-center justify-content-center'>
                                <img className='about-img' src={about} alt="" />
                            </div>
                        </Col>
                        <div style={{ marginTop: '415px', marginLeft: '1325px', zIndex: '-100px' }} className='d-md-none position-absolute style1'>
                            <img style={{}} src={about2} alt="" />
                        </div>
                        <div style={{ marginTop: '70px', marginLeft: '1305px', zIndex: '-100px' }} className='d-md-none position-absolute style1'>
                            <img style={{}} src={about3} alt="" />
                        </div>
                    </div>

                </Col>
            </div>
        </>
    )
}

export default AboutHome
