import React, { useEffect, useState } from 'react';
import { X } from 'react-feather';
import * as yup from "yup";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { Button, Form, FormFeedback, Label, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-hot-toast';
import { deliveryPincode, district, fieldTypeApi, pincode1, state } from '../../../../../ApiConfigs/ApiConfig';
import Select from 'react-select';

function AddLocation({ modal, toggle, additionalData, id }) {
    const [getState, setState] = useState([]);
    const [getDistrict, setDistrict] = useState([]);
    const [getPincode, setPincode] = useState([]);
    const [selectedDistricts, setSelectedDistricts] = useState([]);
    const [selectedState, setSelectedState] = useState(null); // For the selected state
    const [selectedPincodes, setSelectedPincodes] = useState([]);


    const formSchema = yup.object().shape({
        state: yup.object().nullable().required('Please select a state'),
        districts: yup.array().min(1, 'Please select at least one district').required(),
        exceptPincodes: yup.array().required(),
    });

    const { reset, control, handleSubmit, formState: { errors } } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: {
            state: null,
            districts: [],
            exceptPincodes: []
        }
    });

    const handleRest = () => {
        reset({
            state: null,
            districts: [],
            exceptPincodes: []
        });
    };

    const close = () => {
        toggle();
        handleRest();
    };

    const submitForm = async (data) => {
        const payload = {
            product: id,
            state: selectedState,  // Correct reference to form data
            districts: selectedDistricts,  // Correct reference to form data
            exceptPincodes: selectedPincodes,  // Correct reference to form data
        };

        try {
            const response = await axios.post(deliveryPincode, payload);
            close();
            additionalData();
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg?.message || error?.response?.data?.msg);
        }
    };

    useEffect(() => {
        getStateOption();
    }, []);

    const getStateOption = async (searchValue = "") => {
        try {
            const response = await axios.get(`${state}?search=${searchValue}`);
            const optionsState = response?.data?.result?.map((item) => ({
                label: item?.state,
                value: item?._id,
            }));
            setState(optionsState);
        } catch (error) {
            console.error('Error fetching states:', error);
        }
    };

    const handleStateChange = (selectedOption) => {
        setDistrict([]);
        reset({ districts: [], exceptPincodes: [] }); // Reset districts and pincodes
        const stateLabel = selectedOption.label; // Extract the state label
        setSelectedState(stateLabel);
        getDistrictOption([selectedOption.label]);
    };

    const getDistrictOption = async (stateValues) => {
        try {
            const response = await axios.get(`${district}?state=${stateValues.join(",")}`);
            const options = response?.data?.result?.rows?.map((item) => ({
                label: item?.district_name_english,
                value: item?._id,
            }));
            setDistrict(options);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const handleDistrictChange = (selectedOption) => {
        const selectedDistricts = selectedOption.map(district => district.label);  // Get the selected district labels
        getPincodeOption('', selectedDistricts);  // Call API with empty search value initially
        setSelectedDistricts(selectedDistricts);  // Save the selected districts in the state for later use
    };

    const getPincodeOption = async (selectvalue = '', districtValues = []) => {
        try {
            const response = await axios.get(`${pincode1}?districts=${districtValues.join(",")}&search=${selectvalue}`);
            const options = response?.data?.result?.map((item) => ({
                label: item?.pincode,
                value: item?._id,
            }));
            setPincode(options);
        } catch (error) {
            console.error('Error fetching pincodes:', error);
        }
    };

    return (
        <Modal isOpen={modal} toggle={toggle}>
            <div className="d-flex align-items-center justify-content-between mb-1 px-1" style={{ backgroundColor: 'white', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', borderBottom: '1px solid #EEEEEE' }}>
                <h4 className="modal-title" style={{ marginTop: '23px', marginBottom: '23px', paddingLeft: '10px', fontFamily: 'roboto', color: '#2B2B2D' }}>
                    Add Delivery Locations
                </h4>
                <X style={{ color: 'black', cursor: 'pointer' }} className="addmodal-close fw-normal mt-25 me-2" size={25} onClick={close} />
            </div>
            <ModalBody>
                <Form onSubmit={handleSubmit(submitForm)}>
                    <div className='mb-1 mt-3'>
                        <Label>Select Delivery Available State</Label>
                        <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={getState}  // Options fetched from API
                                    value={getState?.find(option => option.value === field.value?.value || option.value === field.value)} // Ensuring value displays correctly
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption.value);  // Store only the value in the form
                                        handleStateChange(selectedOption);     // Handle state change
                                    }}
                                    placeholder="Select State"
                                    isClearable
                                />
                            )}
                        />
                        {errors.state && <FormFeedback>{errors.state.message}</FormFeedback>}
                    </div>

                    <div className='mb-1 mt-3'>
                        <Label>Select Delivery Available Districts</Label>
                        <Controller
                            name="districts"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={getDistrict}
                                    isMulti
                                    placeholder="Select Districts"
                                    onChange={(selectedOption) => {
                                        handleDistrictChange(selectedOption);
                                        field.onChange(selectedOption);
                                    }}
                                    value={field.value}
                                />
                            )}
                        />
                        {errors?.districts && <FormFeedback>{errors.districts.message}</FormFeedback>}
                    </div>

                    <div className='mb-1 mt-3'>
                        <Label>Select Unavailable Delivery Location Pincodes</Label>
                        <Controller
                            name="exceptPincodes"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={getPincode}  // Fetched options from API
                                    isMulti
                                    placeholder="Select Unavailable Pincodes"
                                    onChange={(selectedOption) => {
                                        field.onChange(selectedOption); // Update the form state
                                        const labels = selectedOption.map(option => option.label);
                                        setSelectedPincodes(labels);
                                    }}
                                    value={field.value}
                                    onInputChange={(inputValue) => {
                                        getPincodeOption(inputValue, selectedDistricts);  // Fetch based on search term and selected districts
                                    }}
                                />
                            )}
                        />
                        {errors?.exceptPincodes && <FormFeedback>{errors.exceptPincodes.message}</FormFeedback>}
                    </div>
                    {/* <div className='d-bloick mt-3'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}>Enter the new name for the Field option in the text field. Ensure the name is accurate to avoid confusion. </p>
                    </div> */}
                    <hr></hr>
                    <div className='d-flex'>
                        <Button style={{ border: '1px solid #E4510B', color: '#E4510B', background: 'white', borderRadius: '10px' }} onClick={close} className=' mt-1 w-50'>Cancel</Button>
                        <Button className='mt-1 w-50 ms-2 add-modal-btn' type='submit' onClick={submitForm}>Submit</Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
}

export default AddLocation;

