import axios from 'axios';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { productCategory } from '../../../ApiConfigs/ApiConfig';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import "./index.css";
import { ArrowRight } from "react-feather";
import { Col, Row } from 'reactstrap';
import { FaAngleRight } from "react-icons/fa6";
import Demo_json from '../landingPage/Demo.json';
import categoryLeft from "../../../assets/images/banner/Category-left.png";
import categoryRight from "../../../assets/images/banner/Category-right.png";
import banner1 from "../../../assets/images/banner/Group1.png";
import banner2 from "../../../assets/images/banner/Group2.png";
import banner3 from "../../../assets/images/banner/Group3.png";
import banner4 from "../../../assets/images/banner/Group4.png";
import banner5 from "../../../assets/images/banner/Group5.png";
import banner6 from "../../../assets/images/banner/Group6.png";
import banner7 from "../../../assets/images/banner/Group7.png";
import banner8 from "../../../assets/images/banner/Group8.png";
import banner9 from "../../../assets/images/banner/Group9.png";
import Lottie from 'react-lottie'
import printimg1 from "../../../assets/images/banner/printimg1.png";
import printimg2 from "../../../assets/images/banner/printimg2.png";
import printimg3 from "../../../assets/images/banner/printimg3.png";
import printimg4 from "../../../assets/images/banner/printimg4.png";
import choose from "../../../assets/images/logo/choose.png";
import office from "../../../assets/images/logo/office.png";
import booklet from "../../../assets/images/logo/booklet.png";
import business from "../../../assets/images/logo/business-cards.png";
import sticker from "../../../assets/images/logo/sticker.png";
import note from "../../../assets/images/logo/notebook.png";
import idcard from "../../../assets/images/logo/id-card.png";
import arrow from "../../../assets/images/logo/arrow.png";
import spec from "../../../assets/images/logo/spec.png";
import upload from "../../../assets/images/logo/upload.png";
import checkout from "../../../assets/images/logo/checkout.png";
import ChooseProductModal from './ChooseProductModal';

function Landing() {
    const [dataa, setData] = useState();
    const [eightData, setEightData] = useState();
    const [loading, setLoading] = useState(false);
    const [chooseModal, setChooseModal] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [length, setlength] = useState();
    const navigate = useNavigate();

    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(productCategory);
            setData(response?.data?.result);
        } catch (error) {
            toast.error(error?.response?.data?.msg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        additionalData();
    }, []);

    useEffect(() => {
        if (dataa && dataa.length >= 8) {
            const shuffledData = [...dataa].sort(() => Math.random() - 0.5);
            setEightData(shuffledData.slice(0, 4));
        } else {
            setEightData(dataa);
        }
    }, [dataa]);

    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const truncateDescription = (description, wordLimit) => {
        const words = description?.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return description;
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        if (windowWidth > 1200) {
            setlength(40);
        }
        if (windowWidth > 1550) {
            setlength(50);
        }
    }, [windowWidth]);

    const categories = [
        { image: office, label: "Office Supplies" },
        { image: booklet, label: "Booklet Printing" },
        { image: business, label: "Business Cards" },
        { image: sticker, label: "Stickers Printing" },
        { image: note, label: "Note Books" },
        { image: idcard, label: "ID Cards and Lanyards" },
    ];

    const categoriesproduct = [
        { image: office },
        { image: booklet },
        { image: business },
        { image: sticker },
        { image: note }
    ];

    const [isHovered, setIsHovered] = useState(null);

    const handleMouseEnter = (index) => {
        setIsHovered(index);
    };
    const handleMouseLeave = () => setIsHovered(null);

    const navToViewPage = () => {
        Navigate('/products');
    };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Demo_json,
    };

    return (
        <>
            <div className='custom-product position-relative'>
                {/* <div className='position-relative mt-4 print-img'>
                    <img src={customimg} alt="" />
                </div> */}
                <div style={{ paddingRight: '8%', paddingLeft: '8%' }} className='d-lg-flex d-md-block print-imgggg d-sm-block w-100 mt-4'>
                    <Col lg={6} md={12} sm={12}>
                        <div className='d-flex flex-column printtttt'>
                            <div className='d-block mt-2'>
                                <h1 className='print-h1'>Make It Yours <span className='ps-2 pe-2' style={{ color: 'white', background: '#E42525' }}>Custom</span><br /> Products at Your<br className='br' /> Fingertips</h1>
                                <p className='mt-4 print-para'>Create personalized posters with our easy-to-use <br className='br' /> customization tools. Choose from a variety of <br className='br' /> templates, add your images and text, and customize <br className='br' /> colors to make your poster unique.</p>
                            </div>
                            <div className='d-flex'>
                                <div className='d-block'>
                                    <p style={{ color: '#E42525' }}>Starts from</p>
                                    <div className='d-flex'>
                                        <p style={{ color: '#37A003', marginTop: '-15px' }}>₹10</p>
                                        <p className='ms-1 strike'>₹25</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <button onClick={() => setChooseModal(true)} className='print-btn pt-2 ms-5 pb-2'>Create your Design</button>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} className='printiii'>
                        <div className='d-block printii'>
                            <div className='mt-3'>
                                <img className='printii-img' src={printimg1} alt="" />
                            </div>
                            <div className='d-flex align-items-center printii-img mt-4'>
                                <img className='printimg2' src={printimg2} alt="" />
                                <img className='printimg45' style={{ width: '190px', height: '190px', borderRadius: '12px', marginLeft: '35px' }} src={printimg3} alt="" />
                                <img className='printimg2' style={{ marginLeft: '35px' }} src={printimg4} alt="" />
                            </div>
                        </div>
                        {/* <div>
                            <Lottie style={{ borderRadius: '15px' }} options={defaultOptions} className="w-full" />
                        </div> */}
                    </Col>
                </div>
            </div>
            <Col lg={12}>
                <div className='d-sm-none d-lg-flex align-items-center category-type position-absolute'>


                    <div className="d-lg-flex category-type1 flex-wrap gap-lg-3 gap-md-0 mt-3">
                        {categories.map((category, index) => (
                            <div key={index}>
                                {isHovered === index && (
                                    <div
                                        className={`position-absolute ${index === 0 ? 'special-hover-style' : 'special-hover-style1'}`}
                                        onMouseLeave={handleMouseLeave}
                                        style={{
                                            marginTop: '-40px',
                                            width: '190px', /* Adjust the size of the circle */
                                            height: '200px',
                                            borderRadius: '50%',
                                            cursor: 'pointer',
                                            position: 'relative',
                                        }}
                                    >
                                        {categoriesproduct.map((subcategory, subIndex) => {
                                            const angle = categoriesproduct.length > 5
                                                ? (360 / categoriesproduct.length) * subIndex
                                                : (120 / categoriesproduct.length) * subIndex;
                                            const radius = 100; // Radius of the circle

                                            const x = radius * Math.cos((angle * Math.PI) / 180); // X position
                                            const y = radius * Math.sin((angle * Math.PI) / 180); // Y position

                                            return (
                                                <div
                                                    key={subIndex}
                                                    className="category-home1 mt-4"
                                                    // onClick={() => navToViewPage()}
                                                    style={{
                                                        position: 'absolute',
                                                        left: `calc(50% + ${x}px)`,
                                                        top: `calc(50% - ${y}px)`,
                                                        transform: 'translate(-50%, -50%)',
                                                    }}
                                                >
                                                    <img
                                                        className='iconimg'
                                                        style={{ width: '25px', height: '25px', borderRadius: '50%' }} // Circular images
                                                        src={subcategory.image}
                                                        alt={subcategory.label}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}

                                <div
                                    style={{ cursor: 'pointer' }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    // onClick={() => navToViewPage()}
                                    className={`category-home ${index !== 0 ? 'category-home' : ''}`}
                                >
                                    <div>
                                        <img src={category.image} alt={category.label} />
                                    </div>
                                    <div className="text-center category-text">
                                        <p>{category.label}</p>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>

                </div>
            </Col>

            <div className='all-category'>
                <Col lg={12} md={12} sm={12}>
                    <div className='d-flex justify-content-center align-items-center pt-3 pt-lg-5 mb-3 mb-lg-5'>
                        <div className='d-flex categ11'>
                            <div>
                                <img className='img1' src={categoryLeft} alt="" />
                            </div>
                            <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                                <h3 className='pp' style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>All Categories</h3>
                            </div>
                            <div>
                                <img className='img1' src={categoryRight} alt="" />
                            </div>
                        </div>
                    </div>
                </Col>
                <div style={{ paddingRight: '5%', paddingLeft: '5%' }}>
                    <Row>
                        {eightData?.map((eachItem, index) => (
                            <Col key={index} className='d-flex' xl={3} lg={4} md={6} sm={12}>

                                <div onClick={() => navigate(`/products/${eachItem?.category_url}`)} className='mb-3 mb-lg-5 ms-2 cursor-pointer' style={{ padding: '13px', border: '1px solid #F0F0F0', borderRadius: '15px', background: 'white', cursor: 'pointer' }}>
                                    <img style={{ borderRadius: '4px', width: '100%' }} src={eachItem?.image} className="img-fluid" alt="" />
                                    <div className='mt-2'>
                                        <h6 style={{ font: 'Lato', fontSize: '18px', fontw: '500' }}>{eachItem?.name}</h6>
                                        <p
                                            className="m-0 pt-1 pb-2"
                                            // onClick={toggleDescription}
                                            style={{
                                                cursor: "pointer",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                fontSize: '16px',
                                                color: '#858585'
                                            }}
                                            title={eachItem?.description}
                                        >
                                            {showFullDescription
                                                ? eachItem?.description
                                                : truncateDescription(eachItem?.description, 4)}
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <p style={{ color: '#37A003', font: 'Lato', fontWeight: '400', fontSize: '14px' }}>Starts From</p>
                                            <h2 className='category-card-amt'
                                                style={{
                                                    fontSize: `${eachItem?.minimumPrice &&
                                                        eachItem.minimumPrice.toString().length > 1
                                                        ? eachItem.minimumPrice.toString().length > 4
                                                            ? "18px"
                                                            : "24px"
                                                        : "24px"
                                                        }`,
                                                    textAlign: `${eachItem?.minimumPrice &&
                                                        eachItem.minimumPrice.toString().length === 1
                                                        ? "strat"
                                                        : "start"
                                                        }`,
                                                }}
                                            >
                                                ₹{eachItem?.minimumPrice}
                                            </h2>
                                        </div>
                                        <Link
                                            to={`/products/${eachItem?.category_url}`}
                                            className="mt-2 product-card-link"
                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <div>
                                                <p className='explore' style={{ marginTop: '8px' }}>Explore Now<FaAngleRight className='d-flex justify-content-center align-items-center mt-1' size={15} /></p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                            </Col>
                        ))}
                    </Row>
                    <p
                        className="view-all-fast-1"
                        onClick={() => navigate("/category")}
                    >
                        View All Category <ArrowRight className=" ms-2 mt-2" />
                    </p>
                </div>
            </div>
            <Col lg={12} md={12} sm={12}>
                <div className='bannerContain'>
                    <div className="banner-container">
                        <img style={{ height: '450px', position: 'relative', width: '1000px' }} className='img-fluid' src={banner2} alt="" />
                    </div>
                    <div className="banner-container">
                        <img style={{ position: 'absolute', marginTop: '-115px' }} src={banner3} alt="" />
                    </div>
                    <div className="banner-container">
                        <img style={{ position: 'absolute', marginTop: '-450px', marginLeft: '70px' }} src={banner4} alt="" />
                    </div>
                    <div className="banner-container">
                        <img style={{ position: 'absolute', marginTop: '-103px', right: '0' }} src={banner5} alt="" />
                    </div>
                    <div className="banner-container">
                        <img className='d-md-none' style={{ position: 'absolute', marginTop: '-380px', marginLeft: '1080px' }} src={banner6} alt="" />
                    </div>
                    <div className="banner-container">
                        <img style={{ position: 'absolute', marginTop: '-120px', marginLeft: '700px' }} src={banner6} alt="" />
                    </div>
                    <div className="banner-container">
                        <img className='d-md-none' style={{ position: 'absolute', marginTop: '-430px', marginLeft: '1400px' }} src={banner7} alt="" />
                    </div>
                    <div className="banner-container">
                        <img className='d-md-none' style={{ position: 'absolute', marginTop: '-80px', marginLeft: '1100px' }} src={banner8} alt="" />
                    </div>
                    <div className="banner-container">
                        <img className='d-md-none' style={{ position: 'absolute', marginTop: '-260px', marginLeft: '950px' }} src={banner9} alt="" />
                    </div>
                    <div className='banner1 pe-4'>
                        <img className='banner2 img-fluid' style={{ width: '400px', position: 'absolute' }} src={banner1} alt="" />
                    </div>
                    <div style={{ paddingLeft: '8%', position: 'absolute', marginTop: '90px' }} className='d-block'>
                        <div>
                            <h1 className='banner-container1'>Unbeatable Deals Await At <br /> Your  Trusted Printon</h1>
                        </div>
                        <div className='mt-4'>
                            <p className='banner-container2'>Gift Smart With 2024 Custom Diaries, Notebooks,Calendars...etc</p>
                        </div>
                        <div className='mt-4'>
                            <Link to={`/products`}>
                                <button style={{ padding: '5px 10px 5px 10px', width: '150px', border: 'none', background: '#E4510B', borderRadius: '4px', color: 'white' }}>Order Now</button>
                            </Link>
                        </div>
                    </div>

                </div>
            </Col>
            <Col lg={12} md={12} sm={12}>
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='mt-5 d-flex'>
                        <div>
                            <img className='img1' src={categoryLeft} alt="" />
                        </div>
                        <div className=' ms-2 me-2 mt-2 d-flex justify-content-center align-items-center'>
                            <h3 className='pp' style={{ fontWeight: '400', fontSize: '40px', font: 'Manrope' }}>How it works</h3>
                        </div>
                        <div>
                            <img className='img1' src={categoryRight} alt="" />
                        </div>
                    </div>
                </div>
            </Col>
            <Col>
                <div className='container mt-4 mt-lg-5 d-lg-flex justify-content-center flex-wrap gap-lg-4'
                    style={{ paddingRight: '5%', paddingLeft: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    {/* First Row */}
                    <div className='d-flex'>
                        <div className='d-flex me-5 me-lg-0 flex-column justify-content-center align-items-center'>
                            <div className='ms-4 d-flex justify-content-center align-items-center work-icon'>
                                <img src={choose} alt="" />
                            </div>
                            <div className='mt-4'>
                                <p className='work-text'>Choose Product</p>
                            </div>
                        </div>
                        <div className='ms-1 mt-5 '>
                            <img className='arrow' src={arrow} alt="" />
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <div className='ms-4 d-flex justify-content-center align-items-center work-icon'>
                                <img src={spec} alt="" />
                            </div>
                            <div className='mt-4'>
                                <p className='work-text'>Spec Product</p>
                            </div>
                        </div>
                        <div className='ms-1 mt-5 '>
                            <img className='arrow' src={arrow} alt="" />
                        </div>
                    </div>

                    {/* Second Row */}
                    <div className='d-flex mt-4 mt-lg-0'>
                        <div className='d-flex me-5 me-lg-0 flex-column justify-content-center align-items-center'>
                            <div className='ms-4 d-flex justify-content-center align-items-center work-icon'>
                                <img src={upload} alt="" />
                            </div>
                            <div className='mt-4'>
                                <p className='work-text'>Upload Artwork</p>
                            </div>
                        </div>
                        <div className='ms-1 mt-5 '>
                            <img className='arrow' src={arrow} alt="" />
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center ab-icon'>
                            <div className='ms-4 d-flex justify-content-center align-items-center work-icon'>
                                <img src={checkout} alt="" />
                            </div>
                            <div className='mt-4 ms-3 d-flex justify-content-center'>
                                <p className='work-text'>Checkout</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Col>
            <Col className='mt-3 mt-lg-5 mb-5'>
                <Link style={{ textDecoration: 'none' }} to={`/products`}>
                    <div className='d-flex justify-content-end' style={{ paddingRight: '5%' }}>
                        <div className='d-flex flex-row align-items-center justify-content-center' style={{ background: '#E4510B', color: 'white', paddingRight: '10px', paddingLeft: '10px', height: '40px', borderRadius: '4px', width: '200px' }}>
                            <p className='mt-3' style={{ marginTop: '8px' }}>Lets Go Printing <FaAngleRight size={15} /></p>
                        </div>
                    </div>
                </Link>
            </Col>
            <ChooseProductModal isOpen={chooseModal} toggle={() => setChooseModal(!chooseModal)} />
        </>
    )
}

export default Landing