import React, { useEffect, useState } from 'react'
import { deleteDistrict1, deletePincode, deliveryPincode, locationStatus, statusUpdate } from '../../../../ApiConfigs/ApiConfig';
import { FaPlus } from "react-icons/fa6";
import DataTable from 'react-data-table-component';
import { Edit2, PlusCircle, Trash } from 'react-feather';
import axios from 'axios'
import '../index.css'
import { toast } from 'react-hot-toast'
import { Button, FormFeedback, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'
import Select from 'react-select';
import { GrPowerReset } from "react-icons/gr";
import { FiChevronRight } from 'react-icons/fi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BiEditAlt } from "react-icons/bi";
import { GoTrash } from "react-icons/go";
import Navbar from '../../../navbar';
import FooterSection from '../../../footer';
import AddLocation from './addLocation';
import EditLocation from './EditLocation';
import { Controller, Form } from 'react-hook-form';
import AddPincode from './addPincode';

const DeliveryLocation = () => {
    const { id } = useParams();
    const [addModal, setModal] = useState(false);
    const location = useLocation();
    const [customizeLocations, setCustomizeLocations] = useState();
    const toggleAdd = () => setModal(!addModal);
    const [editModal, setEditModal] = useState(false);
    const toggleEdit = () => setEditModal(!editModal);
    const [addPincodeModal, setAddPincodeModal] = useState(false);
    const toggleAddPincode = () => setAddPincodeModal(!addPincodeModal);
    const navigate = useNavigate()
    const [searchKey, setSearchKey] = useState('');
    const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const [deleteData, setDeleteData] = useState();
    const [districtId, setDistrictId] = useState();
    const [deleteDistrictData, setDeleteDistrictData] = useState();
    const [deleteDistrict, setDeleteDistrict] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteDistrictModal, setDeleteDistrictModal] = useState(false);
    const [deletePincodeModal, setDeletePincodeModal] = useState(false);
    const [addDistrictModal, setAddDistrictModal] = useState(false);
    const [selectedData, setSelectedData] = useState('')
    const [stateId, setStateId] = useState('')

    const closeDeleteModal = () => {
        setDeleteModal(false);
    }

    const closeDeleteDistrictModal = () => {
        setDeleteDistrictModal(false);
    }

    const closeDeletePincodeModal = () => {
        setDeletePincodeModal(false);
    }

    const closeAddDistrictModal = () => {
        setAddDistrictModal(false);
    }

    // Over All get field type
    const additionalData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${deliveryPincode}?product=${id}&sortBy=${sorts}&search=${searchKey}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error.response.data.msg)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (localStorage.getItem('token')) {

            additionalData()
        }
    }, [localStorage.getItem('token')])

    useEffect(() => {
        additionalData(searchKey)

    }, [sorts, searchKey])

    useEffect(() => {
        updateStatus2()

    }, [])

    const updateStatus2 = async () => {

        try {
            const response = await axios.get(`${locationStatus}/${id}`,);
            setCustomizeLocations(response?.data?.result?.customizeLocation)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const handleReset = () => {
        setSearchKey('')
        setSorts('');
        additionalData();
        setResetKey((prevKey) => prevKey + 1);
    };

    const openAddModal = (row) => {
        setSelectedData(row)
        setStateId(row?._id)
        setEditModal(true);
    };

    const openAddPincodeModal = (row) => {
        setSelectedData(row)
        setStateId(row?._id)
        setAddPincodeModal(true);
    };

    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }

    const openDeleteDistrictModal = (id, option) => {
        setDeleteData(id);
        setDeleteDistrictModal(true);
        setDeleteDistrict(option);
    }

    const openDeletePincodeModal = (id, option) => {
        setDeleteData(id);
        setDeletePincodeModal(true);
        setDeleteDistrict(option);
    }

    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${deliveryPincode}/${deleteData}`)
            closeDeleteModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const handleConfirmDeleteDistrict = async () => {
        try {
            const response = await axios.delete(`${deleteDistrict1}/${deleteData}?district=${deleteDistrict}`)

            closeDeleteDistrictModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const handleConfirmDeletePincode = async () => {
        try {
            const response = await axios.delete(`${deletePincode}/${deleteData}?pincode=${deleteDistrict}`)
            closeDeletePincodeModal()
            additionalData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }

    const categorySortData = [
        {
            name: "latest",
            value: "latest"
        },
        {
            name: "oldest",
            value: "oldest"
        }
    ]

    const updateStatus = async (id) => {

        try {
            const response = await axios.post(`${statusUpdate}/${id}`,);
            additionalData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const updateStatus1 = async (id, newStatus) => {

        try {
            const response = await axios.post(`${locationStatus}/${id}`);
            updateStatus2()
            additionalData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    return (
        <>
            <Navbar />
            <div style={{ background: '#F8F8F8' }}>
                <div className='pt-1' style={{ paddingLeft: '8%', paddingRight: '8%' }}>
                    <div>
                        <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ opacity: '0.6' }} onClick={() => navigate('/admin/product')}>Products </span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}> Add Delivery Locations</span></p>
                    </div>
                    <div style={{ width: '100%' }} className='d-flex align-items-center'>
                        <h1 className='product-overall-title' style={{ font: 'Manrope', }}>Delivery Locations</h1>
                        <hr className='ms-3 call2' style={{ height: '2px', width: '100%', background: 'black' }} />
                    </div>
                </div>
                <div className='pt-2' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '15%' }}>

                    <div
                        className='pb-2 d-block'
                        style={{
                            display: 'flex',
                            flexDirection: 'column', // Ensure vertical alignment of content
                            marginBottom: '-30px',
                            background: 'white',
                            border: '1px solid #DFDFDF',
                            borderRadius: '12px',
                            minHeight: '80px' // Set a minimum height instead of a fixed height
                        }}
                    >
                        <div style={{ borderBottom: '1px solid #DFDFDF' }}>
                            <div className='d-flex align-items-center justify-content-between p-3'>
                                <div className='d-flex align-items-center'>
                                    <div className='red-line me-2'></div>
                                    <h1 className='p-0 m-0 h5'>Delivery Location List</h1>
                                </div>
                                <div className="switch d-flex align-items-center">
                                    <label className="switch-label">
                                        <input
                                            type="checkbox"
                                            className="switch-input"
                                            id="switch-success"
                                            name="success"
                                            checked={customizeLocations} // Control the checkbox based on the state
                                            onChange={async (e) => {
                                                const newStatus = e.target.checked; // Get the new checkbox state
                                                const result = window.confirm(
                                                    `Are you sure you want to make it ${newStatus ? 'Active' : 'Inactive'}?`
                                                );
                                                if (result) {
                                                    await updateStatus1(id, newStatus); // Pass the new status to the update function
                                                } else {
                                                    e.preventDefault(); // Prevent the checkbox from changing state if action is canceled
                                                }
                                            }}
                                        />
                                        <span className="slider"></span>
                                    </label>
                                    <span
                                        className="ms-2 d-flex"
                                        style={{
                                            color: customizeLocations ? 'green' : 'red', // Green for active, red for inactive
                                            fontWeight: '400',
                                            fontSize: '14px',
                                            flexDirection: 'row' // Horizontal alignment
                                        }}
                                    >
                                        {customizeLocations ? 'Active' : 'Inactive'} {/* Display Active/Inactive */}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex p-4'>
                            <div style={{ display: 'flex' }}>
                                <form className="search me-2">
                                    <input
                                        type="text"
                                        id="search-bar"
                                        key={`status${resetKey}`}
                                        placeholder="Search Name"
                                        onChange={(e) => setSearchKey(e.target.value)}
                                        style={{ color: 'black', border: '1px solid #BEBEBE', paddingRight: '50px' }}
                                    />
                                    <a href="#">
                                        <img
                                            style={{ left: '-300px', color: '#333333', opacity: '0.5' }}
                                            className="search-icon"
                                            src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png"
                                        />
                                    </a>
                                </form>
                            </div>
                            <div className="ms-4">
                                <Select
                                    className="react-select me-2"
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 }),
                                        border: '1px solid #BEBEBE'
                                    }}
                                    key={`status${resetKey}`}
                                    type='select'
                                    options={categorySortData?.map((item) => ({
                                        label: item?.name,
                                        value: item?.value
                                    }))}
                                    placeholder='Sort By'
                                    onChange={(selectedOption) => setSorts(selectedOption.value)}
                                />
                            </div>
                            <div style={{ marginRight: "15px", marginTop: '10px', color: '#e4510b' }}>
                                <GrPowerReset size={25} onClick={handleReset} />
                            </div>
                            <div style={{ marginLeft: 'auto' }}>
                                <button className='productCategoryBtn' onClick={toggleAdd}><FaPlus /> Add Location</button>
                            </div>
                        </div>
                    </div>

                    {data?.rows?.map((field, index) => (
                        <div
                            key={field?._id} // Ensure to provide a unique key for each element
                            className='mt-5 d-block'
                            style={{
                                display: 'flex',
                                marginBottom: '-30px',
                                background: 'white',
                                border: '1px solid #DFDFDF',
                                borderRadius: '12px',
                                minHeight: '250px' // Set a minimum height to ensure the box has some size even with less content
                            }}
                        >
                            <div className='d-flex' style={{ width: '100%' }}>
                                <div
                                    className='p-4 d-flex justify-content-center align-items-start'
                                    style={{ borderRight: '1px solid #DFDFDF', minHeight: '250px' }} // Ensure the left section has minimum height
                                >
                                    <p style={{ fontSize: '20px', fontFamily: 'Lato', fontWeight: '500', color: '#333333' }}>
                                        {(index + 1).toString().padStart(2, '0')}
                                    </p> {/* Adding serial number */}
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ borderBottom: '1px solid #DFDFDF' }} className='d-flex justify-content-between p-3'>
                                        <div className='d-flex'>
                                            <p style={{ color: '#333333', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                                State :
                                            </p>
                                            <p style={{ color: '#333333', fontSize: '18px', fontFamily: 'Lato', fontWeight: '600' }} className='ms-3'>
                                                {field?.state}
                                            </p>
                                        </div>
                                        <div className='d-flex'>
                                            {/* <Button style={{ background: '#EEEEEE', border: 'none' }} onClick={() => openEditModal(field)}>
                                                <BiEditAlt color='#4E4E4E' size={25} />
                                            </Button> */}
                                            <div className="switch d-flex align-items-center">
                                                <label className="switch-label">
                                                    <input
                                                        type="checkbox"
                                                        className="switch-input"
                                                        id="switch-success"
                                                        name="success"
                                                        // onChange={async (e) => {
                                                        //     // Directly update the status without confirmation
                                                        //     await updateStatus(row._id);
                                                        // }}
                                                        onChange={async (e) => {
                                                            const result = window.confirm(
                                                                `Are you sure you want to make as ${field?.status === 'active' ? 'Inactive' : 'Active'}`
                                                            );
                                                            if (result) {
                                                                await updateStatus(field?._id);
                                                            } else {

                                                                e.target.checked = !e.target.checked;
                                                            }
                                                        }}
                                                        defaultChecked={field?.status === 'active'}
                                                    />

                                                    <span className="slider"></span>
                                                </label>
                                                <span
                                                    className="ms-2 d-flex"
                                                    style={{
                                                        color: field?.status === 'active' ? 'green' : 'red',
                                                        fontWeight: '400',
                                                        fontSize: '14px',
                                                        flexDirection: 'row' // Ensure horizontal alignment
                                                    }}
                                                >
                                                    {field?.status === 'active' ? 'Active' : 'Inactive'}
                                                </span>
                                            </div>
                                            <Button className='ms-3' style={{ background: '#D5383826', border: 'none' }} onClick={() => openDeleteModal(field?._id)} >
                                                <GoTrash color='#E42525' size={25} />
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{ borderBottom: '1px solid #DFDFDF' }} className='d-flex p-3'>
                                        <div className='d-flex p-2'>
                                            <p style={{ color: '#333333', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                                District  :
                                            </p>
                                        </div>
                                        <div className='d-flex flex-wrap'> {/* Use flex-wrap to handle dynamic content */}
                                            {field?.districts?.map((option) => (
                                                <div key={option?._id} className='d-flex ms-2'>
                                                    <p style={{ color: '#333333', fontSize: '16px', fontFamily: 'Lato', fontWeight: '500', background: '#F6F6F6' }} className='ms-3 p-2'>
                                                        {option} {/* This might be the cause if `option` is an object */}
                                                    </p>
                                                    <div>
                                                        <Button className='ms-2' style={{ background: '#D5383826', border: 'none' }} onClick={() => openDeleteDistrictModal(field?._id, option)}>
                                                            <GoTrash color='#E42525' size={25} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button style={{ width: '45px', height: '40px' }} onClick={() => openAddModal(field)} className='productCategoryBtn ms-2'>
                                                    <FaPlus />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex p-3'>
                                        <div className='d-flex p-2'>
                                            <p style={{ color: '#333333', opacity: '0.5', fontSize: '18px', fontFamily: 'Lato', fontWeight: '500' }}>
                                                Unavailable Pincodes  :
                                            </p>
                                        </div>
                                        <div className='d-flex flex-wrap'> {/* Use flex-wrap for better layout with dynamic content */}
                                            {field?.exceptPincodes?.map((option) => (
                                                <div key={option?._id} className='d-flex ms-2'>
                                                    <p style={{ color: '#333333', fontSize: '16px', fontFamily: 'Lato', fontWeight: '500', background: '#F6F6F6' }} className='ms-3 p-2'>
                                                        {option?.pincode} {/* Render a specific property */}
                                                    </p>
                                                    <div>
                                                        <Button className='ms-2' style={{ background: '#D5383826', border: 'none' }} onClick={() => openDeletePincodeModal(field?._id, option?.pincode)}>
                                                            <GoTrash color='#E42525' size={25} />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            <div style={{ marginLeft: 'auto' }}>
                                                <button style={{ width: '45px', height: '40px' }} onClick={() => openAddPincodeModal(field)} className='productCategoryBtn ms-2'>
                                                    <FaPlus />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div >
            </div>


            <AddLocation modal={addModal} toggle={toggleAdd} additionalData={additionalData} id={id} />
            <EditLocation modal={editModal} toggle={toggleEdit} editId={stateId} editData={selectedData} additionalData={additionalData} id={id} />
            <AddPincode modal={addPincodeModal} toggle={toggleAddPincode} editId={stateId} editData={selectedData} additionalData={additionalData} id={id} />

            <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeDeleteModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-2'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}> Please confirm that you want to delete the Delivery Location. This action will remove the type and all associated districts and pincodes. Ensure that thi location is no longer needed, as this change cannot be undone. </p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="p-3 d-flex">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeDeleteModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmDelete} >
                        Delete Location
                    </Button>
                </div>
            </Modal >
            <Modal isOpen={deleteDistrictModal} toggle={closeDeleteDistrictModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeDeleteDistrictModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-2'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}> Please confirm that you want to delete the District from Delivery Location. This action will remove the district . Ensure that thi location is no longer needed, as this change cannot be undone. </p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="p-3 d-flex">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeDeleteDistrictModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmDeleteDistrict} >
                        Delete District
                    </Button>
                </div>
            </Modal >
            <Modal isOpen={deletePincodeModal} toggle={closeDeletePincodeModal} className="modal-dialog-centered modal-xs">
                <ModalHeader style={{ paddingLeft: '20px', fontFamily: 'italic', textAlign: 'center' }} toggle={closeDeletePincodeModal}>
                    <h5>Confirmation</h5>
                </ModalHeader>
                <ModalBody style={{ fontSize: '20px', paddingTop: '30px', fontWeight: '400' }}>
                    <div className='d-bloick mt-2'>
                        <p style={{ color: '#D34C4D', fontWeight: '600', fontFamily: 'Manrope', fontSize: '16px' }}>Note*</p>
                        <p style={{ color: '#333333', opacity: '0.8', fontSize: '16px', fontWeight: '400' }}> Please confirm that you want to delete the pincode from Delivery Location. This action will remove the pincode . Ensure that thi location is no longer needed, as this change cannot be undone. </p>
                    </div>
                </ModalBody>
                <div style={{ borderTop: '1px solid #EEEEEE' }} className="p-3 d-flex">
                    <Button className='w-50' style={{ color: '#E4510B', border: '1px solid #E4510B', background: 'white' }} onClick={closeDeletePincodeModal} >
                        Cancel
                    </Button>
                    <Button className='w-50 ms-2' style={{ backgroundColor: "#EA5455", border: 'none' }} onClick={handleConfirmDeletePincode} >
                        Delete Pincode
                    </Button>
                </div>
            </Modal >
            <FooterSection />
        </>
    )
}

export default DeliveryLocation