import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
        const isCustomizationPage = pathname.startsWith('/products/productCustomize')
        if (!isCustomizationPage) {
            localStorage.removeItem('selectedOptions')
            localStorage.removeItem('quantity')
            localStorage.removeItem('customizeImageSize')
            localStorage.removeItem('selectedColorIndex')
        }
    }, [pathname]);

    return null;
}

export default ScrollToTop;
