import React, { useEffect, useState } from 'react'
import { menuProductAd, menusStatus } from '../../../ApiConfigs/ApiConfig';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Edit2, Plus, RotateCcw, Trash } from 'react-feather';
import DataTable from 'react-data-table-component';
import { Button, Modal, Input, ModalBody, Spinner, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import AddCombo from './AddCombo';
import EditCombo from './EditCombo';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import { GrPowerReset } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { BiEditAlt } from 'react-icons/bi';
import { GoTrash } from 'react-icons/go';
import { RiArrowDropDownLine } from "react-icons/ri";

function Combo() {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editData, setEditData] = useState([])
    const [editModal, setEditModal] = useState(false)
    const [deleteData, setDeleteData] = useState();
    const handleAdd = () => setAddModal(!addModal)
    const [addModal, setAddModal] = useState(false);
    const [resetKey, setResetKey] = useState(0);
    const [sorts, setSorts] = useState('')
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKey, setSearchKey] = useState('');



    const toggleDescription = () => {
        setShowFullDescription(!showFullDescription);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const handleShowMore = () => setShowMore(!showMore);

    const truncateDescription = (description, maxLength) => {
        return description.length > maxLength
            ? `${description.slice(0, maxLength)}...`
            : description;
    };


    const menuData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${menuProductAd}?search=${searchKey}&currentPage=${currentPage > 0 ? currentPage - 1 : currentPage}&perPage=${rowsPerPage}&sortBy=${sorts}`)
            setData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        } finally {
            setLoading(false);
        }
    }



    const openDeleteModal = (id) => {
        setDeleteData(id);
        setDeleteModal(true);
    }
    const closeDeleteModal = () => {
        setDeleteModal(false);
    }
    const handleConfirmDelete = async () => {
        try {
            const response = await axios.delete(`${menuProductAd}/${deleteData}`)
            closeDeleteModal()
            menuData()
            toast.success(response?.data?.msg)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    }


    const openEditModal = (id) => {
        getEditModal(id)
        setEditModal(true);
    };

    const closeEditModal = () => {
        setEditModal(false);
    };

    const getEditModal = async (id) => {
        try {
            const response = await axios.get(`${menuProductAd}/${id}`)
            setEditData(response?.data?.result)
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }

    }



    useEffect(() => {
        menuData(searchKey)
    }, [sorts, searchKey, currentPage, rowsPerPage])

    const updateStatus = async (id) => {
        try {
            const response = await axios.post(`${menusStatus}/${id}`);
            menuData()
            toast.success(response?.data?.msg);
        } catch (error) {
            toast.error(error?.response?.data?.msg)
        }
    };

    const handleReset = () => {
        setSearchKey('');
        setSorts('');
        menuData();
        setResetKey((prevKey) => prevKey + 1);
        // setResetInputKey((prevKey) => prevKey + 1);
    };

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#FCEDE6',
                color: '#333333',
                fontWeight: '500',
                fontSize: '16px',
            },
        },
    };

    const categorySortData = [
        {
            name: "latest",
            value: "latest"
        },
        {
            name: "oldest",
            value: "oldest"
        }
    ]


    const additionalTable = [
        {
            name: 'S No',
            minWidth: '50px',
            cell: (row, i) =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{(i + 1).toString().padStart(2, '0')}</p>
                </>

        },
        // {
        //     name: 'Image',
        //     sortable: false,
        //     minWidth: '150px',
        //     cell: row => 
        // },
        {
            name: 'Menu Name',
            sortable: false,
            minWidth: '250px',
            cell: row =>
                <>
                    <p style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Lato' }}>{row?.name}</p>
                </>
        },
        {
            name: 'Menu Image & Description',
            sortable: false,
            minWidth: '300px',
            cell: row =>
                <>
                    <img className='d-flex align-items-center' src={row?.image} height={60} width={60} alt="" style={{ padding: '5px' }} />
                    <span className='mt-2 mb-2' style={{ cursor: 'pointer' }}>
                        {showFullDescription ? row?.description : truncateDescription(row?.description, 40)}</span>,
                </>
        },
        {
            name: 'product',
            sortable: false,
            minWidth: '200px',
            cell: row =>
                <>
                    {/* Display the first product name */}
                    <span className='me-2'>{row?.category[0]?.products[0]?.name}</span>

                    {/* Check if there are more categories and products to display */}
                    {row?.category.length > 1 && (
                        <>
                            {/* Show how many additional categories exist */}


                            {/* Dropdown to display more products */}
                            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                                <DropdownToggle style={{ color: 'black', background: 'white' }}>
                                    <span > + {row?.category?.length - 1}</span><RiArrowDropDownLine />
                                </DropdownToggle>
                                <DropdownMenu>
                                    {/* Display products from the additional categories */}
                                    {row?.category.slice(1).map((category, index) => (
                                        <DropdownItem key={index}>
                                            {/* Loop through the products of each category */}
                                            {category?.products?.map((product, prodIndex) => (
                                                <p key={prodIndex}>{product?.name}</p>
                                            ))}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </Dropdown>
                        </>
                    )}
                </>


        },
        {
            name: 'Status',
            maxWidth: '200px',
            cell: (row) => (
                <>
                    {/* <div class="switch">
                        <Input
                            type="switch"
                            id="switch-success"
                            name="success"
                            onChange={async (e) => {
                                const result = window.confirm(
                                    `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                                );
                                if (result) {
                                    await updateStatus(row._id);
                                } else {

                                    e.target.checked = !e.target.checked;
                                }
                            }}
                            defaultChecked={row.status === 'active'}
                        />
                    </div> */}
                    <div className="switch d-flex align-items-center">
                        <label className="switch-label">
                            <input
                                type="checkbox"
                                className="switch-input"
                                id="switch-success"
                                name="success"
                                onChange={async (e) => {
                                    const result = window.confirm(
                                        `Are you sure you want to make as ${row.status === 'active' ? 'Inactive' : 'Active'}`
                                    );
                                    if (result) {
                                        await updateStatus(row._id);
                                    } else {

                                        e.target.checked = !e.target.checked;
                                    }
                                }}
                                defaultChecked={row.status === 'active'}
                            />

                            <span className="slider"></span>
                        </label>
                        <span
                            className="ms-2 d-flex"
                            style={{
                                color: row.status === 'active' ? 'green' : 'red',
                                fontWeight: '400',
                                fontSize: '14px',
                                flexDirection: 'row' // Ensure horizontal alignment
                            }}
                        >
                            {row.status === 'active' ? 'Active' : 'Inactive'}
                        </span>
                    </div>

                </>
            )
        },

        {
            name: "Action",
            minWidth: "200px",
            cell: (row) => (
                <>
                    <button className='ms-2' style={{ background: '#EEEEEE', border: 'none', width: '40px', height: '40px' }} onClick={() => openEditModal(row?._id)}>
                        <BiEditAlt color='#4E4E4E' size={25} />
                    </button>
                    <button className='ms-2' style={{ background: '#D5383826', border: 'none', width: '40px', height: '40px' }} onClick={() => openDeleteModal(row?._id)}>
                        <GoTrash color='#E42525' size={25} />
                    </button>
                </>
            )

        }
    ];

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const CustomPagination = (pageCount) => {
        return (
            <>
                <ReactPaginate
                    nextLabel="Next"
                    breakLabel="..."
                    previousLabel="Prev"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePagination}
                    containerClassName="pagination justify-content-end p-1"
                    activeClassName="active"
                    pageClassName="page-item"
                    breakClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    forcePage={currentPage - 1}
                />
            </>
        );
    };



    return (
        <>

            <div className='pt-2' style={{ paddingLeft: '8%', paddingRight: '8%', paddingBottom: '10%' }}>
                <div>
                    <p className='pt-3' style={{ cursor: 'pointer', marginLeft: '10px', color: '#333333' }}><span style={{ opacity: '0.6' }} onClick={() => navigate('/')}>Home</span><span style={{ opacity: '0.6' }}><FiChevronRight /></span><span style={{ color: '#E4510B' }}>Menu Combo</span></p>
                </div>
                <div className='mt-4 order-table-con  shadow-sm'>
                    <div className='d-flex align-items-center justify-content-between p-3'>
                        <div className='d-flex align-items-center'>
                            <div className='red-line me-2'></div>
                            <h1 className='p-0 m-0 h5'>Menu Combo List</h1>
                        </div>
                        <span onClick={handleReset} className='cursor-pointer'><RotateCcw color='#E4510B' /></span>
                    </div>
                    <div className='line-hr-new'></div>
                    <div className='p-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <div className='d-flex gap-3'>
                                <div className='d-flex flex-column'>
                                    <label style={{ color: '#333333' }} className='fw-medium'>Search</label>
                                    <input
                                        type='text'
                                        className='search-order'
                                        placeholder='Search by Menu combo name'
                                        value={searchKey}
                                        onChange={(e) => setSearchKey(e.target.value)}
                                    />
                                </div>
                                <div className='d-flex'>
                                    <div className='d-flex flex-column'>
                                        <label style={{ color: '#333333' }} className='fw-medium'>Sort By</label>
                                        <select
                                            className='select-order'
                                            value={sorts}
                                            onChange={(e) => setSorts(e.target.value)}
                                        >
                                            <option value='latest'>Latest</option>
                                            <option value='oldest'>Oldest</option>
                                            {/* Add more options as needed */}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <button className='productCategoryBtn mt-3' onClick={handleAdd}><Plus size={20} /> Add Product</button>
                        </div>
                        {loading ? (
                            <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Spinner
                                    color="primary"
                                >
                                    Loading...
                                </Spinner>
                            </div>
                        ) : (
                            <DataTable
                                pagination
                                paginationServer
                                noHeader
                                highlightOnHover
                                fixedHeader
                                fixedHeaderScrollHeight='130vh'
                                data={data?.rows}
                                columns={additionalTable}
                                paginationDefaultPage={currentPage}
                                customStyles={customStyles}
                                paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                            />
                        )}
                    </div>
                </div>
                {/* <div style={{ display: 'flex', marginBottom: '-30px' }}>
                    <h1>MENU PRODUCT'S</h1>
                    <div className='total-count'> {data?.pagination?.total}</div>
                    <div className="ms-4" >
                        <Select
                            className="react-select me-2"
                            styles={{
                                menu: provided => ({ ...provided, zIndex: 9999, }),
                            }}
                            key={`status${resetKey}`}
                            type='select'
                            options={categorySortData?.map((item) => ({
                                label: item?.name,
                                value: item?.value
                            }))}
                            placeholder='Sort By'
                            onChange={(selectedOption) => setSorts(selectedOption.value)}
                        />
                    </div>
                    <div style={{ marginRight: "20px", marginTop: '10px', color: '#e4510b' }}>
                        <GrPowerReset size={25} onClick={handleReset} />
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                        <form className="search me-2"
                        >
                            <input
                                type="text"
                                id="search-bar"
                                placeholder="Search Name"
                                onChange={(e) => setSearchKey(e.target.value)}
                                style={{ color: 'black', borderColor: 'black', paddingRight: '50px' }}
                            />
                            <a href="#"><img className="search-icon" src="http://www.endlessicons.com/wp-content/uploads/2012/12/search-icon.png" /></a>
                        </form>
                        <button className='addProductBtn' onClick={handleAdd}>Add Menu</button>
                    </div>
                </div>
                {loading ? (
                    <div style={{ height: "50vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner
                            color="primary"
                        >
                            Loading...
                        </Spinner>
                    </div>
                ) : (
                    <DataTable
                        noHeader
                        pagination
                        paginationServer
                        highlightOnHover
                        fixedHeader
                        fixedHeaderScrollHeight='130vh'
                        data={data?.rows}
                        columns={additionalTable}
                        paginationDefaultPage={currentPage}
                        paginationComponent={() => CustomPagination(data?.pagination?.pages)}
                    />
                )} */}
                {/* add modal */}
                <AddCombo open={addModal} handleAdd={handleAdd} menuData={menuData} />

                {/* edit modal */}
                <EditCombo open={editModal} handleEdit={closeEditModal} editData={editData} menuData={menuData} />
                {/* delete modal */}
                <Modal isOpen={deleteModal} toggle={closeDeleteModal} className="modal-dialog-centered modal-xs">
                    <ModalBody style={{ fontSize: '20px', textAlign: 'center', paddingTop: '30px', fontWeight: '400' }}>Are you sure you want to delete this menu combo?</ModalBody>
                    <div style={{ justifyContent: 'center' }} className="modal-footer">
                        <Button style={{ backgroundColor: "#E4510B", border: 'none' }} onClick={handleConfirmDelete} >
                            Confirm
                        </Button>
                        <Button color="secondary" onClick={closeDeleteModal}>
                            Cancel
                        </Button>
                    </div>
                </Modal>
            </div>
        </>
    )
}

export default Combo
