import React, { useEffect, useRef, useState } from 'react';
import { otpSend, otpVerify } from '../../../../ApiConfigs/ApiConfig';
import axios from 'axios';
import { Form, FormFeedback, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';

function OTPSend({ toggleAuth, toggleOTP, setCartKey, toggle1, showOTP, onMobileChange }) {
    const [otp, setOtp] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [timer, setTimer] = useState(0);

    const handleEmailSignIn = async () => {
        const payload = {
            mobile: mobile,
            otp: otp
        }

        try {
            const response = await axios.post(otpVerify, payload);
            toast.success(response?.data?.msg);
            localStorage.setItem('token', response?.data?.result?.tokens?.accessToken);
            localStorage.setItem('refreshToken', response?.data?.result?.tokens?.refreshToken);
            localStorage.setItem('role', response?.data?.result?.user?.role?.name);
            localStorage.setItem('userId', response?.data?.result?.user?._id);
            localStorage.setItem('name', response?.data?.result?.user?.name);
            localStorage.setItem('mobile', response?.data?.result?.user?.mobile);

            if (setCartKey === 'yes') {
                localStorage.setItem('AddCart', true);
            }
            localStorage.removeItem('SignInMobileOrEmail');
            localStorage.removeItem('SignInMobile');
            localStorage.removeItem('signUpPassword')
            toggleOTP();
            toggle1();
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }
    };

    const mobile = localStorage.getItem('SignInMobileOrEmail');

    const ResendOTP = async () => {
        const payload = {
            mobile: mobile
        }

        try {
            const response = await axios.post(otpSend, payload);
            toast.success(response?.data?.msg);
        }
        catch (error) {
            toast.error(error?.response?.data?.msg);
        }

        setIsDisabled(true);
        setTimer(40);

        // Start the countdown
        const countdown = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(countdown); // Clear the interval when the timer hits 0
                    setIsDisabled(false); // Enable the button again
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);
    };

    const isOtpComplete = otp.length === 6;

    const navigate = useNavigate();

    const handleChangeClick = () => {
        if (onMobileChange) {
            onMobileChange(mobile);
        }
        // Navigate to the SignIn page with the mobile number passed as state
        toggleOTP()
    };


    const { handleSubmit, control, formState: { errors } } = useForm();

    return (

        <div style={{ paddingLeft: '5%', paddingRight: '5%' }}>
            <div className=" text-center">
                {/* <img src={logo} alt="logo" height={60} className="me-2" /> */}
                <h1 className="auth-head mt-2">OTP</h1>

            </div>
            <div>
                <p className='text-center'>
                    {mobile} <span
                        style={{ color: '#E45D25', textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={handleChangeClick}
                    >
                        change
                    </span>
                </p>
            </div>
            <div>
                <p className='text-center' style={{ fontSize: '16px', fontWeight: '400', lineHeight: '25px', color: '#333333', opacity: '0.5' }}>
                    We’ve sent a One Time Password (OTP) to the mobile number above. Please enter it to complete verification
                </p>
            </div>
            <Form onSubmit={handleSubmit(handleEmailSignIn)}>

                <div className='' style={{ display: "flex", justifyContent: "center", width: '100%' }}>
                    <div className=''>
                        <Label>Enter OTP</Label>
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            separator={<span>-</span>}
                            renderInput={(inputProps, index) => (
                                <input {...inputProps} style={{ padding: "10px", width: '50px', height: '50px', textAlign: "center", borderRadius: "8px", marginLeft: "7px", justifyContent: 'center', fontSize: "16px", color: '#333333' }} />
                            )}
                        />
                        {errors.otp && <FormFeedback>{errors.otp.message}</FormFeedback>}
                    </div>
                </div>
                {/* <h6 className='text-center'>OTP has been sent to this mobile number! <span className='text-primary' style={{ cursor: 'pointer' }} onClick={toggleAuth}>{mobile}</span></h6> */}
                <p className='float-end' style={{
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    marginRight: '30px',
                    color: isDisabled ? 'gray' : '#E45D25'
                }} onClick={!isDisabled ? ResendOTP : null}>Resend OTP {isDisabled && `(${timer}s)`}</p>
                {/* <div className='modern-modal-footer'>
                    <button className='auth-button1' type='submit'>Login</button>
                </div> */}
                <div className='p-3'>
                    <button
                        className='auth-button1'
                        type='submit'
                        disabled={!isOtpComplete}  // Disable button until OTP is fully entered
                        style={{
                            backgroundColor: isOtpComplete ? '#E4510B' : '#DFDFDF',  // Enable button color
                            color: isOtpComplete ? 'white' : '#666666',  // Disable button text color
                            cursor: isOtpComplete ? 'pointer' : 'not-allowed',  // Change cursor based on state
                        }}
                    >
                        Login
                    </button>
                </div>
                {/* <div style={{ width: '100%' }} className='d-flex'>
                    <hr style={{ width: '100%' }} />
                    <p
                        className='ms-1 me-1'
                        style={{
                            color: '#333333D1',
                            minWidth: 'fit-content',
                            fontSize: '16px',
                            fontWeight: '400',
                        }}
                    >
                        or
                    </p>
                    <hr style={{ width: '100%' }} />
                </div>
                <div className=''>
                    <button
                        style={{ height: '45px', width: '100%', border: '1px solid #C6C6C6', borderRadius: '5px', fontSize: '20px', fontWeight: '600', background: 'transparent' }}
                        type='button'
                        onClick={handleChangeClick}
                    >
                        Login with your Password
                    </button>
                </div> */}
            </Form>
        </div>
    );
}

export default OTPSend;

